<div mat-dialog-title fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close [disabled]="isProceeding">
    <mat-icon data-testid="close-modal-button">close</mat-icon>
  </button>
</div>
<mat-dialog-content data-testid="verify-modal-container" [ngSwitch]="approve">
  <h1>Decision about {{ userName }}</h1>
  <p *ngSwitchCase="true">
    {{
      isPersonal || isKeyPersonsDecisionPoint
        ? 'By proceeding, the applicant will continue with risk scoring.'
        : 'By proceeding, the applicant will continue with verification of business key persons.'
    }}
  </p>

  <div *ngSwitchCase="false">
    <p>By proceeding, you will reject the applicant.</p>
    <div [formGroup]="reasonForm" fxLayout="column" class="field">
      <label for="closeReason">Please select a rejection reason:</label>
      <mat-form-field appearance="outline">
        <mat-select
          placeholder="Please select"
          fxLayout="column"
          fxLayoutGap="14px"
          name="closeReason"
          formControlName="closeReason"
        >
          <mat-option
            *ngFor="let reason of closeReasonNamesMap | keyvalue"
            [value]="reason.key"
            >{{ reason.value }}</mat-option
          >
        </mat-select>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="closeReasonControl?.hasError('required')"
          >Required</mat-error
        >
      </mat-form-field>
    </div>
  </div>

  <div class="buttons-container" fxLayoutAlign="end center" fxLayoutGap="40px">
    <button
      mat-stroked-button
      data-testid="cancel-modal-button"
      color="accent"
      mat-dialog-close
      [disabled]="isProceeding"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="accent"
      [disabled]="isProceeding || (reasonForm.invalid && !approve)"
      data-testid="proceed-modal-button"
      [appButtonLoader]="isProceeding"
      (click)="proceedDecision()"
    >
      Proceed
    </button>
  </div>
</mat-dialog-content>

import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { RegistrationService } from 'src/app/login/services/registration.service';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { COUNTDOWN_IN_SECONDS } from 'src/app/shared/helpers/various-helpers.helper';

@Component({
  selector: 'app-provide-email',
  templateUrl: './provide-email.component.html',
  styleUrls: ['./provide-email.component.scss'],
})
export class ProvideEmailComponent {
  isSending: boolean = false;
  isSendingAgain: boolean = false;
  isVerifying: boolean = false;
  smsSentAgain: boolean = false;
  isSendAgainDisabled: boolean = false;
  currentTemplate!: TemplateRef<any>;
  errorStatus: number | null | undefined;
  countdown: number = COUNTDOWN_IN_SECONDS;

  resetForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    otp: ['', Validators.required],
  });

  @ViewChild('emailStep1', { static: true }) emailStep1!: TemplateRef<any>;
  @ViewChild('emailStep2', { static: true }) emailStep2!: TemplateRef<any>;
  @ViewChild('emailStep3', { static: true }) emailStep3!: TemplateRef<any>;

  constructor(
    private fb: FormBuilder,
    private errorService: ErrorService,
    private registrationService: RegistrationService
  ) {}

  ngOnInit(): void {
    this.currentTemplate = this.emailStep1;
    this.otpControl.disable();
  }

  async sendOtp(again: boolean = false): Promise<void> {
    if (again) this.startCountdown();
    const email = this.resetForm.get('email')?.value;
    again ? (this.isSendingAgain = true) : (this.isSending = true);
    (
      await this.registrationService.sendResetPasswordOtp(email, again)
    ).subscribe(
      () => {
        if (again) {
          this.smsSentAgain = true;
          this.isSendingAgain = false;
        } else {
          this.isSending = false;
        }
        this.currentTemplate = this.emailStep2;
        this.otpControl.enable();
      },
      (err) => {
        again ? (this.isSendingAgain = false) : (this.isSending = false);
        if (err.status === 403) {
          this.errorStatus = err.status;
        } else {
          this.errorService.showErrorDialog();
        }
      }
    );
  }

  async reset(): Promise<void> {
    this.isVerifying = true;
    const email = this.resetForm.get('email')?.value;
    const otp = this.resetForm.get('otp')?.value;
    (
      await this.registrationService.sendResetPasswordEmail(email, otp)
    ).subscribe(
      () => {
        this.isVerifying = false;
        this.currentTemplate = this.emailStep3;
      },
      (err) => {
        this.isVerifying = false;
        if (err.status === 400) {
          this.otpControl?.setErrors({ wrongCode: true }); // invalid otp
        } else if (err.status === 403) {
          this.errorStatus = err.status; // too many failed otp attempts
        } else {
          this.errorService.showErrorDialog();
        }
      }
    );
  }

  async startCountdown() {
    this.isSendAgainDisabled = true;
    const countdownInterval = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        clearInterval(countdownInterval);
        this.isSendAgainDisabled = false;
        this.smsSentAgain = false;
        this.countdown = COUNTDOWN_IN_SECONDS;
      }
    }, 1000);
  }

  get otpControl(): AbstractControl {
    return this.resetForm.get('otp')!;
  }
}

<div *ngIf="directorGroup" [formGroup]="directorGroup" class="main">
  <ng-container formArrayName="directorForm">
    <ng-container
      *ngFor="let element of directorControl.controls; let i = index"
      [formGroupName]="i"
    >
      <mat-accordion *ngIf="element.value.firstName.id === director.id">
        <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
          <mat-expansion-panel-header class="disable_ripple">
            <mat-panel-title fxLayoutAlign="space-between center">
              <div class="title">
                <h2 *ngIf="director.isLegalPerson">
                  {{ i + 1 | ordinalNumber }} (Legal) Person
                </h2>
                <h2 *ngIf="!director.isLegalPerson">
                  {{ i + 1 | ordinalNumber }} (Natural) Person
                </h2>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container>
            <ng-container *ngIf="director.isLegalPerson">
              <app-admin-field-new
                [userId]="userId"
                label="Legal person name"
                formControlName="legalPersonName"
                [disableComment]="true"
              ></app-admin-field-new>
              <app-admin-field-new
                [userId]="userId"
                label="Registration number"
                formControlName="legalPersonRegistrationNumber"
              ></app-admin-field-new>
              <app-admin-field-new
                [userId]="userId"
                label="Date of Incorporation"
                formControlName="legalPersonIncorporationDate"
              ></app-admin-field-new>
            </ng-container>

            <ng-container *ngIf="!director.isLegalPerson">
              <app-admin-field-new
                [userId]="userId"
                label="First Name"
                formControlName="firstName"
                [disableComment]="true"
              ></app-admin-field-new>
              <app-admin-field-new
                [userId]="userId"
                label="Last Name"
                formControlName="lastName"
                [disableComment]="true"
              ></app-admin-field-new>
              <app-admin-field-new
                [userId]="userId"
                label="Date of Birth"
                formControlName="dateOfBirth"
                [disableComment]="true"
              ></app-admin-field-new>
              <app-admin-field-new
                [userId]="userId"
                label="Nationality"
                formControlName="nationality"
              ></app-admin-field-new>
            </ng-container>

            <app-admin-field-new
              [userId]="userId"
              label="Email"
              formControlName="email"
              [disableComment]="!director.isLegalPerson"
              [allowResendIdVerificationUrl]="allowResendIdVerificationUrl"
            ></app-admin-field-new>
            <app-admin-field-new
              [userId]="userId"
              label="{{
                director.isLegalPerson
                  ? 'Operating address'
                  : 'Residential address'
              }}"
              formControlName="streetAddress"
              [disableComment]="true"
            ></app-admin-field-new>
            <app-admin-field-new
              [userId]="userId"
              label="{{
                director.isLegalPerson
                  ? 'Operating address 2nd line'
                  : 'Residential address 2nd line'
              }}"
              formControlName="additionalStreetAddress"
              [disableComment]="true"
            ></app-admin-field-new>
            <app-admin-field-new
              [userId]="userId"
              label="Zip/Postal code"
              formControlName="postCode"
              [disableComment]="true"
            ></app-admin-field-new>
            <app-admin-field-new
              [userId]="userId"
              label="City"
              formControlName="city"
              [disableComment]="true"
            ></app-admin-field-new>
            <app-admin-field-new
              [userId]="userId"
              label="Country"
              formControlName="countryId"
              [disableComment]="true"
            ></app-admin-field-new>

            <ng-container *ngIf="!director.isLegalPerson">
              <app-admin-field-new
                [userId]="userId"
                label="The address above is my temporary residence in EEA"
                formControlName="isTempAddress"
              ></app-admin-field-new>
            </ng-container>

            <ng-container *ngIf="!director.isLegalPerson">
              <app-admin-field-new
                [userId]="userId"
                label="Phone number"
                formControlName="phoneNumber"
              ></app-admin-field-new>
            </ng-container>

            <!-- Status: Allow admin to add comment on status/position referring to all options (authorized person/director/shareholder/ubo)  -->
            <app-admin-field-new
              [userId]="userId"
              label="Status"
              formControlName="isDirector"
              [disableComment]="false"
            ></app-admin-field-new>

            <ng-container *ngIf="!director.isLegalPerson">
              <app-admin-field-new
                [userId]="userId"
                label="Authorized contact person"
                formControlName="isAuthorizedPerson"
                [disableComment]="true"
              ></app-admin-field-new>
            </ng-container>

            <app-admin-field-new
              [userId]="userId"
              label="Director"
              formControlName="isDirector"
              [disableComment]="true"
            ></app-admin-field-new>
            <app-admin-field-new
              [userId]="userId"
              label="Shareholder"
              formControlName="isShareholder"
              [disableComment]="true"
            ></app-admin-field-new>
            <app-admin-field-new
              [userId]="userId"
              label="Percentage of shares owned by shareholder"
              formControlName="percentageOwnedByShareholder"
              [disableComment]="true"
            ></app-admin-field-new>
            <app-admin-field-new
              [userId]="userId"
              label="UBO"
              formControlName="isUBO"
              [disableComment]="true"
            ></app-admin-field-new>
            <app-admin-field-new
              [userId]="userId"
              label="Percentage of shares owned by UBO"
              formControlName="percentageOwnedByUBO"
              [disableComment]="true"
            ></app-admin-field-new>

            <ng-container *ngIf="!director.isLegalPerson">
              <app-admin-field-new
                [userId]="userId"
                label="Tax Identification Number"
                formControlName="taxNumber"
              ></app-admin-field-new>
              <app-admin-field-new
                [userId]="userId"
                label="Country of Tax Residency (if applicable)"
                formControlName="taxResidencyCountry"
              ></app-admin-field-new>
              <app-admin-field-new
                [userId]="userId"
                label="Are you (or have you been in the 12 previous months) a PEP or a close family member (spouse, children, parents) or a close associate (any business relations) of a PEP?"
                formControlName="isPEP"
              ></app-admin-field-new>
            </ng-container>
            <app-admin-field-new
              [userId]="userId"
              label="Verification complete"
              formControlName="onboardingVerificationDone"
              [disableComment]="true"
            ></app-admin-field-new>
          </ng-container>

          <div fxLayout="row wrap" fxLayoutGap="30px grid">
            <app-admin-document
              *ngFor="let control of documentsControls"
              [formControl]="control"
              [userId]="userId"
              [companyDirectorId]="director.id"
              [fileDialogHeader]="fileDialogHeader"
              [showProofOfOperatingAddress]="director.isLegalPerson"
            ></app-admin-document>
          </div>

          <!-- CLOSE LINKS OF KEY PERSON -->
          <div
            *ngIf="directorCloseLinkGroup"
            [formGroup]="directorCloseLinkGroup"
            class="main"
          >
            <ng-container formArrayName="directorCloseLinkForm">
              <ng-container
                *ngFor="
                  let element of directorCloseLinkControl.controls;
                  let i = index
                "
                [formGroupName]="i"
              >
                <div *ngIf="element.value.isDirector.id === director.id">
                  <h2 style="margin-top: 20px; margin-bottom: 10px">
                    Close Link
                  </h2>
                  <app-admin-field-new
                    [userId]="userId"
                    label="Company name"
                    formControlName="name"
                    [disableComment]="true"
                  ></app-admin-field-new>
                  <app-admin-field-new
                    [userId]="userId"
                    label="Registration number"
                    formControlName="registrationNumber"
                    [disableComment]="true"
                  ></app-admin-field-new>
                  <app-admin-field-new
                    [userId]="userId"
                    label="Date of Incorporation"
                    formControlName="incorporationDate"
                    [disableComment]="true"
                  ></app-admin-field-new>
                  <div formGroupName="address">
                    <app-admin-field-new
                      [userId]="userId"
                      label="Registered address (Street, building number, place)"
                      formControlName="streetAddress"
                      [disableComment]="true"
                    ></app-admin-field-new>
                    <app-admin-field-new
                      [userId]="userId"
                      label="Registered address 2nd line (optional)"
                      formControlName="additionalStreetAddress"
                      [disableComment]="true"
                    ></app-admin-field-new>
                    <app-admin-field-new
                      [userId]="userId"
                      label="Zip/Postal code"
                      formControlName="postCode"
                      [disableComment]="true"
                    ></app-admin-field-new>
                    <app-admin-field-new
                      [userId]="userId"
                      label="City"
                      formControlName="city"
                      [disableComment]="true"
                    ></app-admin-field-new>
                    <app-admin-field-new
                      [userId]="userId"
                      label="Country"
                      formControlName="countryId"
                      [disableComment]="true"
                    ></app-admin-field-new>
                  </div>

                  <!-- Status: Allow admin to add comment on status/position referring to all options (director/shareholder/ubo)  -->
                  <app-admin-field-new
                    [userId]="userId"
                    label="Status"
                    formControlName="isDirector"
                    [disableComment]="false"
                  ></app-admin-field-new>

                  <app-admin-field-new
                    [userId]="userId"
                    label="Director"
                    formControlName="isDirector"
                    [disableComment]="true"
                  ></app-admin-field-new>
                  <app-admin-field-new
                    [userId]="userId"
                    label="Shareholder"
                    formControlName="isShareholder"
                    [disableComment]="true"
                  ></app-admin-field-new>
                  <app-admin-field-new
                    [userId]="userId"
                    label="Percentage of shares owned by shareholder"
                    formControlName="percentageOwnedByShareholder"
                    [disableComment]="true"
                  ></app-admin-field-new>
                  <app-admin-field-new
                    [userId]="userId"
                    label="UBO"
                    formControlName="isUBO"
                    [disableComment]="true"
                  ></app-admin-field-new>
                  <app-admin-field-new
                    [userId]="userId"
                    label="Percentage of shares owned by UBO"
                    formControlName="percentageOwnedByUBO"
                    [disableComment]="true"
                  ></app-admin-field-new>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </mat-expansion-panel>
        <mat-divider></mat-divider>
      </mat-accordion>
    </ng-container>
  </ng-container>
</div>

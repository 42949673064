import { Component, Input } from '@angular/core';
import { TransactionTypeName } from 'src/app/shared/models/transaction-type-name.enum';
import { StatementItem } from 'src/app/statement/models/statement-item.model';

@Component({
  selector: 'app-billing-section',
  templateUrl: './billing-section.component.html',
  styleUrls: ['./billing-section.component.scss'],
})
export class BillingSectionComponent {
  @Input() transaction!: StatementItem;

  TransactionTypeName = TransactionTypeName;
}

<div [formGroup]="counterpartiesForm">
  <div class="desc">
    Please provide details of your main
    <span [matTooltip]="tooltips.counterpartiesTooltip">counterparties</span>
    (at least one).
  </div>
  <app-counterparty-form
    *ngFor="
      let counterparty of counterparties;
      trackBy: trackByFn;
      let i = index
    "
    [index]="i"
    [countries]="countries"
    [isReviewed]="isReviewed"
    [fields]="fields"
    [counterparty]="counterparty"
    (counterpartyChange)="onCounterpartyChange($event)"
    (counterpartyRemove)="onCounterpartyRemove($event)"
    (isComplete)="setComplete(counterparty.id, $event)"
  ></app-counterparty-form>
  <mat-error
    *ngIf="
      counterpartiesForm.controls['counterparties'].hasError(
        'incomingTransactionRequired'
      )
    "
    >At least one Counterparty with Incoming Transaction is required</mat-error
  >
  <mat-error
    *ngIf="
      counterpartiesForm.controls['counterparties'].hasError(
        'outgoingTransactionRequired'
      )
    "
    >At least one Counterparty with Outgoing Transaction is required</mat-error
  >
  <button
    *ngIf="!isReviewed"
    mat-flat-button
    color="accent"
    class="g-small"
    [disabled]="isAdding"
    [appButtonLoader]="isAdding"
    (click)="addCounterparty()"
  >
    Add another counterparty
  </button>
</div>

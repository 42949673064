import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { minControlsNumberValidator } from 'src/app/shared/min-controls-number.validator';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import {
  CompanyDirectorWithDocuments,
  SaveCompanyDirector,
} from '../../models/company-director.model';
import { OnboardingService } from '../../onboarding.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Country } from 'src/app/shared/models/country.model';
import { Address } from 'src/app/shared/models/address.model';
import { CompanyCloseLink } from '../../models/company-close-link.model';
import { CompanyDirectorCloseLink } from '../../models/company-director-close-link.model';
import { OnboardingField } from '../../models/onboarding-field.model';
import { MatDialogRef } from '@angular/material/dialog';
import { VerificationDialogComponent } from '../../components/verification-dialog/verification-dialog.component';
import { tooltips } from 'src/app/shared/helpers/various-helpers.helper';

@Component({
  selector: 'app-company-directors-form-new',
  templateUrl: './company-directors-form.component.html',
  styleUrls: ['./company-directors-form.component.scss'],
})
export class CompanyDirectorsFormNewComponent implements OnInit, OnDestroy {
  @Input() countries: Country[] = [];
  @Input() fields: OnboardingField[] = [];
  @Input() set companyDirectors(val: CompanyDirectorWithDocuments[]) {
    const directorsIds = new Set();
    this.directors = [];

    val.forEach((director) => {
      directorsIds.add(director.id.toString());
      this.directors.push(director);

      // adds controls to directorsGroup for missing directors
      if (!this.directorsGroup.get(director.id.toString())) {
        this.directorsGroup.addControl(
          director.id.toString(),
          this.fb.control(null, Validators.requiredTrue)
        );
      }
    });

    // removes controls from directorsGroup that are not needed anymore
    Object.keys(this.directorsGroup.controls).forEach((key) => {
      if (!directorsIds.has(key)) {
        this.directorsGroup.removeControl(key);
      }
    });

    this._companyDirectors = val;
  }
  get companyDirectors(): CompanyDirectorWithDocuments[] {
    return this._companyDirectors;
  }

  @Input() set companyCloseLinks(val: CompanyCloseLink[]) {
    this._companyCloseLinks = val;
  }
  get companyCloseLinks(): CompanyCloseLink[] {
    return this._companyCloseLinks;
  }

  @Input() set companyDirectorCloseLinks(val: CompanyDirectorCloseLink[]) {
    this._companyDirectorCloseLinks = val;
  }
  get companyDirectorCloseLinks(): CompanyDirectorCloseLink[] {
    return this._companyDirectorCloseLinks;
  }
  @Input() isReviewed: boolean = false;
  @Input() isEDD!: boolean;
  @Input() isFirstTimeEDD!: boolean;
  // It is emitted when director is modified by user (so that it can be shown properly in other section)
  @Output() companyDirectorsChange = new EventEmitter<
    CompanyDirectorWithDocuments[]
  >();
  @Output() companyCloseLinksChange = new EventEmitter<CompanyCloseLink[]>();
  @Output() companyDirectorCloseLinksChange = new EventEmitter<
    CompanyDirectorCloseLink[]
  >();
  @Output() referenceId = new EventEmitter<string>();
  @Output() dialogRef = new EventEmitter<
    MatDialogRef<VerificationDialogComponent>
  >();
  @Output() isComplete = new EventEmitter<boolean>();

  directors: CompanyDirectorWithDocuments[] = [];
  isAdding: boolean = false;
  isAddingLegalPerson: boolean = false;
  tooltips = tooltips;
  private _companyDirectors: CompanyDirectorWithDocuments[] = [];
  private _companyCloseLinks: CompanyCloseLink[] = [];
  private _companyDirectorCloseLinks: CompanyDirectorCloseLink[] = [];
  private directorsFormSub: Subscription | undefined = undefined;

  directorsForm: FormGroup = this.fb.group(
    {
      directors: this.fb.group(
        {},
        {
          validators: [
            minControlsNumberValidator(1), // it has to be at least one director
            this.sharesValidator.bind(this), // shares don't exceed 100%
            this.positionValidator.bind(this), // only one authorized person and at least one director/shareholder/UBO
          ],
        }
      ), // this group is used only for validation purposes (contains values 'isValid', and validation 'requiredTrue')
    },
    { updateOn: 'blur' }
  );

  constructor(
    private fb: FormBuilder,
    private errorService: ErrorService,
    private onboardingService: OnboardingService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    // Emits status of the form when it changes
    this.directorsFormSub = this.directorsForm.statusChanges
      .pipe(startWith(this.directorsForm.status))
      .subscribe((status) => {
        setTimeout(() => this.isComplete.emit(status === 'VALID'));
      });
  }

  ngOnDestroy(): void {
    this.directorsFormSub?.unsubscribe();
  }

  addDirectorShareholder(isNaturalPerson: boolean): void {
    this.isComplete.emit(false); // prevents saving application while adding director
    isNaturalPerson
      ? (this.isAdding = true)
      : (this.isAddingLegalPerson = true);

    const address: Address = {
      streetAddress: '',
      additionalStreetAddress: '',
      postCode: '',
      city: '',
      countryId: 60,
    };
    const body: SaveCompanyDirector = {
      firstName: '',
      lastName: '',
      isDirector: false,
      isShareholder: false,
      isUBO: false,
      isAuthorizedPerson: false,
      email: '',
      phoneNumber: '',
      dateOfBirth: '',
      nationality: 60,
      address: address,
      isTempAddress: false,
      taxNumber: '',
      taxResidencyCountry: 60,
      isPEP: false,
      percentageOwnedByShareholder: 0,
      percentageOwnedByUBO: 0,
      isLegalPerson: isNaturalPerson ? false : true,
      legalPersonName: '',
      legalPersonRegistrationNumber: '',
      legalPersonIncorporationDate: '',
      isActive: true,
      onboardingVerificationDone: false,
    };
    this.onboardingService.createCompanyDirectorOrShareholder(body).subscribe(
      (newDirector) => {
        this.companyDirectorsChange.emit([
          ...this.companyDirectors,
          newDirector,
        ]);
        isNaturalPerson
          ? (this.isAdding = false)
          : (this.isAddingLegalPerson = false);
      },
      () => {
        this.errorService.showErrorDialog();
        isNaturalPerson
          ? (this.isAdding = false)
          : (this.isAddingLegalPerson = false);
      }
    );
  }

  // counts ordinal number of a director (needed for case when we show e.g. 2nd Shareholder is 3rd Director)
  getDirectorIndex(id: number): number {
    return this.companyDirectors
      .filter((companyDirector) => companyDirector.isDirector)
      .findIndex((companyDirector) => companyDirector.id === id);
  }

  // used for emitting company directors when one was changed
  onDirectorChange(newDirector: CompanyDirectorWithDocuments): void {
    const index = this.companyDirectors.findIndex(
      (companyDirector) => newDirector.id === companyDirector.id
    );

    const newCompanyDirectors = [...this.companyDirectors];
    newCompanyDirectors[index] = {
      ...this.companyDirectors[index],
      ...newDirector,
    };
    // delete phone number from the emitted changes, so it is not marked as invalid
    delete (newCompanyDirectors[index] as any).phoneNumber;
    this.companyDirectorsChange.emit(newCompanyDirectors);
  }

  // used for emitting company directors when one was removed
  onDirectorRemove(id: number): void {
    const newCompanyDirectors = this.companyDirectors.filter(
      (companyDirector) => companyDirector.id !== id
    );
    this.companyDirectorsChange.emit(newCompanyDirectors);
  }

  // used for setting director as complete (in directorsGroup)
  setComplete(directorId: number, isComplete: boolean): void {
    this.directorsGroup.get(directorId.toString())?.setValue(isComplete);
  }

  // used for emitting company close links when one is added
  onCloseLinkChange(newCloseLinks: CompanyCloseLink[]): void {
    this.companyCloseLinksChange.emit(newCloseLinks);
  }
  // used for emitting company director-close links when changed
  onDirectorCloseLinkChange(
    newDirectorCloseLinks: CompanyDirectorCloseLink[]
  ): void {
    this.companyDirectorCloseLinksChange.emit(newDirectorCloseLinks);
  }
  // used for emitting reference id of verification when frontend event received
  referenceIdChanged(referenceId: string) {
    this.referenceId.emit(referenceId);
  }
  // used for emitting verification dialog reference
  dialogRefChanged(dialogRef: MatDialogRef<VerificationDialogComponent>) {
    this.dialogRef.emit(dialogRef);
  }

  trackByFn(index: number, item: CompanyDirectorWithDocuments): number {
    return item.id;
  }

  get directorsGroup(): FormGroup {
    return this.directorsForm.get('directors') as FormGroup;
  }

  private sharesValidator() {
    if (this.directorsForm && this.directors.length > 0) {
      const shares = this.directors
        .filter((d) => d.isShareholder)
        .map((s) => s.percentageOwnedByShareholder);
      if (shares.length > 0) {
        const sum = shares.reduce((sum, p) => sum + p, 0);
        if (sum > 100) {
          return { sharesMoreThan100: true };
        }
      }
    }
    return null;
  }

  private positionValidator() {
    if (this.directorsForm && this.directors.length > 0) {
      if (
        this.directors.filter((d) => d.isAuthorizedPerson).length === 1 &&
        this.directors.filter((d) => d.isDirector).length > 0 &&
        this.directors.filter((d) => d.isShareholder).length > 0 &&
        this.directors.filter((d) => d.isUBO).length > 0
      ) {
        return null;
      } else {
        return { atLeastOnePosition: true };
      }
    }
    return null;
  }
}

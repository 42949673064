<div [formGroup]="directorForm" class="main">
  <ng-container>
    <mat-accordion>
      <mat-expansion-panel
        [expanded]="false"
        class="mat-elevation-z0"
        [disabled]="isRemoving"
      >
        <mat-expansion-panel-header class="disable_ripple">
          <mat-panel-title fxLayoutAlign="space-between center">
            <div class="title">
              <div fxLayoutAlign="start center" class="header">
                <h2 *ngIf="!isLegalPersonControl?.value">
                  {{ index + 1 | ordinalNumber }} (Natural) Person
                </h2>
                <h2 *ngIf="isLegalPersonControl?.value">
                  {{ index + 1 | ordinalNumber }} (Legal) Person
                </h2>
                <ng-container
                  *ngTemplateOutlet="
                    removeButton;
                    context: { $implicit: director }
                  "
                ></ng-container>
              </div>
            </div>
            <div
              [ngSwitch]="
                directorForm?.valid &&
                verificationAccepted &&
                closeLinksComplete() &&
                documentsComplete
              "
              class="status"
              [class.complete]="
                directorForm?.valid &&
                verificationAccepted &&
                closeLinksComplete() &&
                documentsComplete
              "
            >
              <ng-container *ngSwitchCase="true">Complete</ng-container>
              <ng-container *ngSwitchCase="false">Incomplete</ng-container>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-container>
          <div *ngIf="!isLegalPersonControl?.value">
            <app-onboarding-form-item-new
              label="First name"
              [isReviewed]="isReviewed"
              [value]="director.firstName"
              [field]="
                fieldsMap.get(OnboardingFieldId.BUSINESS_KEY_PERSON_FIRST_NAME)
              "
            >
              <div fxLayout="column" class="field">
                <label for="firstName">First name</label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    name="firstName"
                    formControlName="firstName"
                  />
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="firstNameControl?.hasError('required')"
                    >Required</mat-error
                  >
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="firstNameControl?.hasError('pattern')"
                    >Invalid character</mat-error
                  >
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="firstNameControl?.hasError('maxlength')"
                    >Too many characters</mat-error
                  >
                </mat-form-field>
              </div>
            </app-onboarding-form-item-new>
            <app-onboarding-form-item-new
              label="Last name"
              [isReviewed]="isReviewed"
              [value]="director.lastName"
              [field]="
                fieldsMap.get(OnboardingFieldId.BUSINESS_KEY_PERSON_LAST_NAME)
              "
            >
              <div fxLayout="column" class="field">
                <label for="lastName">Last name</label>
                <mat-form-field appearance="outline">
                  <input matInput name="lastName" formControlName="lastName" />
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="lastNameControl?.hasError('required')"
                    >Required</mat-error
                  >
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="lastNameControl?.hasError('pattern')"
                    >Invalid character</mat-error
                  >
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="lastNameControl?.hasError('maxlength')"
                    >Too many characters</mat-error
                  >
                </mat-form-field>
              </div>
            </app-onboarding-form-item-new>

            <app-onboarding-form-item-new
              label="Date of Birth"
              [isReviewed]="isReviewed"
              [value]="director.dateOfBirth"
              [field]="
                fieldsMap.get(
                  OnboardingFieldId.BUSINESS_KEY_PERSON_DATE_OF_BIRTH
                )
              "
            >
              <div fxLayout="column" class="field short">
                <label for="dateOfBirth">Date of Birth</label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    [matDatepicker]="picker"
                    name="dateOfBirth"
                    formControlName="dateOfBirth"
                    [max]="minDob"
                    readonly
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error *ngIf="dateOfBirthControl?.hasError('required')"
                    >Required</mat-error
                  >
                </mat-form-field>
              </div>
            </app-onboarding-form-item-new>
            <app-onboarding-form-item-new
              label="Nationality"
              [isReviewed]="isReviewed"
              [value]="getNationalityById(countries, director.nationality)"
              [field]="
                fieldsMap.get(OnboardingFieldId.BUSINESS_KEY_PERSON_NATIONALITY)
              "
            >
              <div fxLayout="column" class="field short">
                <label for="nationality">Nationality</label>
                <mat-form-field appearance="outline">
                  <mat-select
                    placeholder="Please select"
                    name="nationality"
                    formControlName="nationality"
                  >
                    <mat-option
                      *ngFor="let country of countries"
                      [value]="country.id"
                      >{{ country.nationality | titlecase }}</mat-option
                    >
                  </mat-select>
                  <mat-error *ngIf="nationalityControl?.hasError('required')"
                    >Required</mat-error
                  >
                </mat-form-field>
              </div>
            </app-onboarding-form-item-new>
          </div>

          <div *ngIf="isLegalPersonControl?.value">
            <app-onboarding-form-item-new
              label="Legal person name"
              [isReviewed]="isReviewed"
              [value]="director.legalPersonName"
              [field]="
                fieldsMap.get(
                  OnboardingFieldId.BUSINESS_KEY_PERSON_LEGAL_PERSON_NAME
                )
              "
            >
              <div fxLayout="column" class="field">
                <label for="legalPersonName">Legal person name</label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    name="legalPersonName"
                    formControlName="legalPersonName"
                  />
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="legalPersonNameControl?.hasError('required')"
                    >Required</mat-error
                  >
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="legalPersonNameControl?.hasError('pattern')"
                    >Invalid character</mat-error
                  >
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="legalPersonNameControl?.hasError('maxlength')"
                    >Too many characters</mat-error
                  >
                </mat-form-field>
              </div>
            </app-onboarding-form-item-new>

            <app-onboarding-form-item-new
              label="Registration number"
              [isReviewed]="isReviewed"
              [value]="director.legalPersonRegistrationNumber"
              [field]="
                fieldsMap.get(
                  OnboardingFieldId.BUSINESS_KEY_PERSON_REGISTRATION_NUMBER
                )
              "
            >
              <div fxLayout="column" class="field">
                <label for="legalPersonRegistrationNumber"
                  >Registration number</label
                >
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    name="legalPersonRegistrationNumber"
                    formControlName="legalPersonRegistrationNumber"
                  />
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="
                      legalPersonRegistrationNumberControl?.hasError('required')
                    "
                    >Required</mat-error
                  >
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="
                      legalPersonRegistrationNumberControl?.hasError('pattern')
                    "
                    >Invalid character</mat-error
                  >
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="
                      legalPersonRegistrationNumberControl?.hasError(
                        'maxlength'
                      )
                    "
                    >Too many characters</mat-error
                  >
                </mat-form-field>
              </div>
            </app-onboarding-form-item-new>

            <app-onboarding-form-item-new
              label="Date of Incorporation"
              [isReviewed]="isReviewed"
              [value]="director.legalPersonIncorporationDate"
              [field]="
                fieldsMap.get(
                  OnboardingFieldId.BUSINESS_KEY_PERSON_DATE_OF_INCORPORATION
                )
              "
            >
              <div fxLayout="column" class="field">
                <label for="legalPersonIncorporationDate"
                  >Date of Incorporation</label
                >
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    data-testid="date-of-incorporation-input"
                    [matDatepicker]="picker"
                    name="legalPersonIncorporationDate"
                    formControlName="legalPersonIncorporationDate"
                    readonly
                    [max]="today"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="
                      legalPersonIncorporationDateControl?.hasError('required')
                    "
                    >Required</mat-error
                  >
                </mat-form-field>
              </div>
            </app-onboarding-form-item-new>
          </div>

          <app-onboarding-form-item-new
            label="Email"
            [isReviewed]="isReviewed"
            [value]="director.email"
            [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_KEY_PERSON_EMAIL)"
          >
            <div fxLayout="column" class="field">
              <label for="email">Email</label>
              <mat-form-field appearance="outline">
                <input matInput name="email" formControlName="email" />
                <mat-error
                  data-testid="validation-message-box"
                  *ngIf="emailControl?.hasError('required')"
                  >Required</mat-error
                >
                <mat-error
                  data-testid="validation-message-box"
                  *ngIf="emailControl?.hasError('email')"
                  >Invalid</mat-error
                >
                <mat-error
                  data-testid="validation-message-box"
                  *ngIf="emailControl?.hasError('pattern')"
                  >Invalid</mat-error
                >
              </mat-form-field>
            </div>
          </app-onboarding-form-item-new>

          <ng-container formGroupName="address">
            <h2 *ngIf="isLegalPersonControl?.value">Operating Address</h2>
            <h2 *ngIf="!isLegalPersonControl?.value">Residential Address</h2>
            <app-onboarding-form-item-new
              label="Address (Street, building number, place)"
              [isReviewed]="isReviewed"
              [value]="director.address?.streetAddress"
              [field]="
                fieldsMap.get(OnboardingFieldId.BUSINESS_KEY_PERSON_ADDRESS)
              "
            >
              <div fxLayout="column" class="field">
                <label>No PO Boxes</label>
                <label for="streetAddress"
                  >Address (Street, building number, place)</label
                >
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    name="streetAddress"
                    formControlName="streetAddress"
                  />
                  <mat-error *ngIf="streetAddressControl?.hasError('required')"
                    >Required</mat-error
                  >
                  <mat-error *ngIf="streetAddressControl?.hasError('pattern')"
                    >Invalid character</mat-error
                  >
                  <mat-error *ngIf="streetAddressControl?.hasError('maxlength')"
                    >Too many characters</mat-error
                  >
                </mat-form-field>
              </div>
            </app-onboarding-form-item-new>
            <app-onboarding-form-item-new
              label="Address 2nd line (optional)"
              [isReviewed]="isReviewed"
              [value]="director.address?.additionalStreetAddress"
              [field]="
                fieldsMap.get(
                  OnboardingFieldId.BUSINESS_KEY_PERSON_ADDRESS_2ND_LINE
                )
              "
            >
              <div fxLayout="column" class="field">
                <label for="additionalStreetAddress"
                  >Address 2nd line (optional)</label
                >
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    name="additionalStreetAddress"
                    formControlName="additionalStreetAddress"
                  />
                  <mat-error
                    *ngIf="additionalStreetAddressControl?.hasError('pattern')"
                    >Invalid character</mat-error
                  >
                  <mat-error
                    *ngIf="
                      additionalStreetAddressControl?.hasError('maxlength')
                    "
                    >Too many characters</mat-error
                  >
                </mat-form-field>
              </div>
            </app-onboarding-form-item-new>

            <div
              [fxLayout]="isReviewed ? 'column' : 'row'"
              [class.field]="!isReviewed"
              [fxLayoutGap]="isReviewed ? '0px' : '35px'"
            >
              <app-onboarding-form-item-new
                label="Zip/Postal code"
                [isReviewed]="isReviewed"
                [value]="director.address?.postCode"
                [field]="
                  fieldsMap.get(
                    OnboardingFieldId.BUSINESS_KEY_PERSON_ZIP_POSTAL_CODE
                  )
                "
              >
                <div fxLayout="column" class="zip">
                  <label for="postCode">Zip/Postal code</label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      name="postCode"
                      formControlName="postCode"
                    />
                    <mat-error *ngIf="postCodeControl?.hasError('required')"
                      >Required</mat-error
                    >
                    <mat-error *ngIf="postCodeControl?.hasError('pattern')"
                      >Invalid</mat-error
                    >
                    <mat-error *ngIf="postCodeControl?.hasError('maxlength')"
                      >Too long</mat-error
                    >
                  </mat-form-field>
                </div>
              </app-onboarding-form-item-new>

              <app-onboarding-form-item-new
                label="City"
                [isReviewed]="isReviewed"
                [value]="director.address?.city"
                [field]="
                  fieldsMap.get(OnboardingFieldId.BUSINESS_KEY_PERSON_CITY)
                "
              >
                <div fxLayout="column" class="city">
                  <label for="city">City</label>
                  <mat-form-field appearance="outline">
                    <input matInput name="city" formControlName="city" />
                    <mat-error *ngIf="cityControl?.hasError('required')"
                      >Required</mat-error
                    >
                    <mat-error *ngIf="cityControl?.hasError('pattern')"
                      >Invalid character</mat-error
                    >
                    <mat-error *ngIf="cityControl?.hasError('maxlength')"
                      >Too many characters</mat-error
                    >
                  </mat-form-field>
                </div>
              </app-onboarding-form-item-new>
            </div>

            <app-onboarding-form-item-new
              label="Country"
              [isReviewed]="isReviewed"
              [value]="getCountryById(countries, director.address?.countryId)"
              [field]="
                fieldsMap.get(OnboardingFieldId.BUSINESS_KEY_PERSON_COUNTRY)
              "
            >
              <div fxLayout="column" class="field short">
                <label for="countryId">Country</label>
                <mat-form-field appearance="outline">
                  <mat-select
                    placeholder="Please select"
                    name="countryId"
                    formControlName="countryId"
                  >
                    <mat-option
                      *ngFor="let country of countries"
                      [value]="country.id"
                      >{{ country.name | titlecase }}</mat-option
                    >
                  </mat-select>
                  <mat-error *ngIf="countryIdControl?.hasError('required')"
                    >Required</mat-error
                  >
                </mat-form-field>
              </div>
            </app-onboarding-form-item-new>
          </ng-container>

          <div *ngIf="!isLegalPersonControl?.value" class="temp-address">
            <app-onboarding-form-item-new
              label="The address above is my temporary residence in
              EEA"
              [isReviewed]="isReviewed"
              [value]="getStringValue(director.isTempAddress)"
              [field]="
                fieldsMap.get(
                  OnboardingFieldId.BUSINESS_KEY_PERSON_IS_TEMP_ADDRESS
                )
              "
            >
              <mat-checkbox
                formControlName="isTempAddress"
                class="checkbox"
                color="primary"
                >The address above is my temporary residence in
                EEA</mat-checkbox
              >
            </app-onboarding-form-item-new>
          </div>

          <div *ngIf="!isLegalPersonControl?.value">
            <app-onboarding-form-item-new
              label="Phone number"
              [isReviewed]="isReviewed"
              [value]="director.phoneNumber"
              [field]="
                fieldsMap.get(
                  OnboardingFieldId.BUSINESS_KEY_PERSON_PHONE_NUMBER
                )
              "
            >
              <div fxLayout="column" class="field phone-number short">
                <label for="phoneNumber">Phone number</label>
                <div class="phone-description">
                  Enter a plus sign (+) followed by your country code and phone
                  number, e.g. +357xxxxxxxx
                </div>
                <ngx-intl-tel-input
                  [enablePlaceholder]="false"
                  [searchCountryFlag]="true"
                  [separateDialCode]="false"
                  [selectedCountryISO]="
                    countryCode ? countryCode : countryCodeCY
                  "
                  data-testid="phone-number-input"
                  name="phoneNumber"
                  formControlName="phoneNumber"
                >
                </ngx-intl-tel-input>
                <mat-error
                  data-testid="validation-message-box"
                  *ngIf="
                    phoneNumberControl?.invalid && phoneNumberControl?.touched
                  "
                  >Invalid</mat-error
                >
              </div>
            </app-onboarding-form-item-new>
          </div>

          <div class="header">
            <h2>Status</h2>
            <!-- Show the following status when isReviewed and comment does not exist -->
            <ng-container
              *ngIf="
                isReviewed &&
                !fieldsMap.get(OnboardingFieldId.BUSINESS_KEY_PERSON_DIRECTOR)
                  ?.comment
              "
            >
              <br />
              <div
                *ngIf="!isLegalPersonControl?.value"
                fxLayout="row"
                fxLayoutGap="20px grid"
                class="submitted"
              >
                <div fxFlex="50">Authorized contact person</div>
                <div fxFlex="50" class="value">
                  {{ getStringValue(director.isAuthorizedPerson) }}
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="20px grid" class="submitted">
                <div fxFlex="50">Director</div>
                <div fxFlex="50" class="value">
                  {{ getStringValue(director.isDirector) }}
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="20px grid" class="submitted">
                <div fxFlex="50">Shareholder</div>
                <div fxFlex="50" class="value">
                  {{ getStringValue(director.isShareholder) }}
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="20px grid" class="submitted">
                <div fxFlex="50">Percentage of shares owned by shareholder</div>
                <div fxFlex="50" class="value">
                  {{
                    director.isShareholder
                      ? director.percentageOwnedByShareholder + '%'
                      : '-'
                  }}
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="20px grid" class="submitted">
                <div fxFlex="50">UBO</div>
                <div fxFlex="50" class="value">
                  {{ getStringValue(director.isUBO) }}
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="20px grid" class="submitted">
                <div fxFlex="50">Percentage of shares owned by UBO</div>
                <div fxFlex="50" class="value">
                  {{
                    director.isUBO ? director.percentageOwnedByUBO + '%' : '-'
                  }}
                </div>
              </div>
            </ng-container>

            <!-- Show the following status when isReviewed and comment exists -->
            <app-onboarding-form-item-new
              label=""
              [isReviewed]="isReviewed"
              [value]=""
              [field]="
                fieldsMap.get(OnboardingFieldId.BUSINESS_KEY_PERSON_DIRECTOR)
              "
            >
              <div fxLayout="column" class="roles" fxLayoutGap="20px">
                <mat-checkbox
                  *ngIf="!isLegalPersonControl?.value"
                  name="isAuthorizedPerson"
                  formControlName="isAuthorizedPerson"
                  color="primary"
                >
                  Authorized contact person
                </mat-checkbox>

                <mat-checkbox
                  name="isDirector"
                  formControlName="isDirector"
                  color="primary"
                >
                  Director
                </mat-checkbox>

                <div
                  fxLayout="row"
                  fxLayoutAlign="start start"
                  class="shareholder"
                >
                  <mat-checkbox
                    name="isShareholder"
                    formControlName="isShareholder"
                    color="primary"
                  >
                    Shareholder
                  </mat-checkbox>
                  <div
                    fxLayout="row"
                    fxLayoutAlign="start start"
                    class="field shares"
                    *ngIf="isShareholderControl?.value"
                  >
                    <label
                      class="shareholder-shares"
                      for="percentageOwnedByShareholder"
                      >% of shares owned</label
                    >
                    <mat-form-field appearance="outline" class="small">
                      <input
                        placeholder="0.00"
                        type="number"
                        min="10"
                        max="100"
                        step=".01"
                        matInput
                        name="percentageOwnedByShareholder"
                        formControlName="percentageOwnedByShareholder"
                      />
                      <div matTextSuffix>%</div>
                      <mat-error
                        data-testid="validation-message-box"
                        *ngIf="
                          percentageOwnedByShareholderControl?.hasError(
                            'required'
                          )
                        "
                        >Required</mat-error
                      >
                      <mat-error
                        data-testid="validation-message-box"
                        *ngIf="
                          percentageOwnedByShareholderControl?.hasError(
                            'pattern'
                          )
                        "
                        >Use format: 0.00</mat-error
                      >
                      <mat-error
                        data-testid="validation-message-box"
                        *ngIf="
                          percentageOwnedByShareholderControl?.hasError('min')
                        "
                        >Value must be greater than or equal to 10</mat-error
                      >
                      <mat-error
                        data-testid="validation-message-box"
                        *ngIf="
                          percentageOwnedByShareholderControl?.hasError('max')
                        "
                        >Value must be less than or equal to 100</mat-error
                      >
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="start start" class="ubo">
                  <mat-checkbox
                    name="isUBO"
                    formControlName="isUBO"
                    color="primary"
                  >
                    UBO
                  </mat-checkbox>
                  <div
                    fxLayout="row"
                    class="field shares"
                    *ngIf="isUBOControl?.value"
                  >
                    <label class="ubo-shares" for="percentageOwnedByUBO"
                      >% of shares owned</label
                    >
                    <mat-form-field appearance="outline" class="small">
                      <input
                        placeholder="0.00"
                        type="number"
                        min="10"
                        max="100"
                        step=".01"
                        matInput
                        name="percentageOwnedByUBO"
                        formControlName="percentageOwnedByUBO"
                      />
                      <div matTextSuffix>%</div>
                      <mat-error
                        data-testid="validation-message-box"
                        *ngIf="
                          percentageOwnedByUBOControl?.hasError('required')
                        "
                        >Required</mat-error
                      >
                      <mat-error
                        data-testid="validation-message-box"
                        *ngIf="percentageOwnedByUBOControl?.hasError('pattern')"
                        >Use format: 0.00</mat-error
                      >
                      <mat-error
                        data-testid="validation-message-box"
                        *ngIf="percentageOwnedByUBOControl?.hasError('min')"
                        >Value must be greater than or equal to 10</mat-error
                      >
                      <mat-error
                        data-testid="validation-message-box"
                        *ngIf="percentageOwnedByUBOControl?.hasError('max')"
                        >Value must be less than or equal to 100</mat-error
                      >
                    </mat-form-field>
                  </div>
                </div>
                <mat-error
                  *ngIf="directorForm?.hasError('atLeastOneCheckboxChecked')"
                  >At least one option is required to be selected</mat-error
                >
              </div>
            </app-onboarding-form-item-new>
          </div>

          <div *ngIf="!isLegalPersonControl?.value">
            <h2>Tax Details</h2>
            <app-onboarding-form-item-new
              label="Tax Identification Number"
              [isReviewed]="isReviewed"
              [value]="director.taxNumber"
              [field]="
                fieldsMap.get(OnboardingFieldId.BUSINESS_KEY_PERSON_TAX_NUMBER)
              "
            >
              <div fxLayout="column" class="field short">
                <label for="taxNumber">Tax Identification Number</label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    name="taxNumber"
                    formControlName="taxNumber"
                  />
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="taxNumberControl?.hasError('required')"
                    >Required</mat-error
                  >
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="taxNumberControl?.hasError('pattern')"
                    >Invalid character</mat-error
                  >
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="taxNumberControl?.hasError('maxlength')"
                    >Too many characters</mat-error
                  >
                </mat-form-field>
              </div>
            </app-onboarding-form-item-new>

            <app-onboarding-form-item-new
              label="Country of Tax Residency (if applicable)"
              [isReviewed]="isReviewed"
              [value]="getCountryById(countries, director.taxResidencyCountry)"
              [field]="
                fieldsMap.get(
                  OnboardingFieldId.BUSINESS_KEY_PERSON_TAX_RESIDENCY_COUNTRY
                )
              "
            >
              <div fxLayout="column" class="field short">
                <label for="taxResidencyCountry"
                  >Country of Tax Residency (if applicable)</label
                >
                <mat-form-field appearance="outline">
                  <mat-select
                    placeholder="Please select"
                    name="taxResidencyCountry"
                    formControlName="taxResidencyCountry"
                  >
                    <mat-option
                      *ngFor="let taxResidencyCountry of countries"
                      [value]="taxResidencyCountry.id"
                      >{{ taxResidencyCountry.name | titlecase }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
            </app-onboarding-form-item-new>

            <div class="pep-title">
              <h2>Politically Exposed Person (PEP) Details</h2>
              <app-onboarding-form-item-new
                label="Are you (or have you been in the 12 previous months) a PEP or a close family member (spouse, children, parents) or a close associate (any business relations) of a PEP?"
                [isReviewed]="isReviewed"
                [value]="getStringValue(director.isPEP)"
                [field]="
                  fieldsMap.get(OnboardingFieldId.BUSINESS_KEY_PERSON_IS_PEP)
                "
              >
                <div fxLayout="column" class="field pep">
                  <label class="question"
                    >Are you (or have you been in the 12 previous months) a PEP
                    or a close family member (spouse, children, parents) or a
                    close associate (any business relations) of a PEP?</label
                  >
                  <mat-radio-group
                    fxLayout="row"
                    fxLayoutGap="14px"
                    name="isPEP{{ director.id }}"
                    formControlName="isPEP"
                  >
                    <mat-radio-button [value]="true">
                      <div>Yes</div>
                    </mat-radio-button>
                    <mat-radio-button [value]="false">
                      <div>No</div>
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </app-onboarding-form-item-new>
            </div>
          </div>

          <!-- Close links appear when:
            1. in EDD 
            AND
            2. for NOT only authorized contact persons OR for any key person (even authorized person) that has entered close links 
            => Because in case the admin added comment on the status/position of key person who was director and authorized contact person, 
            and client responded to the comment by removing the director role and left only the authorized contact person role,
            then the close links that were requested when he was director will be hidden if the close links appear only for the non authorized persons, 
          -->
          <ng-container
            *ngIf="
              isEDD &&
              (!isOnlyAuthorizedPerson() || director.hasCloseLinks !== null)
            "
          >
            <div fxLayoutAlign="start center">
              <h2>Close links</h2>
              <div>
                <!-- Warning appears when in EDD and close links not complete -->
                <button
                  *ngIf="isEDD && !closeLinksComplete()"
                  disableRipple
                  mat-icon-button
                  (click)="selectCompanyCloseLink(director.id)"
                  [matTooltip]="tooltips.editCloseLinkTooltip"
                >
                  <mat-icon class="warning">warning_amber</mat-icon>
                </button>

                <!-- Warning appears when first time in EDD and said has close links but 
                  close links not entered -->
                <button
                  *ngIf="directorForm.hasError('missingCloseLinks')"
                  disableRipple
                  mat-icon-button
                  (click)="selectCompanyCloseLink(director.id)"
                  [matTooltip]="tooltips.addCloseLinksTooltip"
                >
                  <mat-icon class="warning">warning_amber</mat-icon>
                </button>
              </div>
            </div>
            <div class="close-link-title">
              <app-onboarding-form-item-new
                label="Do you have close links?"
                [isReviewed]="isReviewed && !isFirstTimeEDD"
                [value]="getStringValue(director.hasCloseLinks)"
              >
                <div fxLayout="column" class="desc field pep">
                  <label class="question"
                    >Do you have
                    <span [matTooltip]="tooltips.closeLinksTooltip"
                      >close links</span
                    >?</label
                  >
                  <mat-radio-group
                    fxLayout="row"
                    fxLayoutGap="14px"
                    name="hasCloseLinks{{ director.id }}"
                    formControlName="hasCloseLinks"
                  >
                    <mat-radio-button [value]="true">
                      <div>Yes</div>
                    </mat-radio-button>
                    <mat-radio-button [value]="false">
                      <div>No</div>
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </app-onboarding-form-item-new>
            </div>

            <ng-container *ngIf="hasCloseLinksControl?.value">
              <div class="caption field">
                Please provide details of your close links:
              </div>
              <div fxLayout="row" class="field">
                <button
                  [matTooltip]="tooltips.enterCloseLinksTooltip"
                  disableRipple
                  mat-icon-button
                  color="accent"
                  (click)="selectCompanyCloseLink(director.id)"
                >
                  <mat-icon>post_add</mat-icon>
                </button>
              </div>
            </ng-container>
          </ng-container>

          <!-- Display only when form is valid AND 
               for NOT only authorized contact persons OR for any key person (even auth person) that has uploaded documents (e.g. additional documents) -->
          <h2
            *ngIf="
              directorForm.valid &&
              (!isOnlyAuthorizedPerson() || director.documents.length > 0)
            "
            class="id"
          >
            Identification documents
          </h2>

          <!-- Authorized contact person does not do liveness/id/residence check -->
          <div
            *ngIf="
              directorForm.valid &&
              !isOnlyAuthorizedPerson() &&
              !verificationAccepted
            "
          >
            <div class="verify">
              Please ensure that you have your proof of address document ready,
              and that your camera is enabled.
            </div>
            <button
              mat-flat-button
              color="accent"
              class="verification"
              [disabled]="isGettingVerificationUrl"
              [appButtonLoader]="isGettingVerificationUrl"
              (click)="getVerificationUrl()"
            >
              Verify your address
            </button>
          </div>

          <app-director-document-form
            *ngIf="directorForm.valid && verificationAccepted"
            [isReviewed]="isReviewed"
            [show]="!isOnlyAuthorizedPerson() || director.documents.length > 0"
            [isFirstTimeEDD]="isFirstTimeEDD"
            [proofOfAddressLabel]="
              isLegalPersonControl?.value
                ? 'Proof of Operating Address'
                : 'Proof of Address'
            "
            [documents]="director.documents || []"
            [companyDirectorId]="director.id"
            (isComplete)="
              documentsComplete =
                isOnlyAuthorizedPerson() && director.documents.length === 0
                  ? true
                  : $event
            "
          ></app-director-document-form>
        </ng-container>
      </mat-expansion-panel>
      <mat-divider></mat-divider>
    </mat-accordion>
  </ng-container>
</div>

<ng-template #removeButton let-director>
  <button
    *ngIf="!isReviewed"
    mat-icon-button
    class="remove-icon"
    (click)="removeDirector(director)"
    [disabled]="isRemoving"
    [appButtonLoader]="isRemoving"
  >
    <mat-icon svgIcon="cancel"></mat-icon>
  </button>
</ng-template>

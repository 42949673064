import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fromEvent, merge, Subscription } from 'rxjs';
import { debounceTime, startWith, take } from 'rxjs/operators';
import { IdleDialogComponent } from './shared/idle-dialog/idle-dialog.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IdleService {
  timeout = environment.TIMEOUT * 60 * 1000; // time before showing idle dialog in ms
  idleSub: Subscription | null = null;
  constructor(private dialog: MatDialog, private ngZone: NgZone) {}

  startIdleChecking(): void {
    // runOutsideAngular used for not triggering change detection
    this.ngZone.runOutsideAngular(() => {
      this.idleSub?.unsubscribe();
      this.idleSub = merge(
        fromEvent(window, 'mousemove'),
        fromEvent(window, 'click'),
        fromEvent(window, 'keydown')
      )
        .pipe(startWith(null), debounceTime(this.timeout), take(1))
        .subscribe(() => {
          this.ngZone.run(() => {
            this.dialog
              .open(IdleDialogComponent, {
                maxWidth: '100%',
                disableClose: true,
              })
              .beforeClosed()
              .subscribe((shouldStartIdleCheck: boolean) => {
                if (shouldStartIdleCheck) {
                  // should trigger checking idle state if user clicked 'continue'
                  this.startIdleChecking();
                }
              });
          });
        });
    });
  }

  stopIdleChecking(): void {
    this.idleSub?.unsubscribe();
  }
}

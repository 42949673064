<div class="main-container" fxLayout>
  <div class="left-section" fxLayout fxLayoutAlign="space-between center">
    <div fxLayout="column">
      <div fxLayout fxLayoutAlign="center center">
        <mat-icon [svgIcon]="svgIconName"></mat-icon>
        <div class="name">
          <span> {{ name }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-title fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content fxLayout="column" fxLayoutGap="20px">
  <h1>Change password</h1>
  <ng-container [formGroup]="passwordForm" *ngIf="!isSuccess">
    <div class="description">
      Please type the current password and the new one (twice).
    </div>
    <div class="content">
      <div fxLayout="column">
        <label for="currentPassword">Current password</label>
        <mat-form-field appearance="outline">
          <input
            matInput
            name="currentPassword"
            [type]="hideCurrentPassword ? 'password' : 'text'"
            formControlName="currentPassword"
          />
          <button
            mat-icon-button
            matIconSuffix
            (click)="hideCurrentPassword = !hideCurrentPassword"
          >
            <mat-icon class="material-icons-outlined">{{
              hideCurrentPassword ? 'visibility' : 'visibility_off'
            }}</mat-icon>
          </button>
          <mat-error *ngIf="currentPasswordControl.hasError('required')"
            >Required</mat-error
          >
          <mat-error *ngIf="currentPasswordControl.hasError('pattern')"
            >Invalid</mat-error
          >
        </mat-form-field>
      </div>
      <app-password
        #newPassword
        (passwordChange)="onNewPasswordChange($event)"
        [newPassword]="true"
      ></app-password>
      <button
        mat-flat-button
        color="accent"
        class="change-button"
        (click)="submit()"
        [disabled]="
          isSending ||
          !newPassword ||
          !passwordForm.valid ||
          !appPasswordForm.valid
        "
        [appButtonLoader]="isSending"
      >
        Change password
      </button>
    </div>
    <button
      mat-stroked-button
      mat-dialog-close
      fxFlexAlign="end"
      color="accent"
      class="cancel"
    >
      Cancel
    </button>
  </ng-container>

  <ng-container *ngIf="isSuccess">
    <div class="success-text">
      <div class="success">Success!</div>
      <div class="description">Password changed.</div>
    </div>
    <button
      mat-flat-button
      mat-dialog-close
      fxFlexAlign="end"
      color="accent"
      class="cancel"
    >
      Close
    </button>
  </ng-container>
</mat-dialog-content>

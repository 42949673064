<div [formGroup]="directorsForm">
  <app-director-shareholder-form-new
    *ngFor="let director of directors; trackBy: trackByFn; let i = index"
    [index]="i"
    [countries]="countries"
    [fields]="fields"
    [isReviewed]="isReviewed"
    [isEDD]="isEDD"
    [isFirstTimeEDD]="isFirstTimeEDD"
    [directorIndex]="getDirectorIndex(director.id)"
    [director]="director"
    [closeLinks]="companyCloseLinks"
    [directorCloseLinks]="companyDirectorCloseLinks"
    (directorChange)="onDirectorChange($event)"
    (directorRemove)="onDirectorRemove($event)"
    (companyCloseLinksChange)="onCloseLinkChange($event)"
    (companyDirectorCloseLinksChange)="onDirectorCloseLinkChange($event)"
    (referenceId)="referenceIdChanged($event)"
    (dialogRef)="dialogRefChanged($event)"
    (isComplete)="setComplete(director.id, $event)"
  ></app-director-shareholder-form-new>
  <mat-error
    *ngIf="directorsForm.controls['directors'].hasError('sharesMoreThan100')"
    >Percentages of shares owned by shareholders exceed 100%</mat-error
  >
  <mat-error
    *ngIf="directorsForm.controls['directors'].hasError('atLeastOnePosition')"
    >There should be only one Authorized contact person, and at least one
    Director, Shareholder, and UBO</mat-error
  >

  <div *ngIf="!isReviewed" fxLayout="row" class="icons">
    <button
      disableRipple
      mat-icon-button
      color="accent"
      [disabled]="isAdding"
      [appButtonLoader]="isAdding"
      (click)="addDirectorShareholder(true)"
      [matTooltip]="tooltips.addNaturalPersonTooltip"
    >
      <mat-icon>person_add_alt_1</mat-icon>
    </button>
    <button
      disableRipple
      mat-icon-button
      color="accent"
      class="legal-person"
      [disabled]="isAddingLegalPerson"
      [appButtonLoader]="isAddingLegalPerson"
      (click)="addDirectorShareholder(false)"
      [matTooltip]="tooltips.addLegalPersonTooltip"
    >
      <mat-icon>domain_add</mat-icon>
    </button>
  </div>
</div>

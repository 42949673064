import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserState } from '../../../login/models/user-state.enum';
import { RegisteredUsersService } from '../../../admin/users/registered-users/registered-users.service';
import { MatSelectChange } from '@angular/material/select';
import { ErrorService } from '../../../shared/error-dialog/error.service';

@Component({
  selector: 'app-avatar-input-new',
  templateUrl: './avatar-input.component.html',
  styleUrls: ['./avatar-input.component.scss'],
})
export class AvatarInputNewComponent implements OnChanges {
  @Input() avatarUrl: string | null = null;

  _errorMessage: string = '';
  get errorMessage() {
    return this._errorMessage;
  }
  @Input() set errorMessage(errorMessage: string) {
    this.setError(errorMessage);
  }

  @Input() isUploading = false;
  @Input() state?: UserState;
  @Input() userId!: number;
  @Input() isAdminProfile: boolean = false;

  @Output() avatarChange = new EventEmitter<File>();
  @Output() avatarRemove = new EventEmitter<void>();
  minWidth = 120;
  minHeight = 120;
  maxFileSizeMB = 15;
  updateStateTo?: UserState;
  disabledSaveStatus: boolean = true;
  isSaving: boolean = false;

  userStatesMap = new Map<UserState, string>([
    [UserState.KYC_VERIFIED, 'KYC Verified'],
    [UserState.CLOSED, 'Closed'],
  ]);

  userStateForm: FormGroup = this.fb.group({
    userState: [this.state, Validators.required],
  });

  private errorTimeout?: any;

  constructor(
    private fb: FormBuilder,
    private registeredUsersService: RegisteredUsersService,
    private errorService: ErrorService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.state) {
      if (this.state) {
        this.userStateForm.patchValue({ userState: this.state });
      }
    }
  }

  dropped(avatar: NgxFileDropEntry): void {
    if (!this.isUploading && avatar && avatar.fileEntry.isFile) {
      (avatar.fileEntry as FileSystemFileEntry).file((file: File) => {
        const img = new Image();
        var fileReader = new FileReader();
        fileReader.onload = () => {
          img.onload = () => {
            if (img.width < this.minWidth || img.height < this.minHeight) {
              this.setError('Image is too small');
            } else if (file.size > this.maxFileSizeMB * 1024 * 1024) {
              this.setError('File is too big');
            } else {
              this.avatarChange.emit(file);
            }
          };
          img.src = fileReader.result as string;
        };
        fileReader.readAsDataURL(file);
      });
    }
  }

  deleteAvatar(): void {
    this.avatarRemove.emit();
  }

  onSelectionChange(event: MatSelectChange): void {
    this.updateStateTo = event.value;
    this.disabledSaveStatus = false;
  }

  saveUserState() {
    if (this.updateStateTo) {
      this.isSaving = true;
      this.disabledSaveStatus = true;
      this.registeredUsersService
        .updateStatus(this.userId, this.updateStateTo)
        .subscribe(
          () => {
            this.disabledSaveStatus = true;
            this.isSaving = false;
          },
          (error) => {
            this.isSaving = false;
            this.disabledSaveStatus = false;
            this.errorService.showErrorDialog(error.error.message);
          }
        );
    }
  }

  private clearError(): void {
    if (this.errorTimeout) {
      clearTimeout(this.errorTimeout);
    }
    this._errorMessage = '';
  }

  private setError(message: string): void {
    this._errorMessage = message;
    this.errorTimeout = setTimeout(() => this.clearError(), 5000);
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { matchValidator } from 'src/app/shared/match.validator';

enum RegexCodes {
  AT_LEAST_10_CHARS = '(?=.{10,})',
  USE_NUMBER = '(?=.*[0-9])',
  USE_SPECIAL_CHAR = '(?=.*[-!@$%^&*()_+|~=`{}[\\]\\\\:";\'<>?,.\\/])',
  USE_CAPITAL = '(?=.*[A-Z])',
}

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit, OnDestroy {
  @Input() extendPasswordRepeat: boolean = false;
  @Input() newPassword: boolean = false;

  @Output() passwordChange = new EventEmitter<string | null>();

  hidePassword = true;
  hidePasswordRepeat = true;
  extendAll = false;

  private valueChangesSubscription?: Subscription;

  passwordConditions: { code: RegexCodes; description: string }[] = [
    {
      code: RegexCodes.AT_LEAST_10_CHARS,
      description: 'At least 10 characters',
    },
    { code: RegexCodes.USE_NUMBER, description: 'Number' },
    {
      code: RegexCodes.USE_SPECIAL_CHAR,
      description: 'Symbol (! ? $ @ % & etc.)',
    },
    {
      code: RegexCodes.USE_CAPITAL,
      description: 'Capital (upper-case letter)',
    },
  ];

  passwordForm = this.fb.group(
    {
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(
            new RegExp(
              `${RegexCodes.AT_LEAST_10_CHARS}${RegexCodes.USE_NUMBER}${RegexCodes.USE_SPECIAL_CHAR}${RegexCodes.USE_CAPITAL}`
            )
          ),
        ],
      ],
      passwordRepeat: ['', Validators.required],
    },
    {
      validators: [matchValidator('password', 'passwordRepeat')],
    }
  );

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.valueChangesSubscription = this.passwordForm.valueChanges
      .pipe(
        map((v) => (this.passwordForm.valid ? v : null) as string),
        distinctUntilChanged()
      )
      .subscribe((v) => this.passwordChange.emit(v));
  }

  getGroup(): FormGroup {
    return this.passwordForm;
  }

  public get password(): string {
    return this.passwordForm.get('password')?.value;
  }

  public get passwordRepeat(): string {
    return this.passwordForm.get('passwordRepeat')?.value;
  }

  ngOnDestroy(): void {
    this.valueChangesSubscription?.unsubscribe();
  }
}

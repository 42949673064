import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { RiskAssessmentService } from '../risk-assessment.service';
import {
  getJurisdictionName,
  getStringValue,
} from 'src/app/shared/helpers/various-helpers.helper';
import {
  convertToString,
  isNonNullObject,
  getObjectEntries,
  getFirstObjectProperties,
  getObjectProperties,
  formatJson,
  isObject,
  isString,
  isArray,
} from '../helpers.helper';

export interface ShowKybHitDialogData {
  kybHitId: number;
  reportId: number;
  userId: number;
}

@Component({
  selector: 'app-show-kyb-hit-dialog',
  templateUrl: './show-kyb-hit-dialog.component.html',
  styleUrls: ['./show-kyb-hit-dialog.component.scss'],
})
export class ShowKybHitDialogComponent implements OnInit {
  isLoadingKybHit: boolean = false;

  kybHitId!: number;
  userId!: number;

  kybHit: any;

  getJurisdictionName = getJurisdictionName;
  getStringValue = getStringValue;
  convertToString = convertToString;
  isString = isString;
  isNonNullObject = isNonNullObject;
  isObject = isObject;
  getObjectEntries = getObjectEntries;
  getFirstObjectProperties = getFirstObjectProperties;
  getObjectProperties = getObjectProperties;
  isArray = isArray;
  formatJson = formatJson;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ShowKybHitDialogData,
    private riskAssessmentService: RiskAssessmentService,
    private errorService: ErrorService
  ) {}

  async ngOnInit(): Promise<void> {
    this.userId = this.data.userId;
    this.kybHitId = this.data.kybHitId;
    await this.getKybHit();
  }

  async getKybHit() {
    this.isLoadingKybHit = true;
    (
      await this.riskAssessmentService.getKybHit(this.kybHitId, this.userId)
    ).subscribe(
      (kybHit) => {
        this.kybHit = kybHit;
        this.isLoadingKybHit = false;
      },
      (error) => {
        this.isLoadingKybHit = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }
}

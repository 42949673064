import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { RegisteredUserDocument } from '../../../../models/registered-user-document.model';
import { AuthService } from '../../../../../../../login/services/auth.service';
import { environment } from '../../../../../../../../environments/environment';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';

@Component({
  selector: 'app-documents-table',
  templateUrl: './documents-table.component.html',
  styleUrls: ['./documents-table.component.scss'],
})
export class DocumentsTableComponent implements OnChanges, OnInit {
  @Input() userId!: number;
  @Input() displayedDocuments!: string[];
  @Input() isRowColored!: boolean;
  @Input() isSecondRowColored!: boolean;
  @Input() isLoadingTable!: boolean;
  @Input() documents?: RegisteredUserDocument[];

  displayedColumns: string[] = [
    'type',
    'name',
    'extension',
    'uploadedDate',
    'expiryDate',
    'state',
  ];
  matTableDataSource = new MatTableDataSource<RegisteredUserDocument>();

  private sortedData?: RegisteredUserDocument[];

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private httpClient: HttpClient,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.matTableDataSource.filterPredicate = (
      data: RegisteredUserDocument,
      filter: string
    ) => {
      filter = filter.toLowerCase();
      if (data.anotherFileName) {
        return data.anotherFileName.toLowerCase().includes(filter);
      }
      return (
        data.name.toLowerCase().includes(filter) ||
        data.type.toLowerCase().includes(filter)
      );
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.documents) {
      if (this.documents) {
        this.matTableDataSource.data = this.documents.sort((a, b) =>
          this.compare(a.uploadedDate, b.uploadedDate, false)
        );
      }
    }
  }

  downloadAllDocuments(): void {
    const url = `${environment.BACKEND_URL}/admin/users/${this.userId}/documents`;
    this.httpClient
      .get(url, { observe: 'body', responseType: 'arraybuffer' })
      .subscribe(
        (buffer) => {
          var url = window.URL.createObjectURL(new Blob([buffer]));
          var anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = 'documents.zip';
          document.body.appendChild(anchor);
          anchor.click();
          anchor.remove();
        },
        (error) => this.errorService.showErrorDialog(error.error.message)
      );
  }

  sortData(sort: Sort): void {
    const data = this.matTableDataSource.data?.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data?.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'type':
          if (a.anotherFileName && b.anotherFileName) {
            return this.compare(a.anotherFileName, b.anotherFileName, isAsc);
          } else if (b.anotherFileName) {
            return this.compare(a.type, b.anotherFileName, isAsc);
          } else if (a.anotherFileName) {
            return this.compare(a.anotherFileName, b.type, isAsc);
          } else return this.compare(a.type, b.type, isAsc);
        case 'expiryDate':
          return this.compare(
            a.expiryDate ? a.expiryDate : '',
            b.expiryDate ? b.expiryDate : '',
            isAsc
          );
        case 'name':
          return this.compare(a.name, b.name, isAsc);
        case 'extension':
          return this.compare(a.extension, b.extension, isAsc);
        case 'uploadedDate':
          return this.compare(a.uploadedDate, b.uploadedDate, isAsc);
        default:
          return 0;
      }
    });
    this.matTableDataSource.data = this.sortedData;
  }

  applyFilter(event: KeyboardEvent): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.matTableDataSource.filter = filterValue.trim();
  }

  private compare(
    a: number | string,
    b: number | string,
    isAsc: boolean
  ): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}

<app-base-login-page title="You are Verified!">
  <app-login-card imgName="person.svg">
    <app-big-loader *ngIf="isLoading"></app-big-loader>
    <div *ngIf="!isLoading" fxLayout="column" fxLayoutAlign="start center">
      <div class="text">
        <span *ngIf="environment.DISABLE_CARDS">
          <strong> Welcome to eCREDO!</strong><br />
          Please press continue to create your accounts and start using our
          services.</span
        >
        <span *ngIf="!environment.DISABLE_CARDS">
          Final step to gain access to your account: Issuance and delivery of
          your eCREDO Card requires a
          <strong>one-time payment of €{{ feeValue | amount }} </strong>. Please
          proceed with the order of your card using a valid Debit card.
        </span>
      </div>
      <button
        mat-flat-button
        color="accent"
        [appButtonLoader]="isPaying"
        [disabled]="isPaying"
        (click)="startPayment()"
      >
        Continue
      </button>
    </div>
  </app-login-card>
</app-base-login-page>

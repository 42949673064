<div fxLayout="column" class="main">
  <mat-form-field>
    <input
      matInput
      [formControl]="searchControl"
      placeholder="Search favourite"
    />
    <mat-icon matIconSuffix>search</mat-icon>
  </mat-form-field>
  <div *ngIf="isLoading" fxLayoutAlign="center">
    <app-small-loader></app-small-loader>
  </div>

  <ng-container *ngIf="!isLoading">
    <div
      *ngFor="let favourite of favourites"
      fxLayoutAlign="space-between"
      class="favourite"
      (click)="selectFavourite(favourite)"
    >
      <div>
        <div>
          <div class="transaction-name">
            {{ favourite.name }}
          </div>
          <div class="transaction-info">
            {{ favourite.benName }}
          </div>
          <div class="transaction-info" *ngIf="!isE2e">
            {{ favourite.benAccNo }}
          </div>
        </div>
        <div class="transaction-info" [ngSwitch]="favourite.transferType">
          <ng-container *ngSwitchCase="TransferType.SEPA"
            >SEPA Transfer</ng-container
          >
          <ng-container *ngSwitchCase="TransferType.SWIFT"
            >SWIFT Transfer</ng-container
          >
          <ng-container *ngSwitchCase="TransferType.E2E"
            >e2e Now Transfer</ng-container
          >
        </div>
      </div>
      <div class="transaction-currency">
        {{ favourite.rateCurrency }}
      </div>
    </div>
  </ng-container>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import {
  getCountryByCode,
  extractTermsFromFavourite,
  tooltips,
} from 'src/app/shared/helpers/various-helpers.helper';
import { Country } from 'src/app/shared/models/country.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { TransferService } from '../transfer.service';
import { InfoService } from '../../shared/info-dialog/info.service';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { AccountDetails } from 'src/app/dashboard/models/account-details.model';
import { TransferType } from '../shared/enum/transfer-type.enum';
import { FavouriteTransfer } from '../shared/models/favourite-transfer.model';

@Component({
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.scss'],
})
export class FavouritesComponent implements OnInit, OnDestroy {
  isLoadingFavourites: boolean = false;
  isLoading: boolean = false;
  isRemoving: boolean = false;
  removingFavouriteId: string | null = null;
  countries: Country[] = [];
  favourites: FavouriteTransfer[] = [];

  searchControl: FormControl = this.fb.control(null);
  searchSub?: Subscription;
  currentReqSub?: Subscription;
  accountOwnerGuid!: string;
  accounts: AccountDetails[] = [];

  getCountryByCode = getCountryByCode;
  TransferType = TransferType;
  tooltips = tooltips;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private errorService: ErrorService,
    private sharedService: SharedService,
    private transferService: TransferService,
    private infoService: InfoService,
    private dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    forkJoin([
      this.dashboardService.getAccounts(),
      this.sharedService.getAllCountries(),
    ]).subscribe(
      ([accounts, countries]) => {
        this.accounts = [accounts.iban, ...accounts.wallets];
        this.accountOwnerGuid = accounts.accountOwnerGuid;
        this.countries = countries;
        this.isLoading = false;
        this.searchSub = this.searchControl.valueChanges
          .pipe(debounceTime(200), distinctUntilChanged(), startWith(null))
          .subscribe((searchTerm) => this.fetchFavourites(searchTerm));
      },
      () => {
        this.isLoading = false;
        this.errorService.showErrorDialog();
      }
    );
  }

  ngOnDestroy(): void {
    this.searchSub?.unsubscribe();
  }

  makeTransfer(favourite: FavouriteTransfer): void {
    if (favourite.transferType === TransferType.E2E) {
      this.router.navigate(['transfer/e2e-now'], { state: { favourite } });
    } else {
      this.router.navigate(['transfer/external'], { state: { favourite } });
    }
  }

  async removeFavourite(guid: string): Promise<void> {
    this.isRemoving = true;
    this.removingFavouriteId = guid;
    (await this.transferService.deleteFavouriteTransfer(guid)).subscribe(
      () => {
        this.fetchFavourites(this.searchControl.value);
        this.removingFavouriteId = null;
        this.isRemoving = false;
      },
      () => {
        this.removingFavouriteId = null;
        this.errorService.showErrorDialog();
        this.isRemoving = false;
      }
    );
  }

  isRemovingFavourite(guid: string): boolean {
    return this.removingFavouriteId === guid;
  }

  getFromAccountName(favouriteTransfer: FavouriteTransfer) {
    return this.accounts.find(
      (a) =>
        a.id === favouriteTransfer.accountSelectedId &&
        a.guid === favouriteTransfer.accountSelectedGuid
    )?.name;
  }

  showInfo(): void {
    let title: string = 'Favourite Transfers';
    let message1: string =
      'You can save frequently used transfers (e2e Now, SEPA and SWIFT), allowing you to execute them again.';
    this.infoService.showInfoDialog(title, message1, '');
  }

  private async fetchFavourites(searchTerm?: string): Promise<void> {
    this.currentReqSub?.unsubscribe(); // cancels previous request
    this.isLoadingFavourites = true;
    this.currentReqSub = (
      await this.transferService.getFavouriteTransfers(this.accountOwnerGuid)
    ).subscribe(
      (favourites: FavouriteTransfer[]) => {
        this.favourites = searchTerm
          ? favourites.filter((favouriteTransfer: FavouriteTransfer) =>
              extractTermsFromFavourite(favouriteTransfer)
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
            )
          : favourites;
        this.isLoadingFavourites = false;
      },
      () => {
        this.isLoadingFavourites = false;
        this.errorService.showErrorDialog();
      }
    );
  }
}

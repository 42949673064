// string
export function convertToString(value: any): string {
  return String(value);
}

export function isString(data: any) {
  return data !== null && !Array.isArray(data) && typeof data !== 'object';
}

// object
export function isNonNullObject(data: any) {
  // if it is a single object and not an array and
  //    has all null values, don't show the object
  //    has values, show the object
  if (data !== null && !Array.isArray(data) && typeof data === 'object') {
    return !Object.values(data).every((value) => value === null);
  }
  return false;
}

export function isObject(data: any) {
  if (data !== null && typeof data === 'object') {
    return true;
  }
  return false;
}

export function getObjectEntries(obj: any): [string, any][] {
  return Object.entries(obj);
}

export function getFirstObjectProperties(value: any): string[] {
  return value ? Object.keys(value[0]) : [];
}

export function getObjectProperties(obj: any): string[] {
  return obj ? Object.keys(obj) : [];
}

// array
export function isArray(data: any) {
  // if array with objects show objects in table, if empty don't
  return Array.isArray(data) && data.length > 0;
}

// json
export function formatJson(data: any) {
  var formattedText;
  if (data) {
    try {
      formattedText = JSON.stringify(data, null, 2);
    } catch {
      formattedText = data;
    }
  } else {
    formattedText = data;
  }
  return formattedText;
}

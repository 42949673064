import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appButtonLoader]',
})
export class ButtonLoaderDirective {
  @Input('appButtonLoader')
  set isLoading(val: boolean) {
    const firstButtonChild = this.el.nativeElement.children[0];
    const alreadyHasLoader =
      firstButtonChild.classList.contains('button-loader');

    if (val && !alreadyHasLoader) {
      // adds loader
      const showDarkLoader =
        this.el.nativeElement.classList.contains('mat-mdc-icon-button') ||
        this.el.nativeElement.classList.contains('mdc-button--outlined'); // dark version of loader should be shown for these buttons
      const loaderEl = this.renderer2.createElement('img');
      this.renderer2.setProperty(
        loaderEl,
        'src',
        `/assets/img/small-loader${showDarkLoader ? '-dark' : ''}.svg`
      );
      this.renderer2.addClass(loaderEl, 'button-loader');
      this.renderer2.insertBefore(
        this.el.nativeElement,
        loaderEl,
        firstButtonChild
      );
    } else if (!val && alreadyHasLoader) {
      // removes loader
      this.renderer2.removeChild(this.el.nativeElement, firstButtonChild);
    }
    this._isLoading = val;
  }
  get isLoading(): boolean {
    return this._isLoading;
  }

  private _isLoading: boolean = false;

  constructor(
    private el: ElementRef,
    private renderer2: Renderer2
  ) {}
}

<div mat-dialog-title fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content fxLayout="column" fxLayoutGap="20px">
  <h1>Change phone number</h1>
  <ng-container [formGroup]="form" *ngIf="!isSuccess">
    <div class="description">
      Please select the country code and enter your new mobile phone number
      (without re-entering the country code). We will send you a verification
      code via SMS.
    </div>
    <div class="content">
      <div fxLayout="column" class="phone">
        <label for="phoneNumber">New Mobile Number</label>
        <ngx-intl-tel-input
          [enablePlaceholder]="false"
          [searchCountryFlag]="true"
          [separateDialCode]="true"
          name="phoneNumber"
          formControlName="phoneNumber"
        >
        </ngx-intl-tel-input>
        <div class="mat-form-field">
          <div class="mat-form-field-appearance-outline">
            <div class="mat-form-field-wrapper" style="margin-top: 0">
              <div class="mat-form-field-subscript-wrapper">
                <mat-error
                  *ngIf="
                    phoneNumberControl.invalid && phoneNumberControl.touched
                  "
                >
                  {{
                    phoneNumberControl?.hasError('phoneNumberSameWithExisting')
                      ? 'This is your current phone number'
                      : phoneNumberControl?.hasError('unacceptableData')
                        ? 'Data not accepted. Please contact
                  support@ecredo.com'
                        : 'Invalid'
                  }}
                </mat-error>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="column">
        <label>Password</label>
        <mat-form-field appearance="outline">
          <input
            matInput
            formControlName="password"
            [type]="hidePassword ? 'password' : 'text'"
          />
          <mat-error *ngIf="passwordControl.hasError('required')"
            >Required</mat-error
          >
          <mat-error *ngIf="passwordControl.hasError('wrongPassword')"
            >Wrong password</mat-error
          >
          <button
            mat-icon-button
            matIconSuffix
            (click)="hidePassword = !hidePassword"
          >
            <mat-icon class="material-icons-outlined">{{
              hidePassword ? 'visibility' : 'visibility_off'
            }}</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <ng-container *ngIf="isSent">
        <mat-divider class="with-margin"></mat-divider>
        <div class="text">
          We’ve just sent you a verification SMS. Please
          <strong>check your mobile phone</strong> for new messages and insert
          the verification code within the next five minutes.
        </div>
        <div fxLayoutAlign="space-between center" class="verification">
          <div fxLayout="column">
            <label for="otp">Verification code</label>
            <mat-form-field appearance="outline">
              <input matInput formControlName="otp" name="otp" />
              <mat-error *ngIf="otpControl?.hasError('wrongCode')"
                >Wrong code</mat-error
              >
            </mat-form-field>
          </div>
          <button
            mat-flat-button
            color="accent"
            class="verify"
            [disabled]="form.invalid || isVerifying"
            [appButtonLoader]="isVerifying"
            (click)="verify()"
          >
            Verify
          </button>
        </div>
        <mat-divider></mat-divider>
        <div class="send-sms">
          <button
            mat-stroked-button
            color="accent"
            class="g-small"
            [disabled]="
              isSendingAgain ||
              form.get('phoneNumber')?.invalid ||
              isSendAgainDisabled
            "
            [appButtonLoader]="isSendingAgain"
            (click)="submit(true)"
          >
            Send SMS again
            <span *ngIf="isSendAgainDisabled">({{ countdown }}s)</span>
          </button>
          <img
            *ngIf="smsSentAgain"
            src="assets/img/accept.svg"
            alt="sent"
            class="sent-again"
          />
        </div>
      </ng-container>
    </div>
    <button
      mat-flat-button
      *ngIf="!isSent"
      fxFlexAlign="center"
      color="accent"
      [disabled]="form.invalid || isSending"
      [appButtonLoader]="isSending"
      (click)="submit()"
    >
      Send SMS
    </button>
    <button
      mat-stroked-button
      mat-dialog-close
      fxFlexAlign="end"
      color="accent"
      class="cancel"
    >
      Cancel
    </button>
  </ng-container>

  <ng-container *ngIf="isSuccess">
    <div class="success-text">
      <div class="success">Success!</div>
      <div class="description">Phone number changed.</div>
    </div>
    <button
      mat-flat-button
      mat-dialog-close
      fxFlexAlign="end"
      color="accent"
      class="cancel"
    >
      Close
    </button>
  </ng-container>
</mat-dialog-content>

<app-big-loader *ngIf="isLoading"></app-big-loader>
<div *ngIf="!isLoading" fxLayout="column" [formGroup]="transferForm">
  <!-- FIRST STEP -->
  <div
    *ngIf="!isConfirmationStep"
    fxLayout="column"
    fxLayoutGap="30px"
    class="first-step"
  >
    <app-e2e-accounts-selector
      [fromAccount]="fromAccountControl.value"
      [formGroup]="toUserGroup"
      [accountOwnerGuid]="accountOwnerGuid"
      (selectFav)="onFavouriteSelect($event)"
    ></app-e2e-accounts-selector>
    <app-transfer-details-form
      [formGroup]="otherFieldsGroup"
      [beneficiaryName]="nameControl.value"
      [isWrongBeneficiary]="phoneNumberControl.hasError('e2ePhoneNumber')"
      [isSameBeneficiary]="
        phoneNumberControl.hasError('e2ePhoneNumberSameWithUser')
      "
      [isProcessing]="isProcessing"
      (shouldTransfer)="goToConfirmationStep()"
    ></app-transfer-details-form>
  </div>

  <!-- CONFIRMATION STEP -->
  <app-transfer-confirmation
    *ngIf="isConfirmationStep"
    fxFlexAlign="center"
    [fromAccount]="fromAccountControl.value"
    [amount]="amountControl.value"
    [payerDescription]="payerDescriptionControl.value"
    [beneficiaryDescription]="beneficiaryDescriptionControl.value"
    [toName]="nameControl.value"
    [toPhoneNumber]="phoneNumberControl.value"
    [fee]="feeControl.value"
    [isTransferring]="isTransferring"
    [isInvalidOtp]="isInvalidOtp"
    [transactionId]="transactionId"
    [transferBody]="transferBody"
    (transfer)="transfer($event!)"
    (cancel)="cancelTransfer($event)"
  ></app-transfer-confirmation>
</div>

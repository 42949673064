import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  templateUrl: './initial-payment-success.component.html',
  styleUrls: ['./initial-payment-success.component.scss'],
})
export class InitialPaymentSuccessComponent {
  environment = environment;
  constructor() {}
}

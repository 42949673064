<div mat-dialog-title fxLayoutAlign="end">
  <button mat-icon-button data-testid="close-modal-button" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content data-testid="program-details-modal">
  <app-account-header [program]="data"></app-account-header>
  <ng-container [ngSwitch]="data">
    <div *ngSwitchCase="programs.JADE">
      <div fxLayout="column" fxLayoutAlign="start center">
        <div class="price">No monthly subscription</div>
        <button
          mat-stroked-button
          color="accent"
          class="g-small"
          (click)="chooseProgram(programs.JADE)"
          data-testid="choose-jade-program-button-in-modal"
        >
          Choose Jade Account
        </button>
      </div>
      <ul>
        <li>
          <strong>
            Loading on CY IBAN
            <span *ngIf="!environment.DISABLE_WALLET"
              >(incl. e-Wallets)</span
            ></strong
          ><br />
          6,000&euro;/month, 72,000&euro;/year
        </li>
        <li *ngIf="!environment.DISABLE_CARDS">
          <strong>Loading on Card(s)</strong> <br />
          36,000&euro;/year, 3,000&euro;/month
        </li>
        <li *ngIf="!environment.DISABLE_CARDS">
          FREE IBAN loading via any Debit card
        </li>
        <li>
          <strong>SEPA transfers</strong><br />
          FREE all inbound transfers<br />
          FREE outbound transfers up to<br />
          €1,000/transfer
        </li>
        <li *ngIf="!environment.DISABLE_SWIFT">
          <strong>International transfers</strong> <br />
          Outward Transfers via SWIFT from e- Wallets and IBAN
        </li>
        <li><strong>e2e Now</strong></li>
        Instant money transfers between eCREDO clients
        <li *ngIf="!environment.DISABLE_WALLET">
          <strong>E-Wallets</strong><br />
          10 major currency e-Wallets for live currency conversion and outward
          payments
        </li>
        <li *ngIf="!environment.DISABLE_CARDS">
          <strong>Card (s)</strong><br />
          1 physical card. Issuance and delivery €10.00 one time fee.
        </li>
        <li class="missing-feature" *ngIf="!environment.DISABLE_CARDS">
          <strong>Additional cards</strong> -
          <span
            class="switch"
            data-testid="switch-to-emerald-link"
            (click)="switchToEmerald()"
            >Switch to Emerald</span
          >
        </li>

        <li *ngIf="!environment.DISABLE_CARDS">
          FREE ATM Cash withdrawals: 5 X €200<br />
          per month<br />
          Global use of Card<br />
          Instore and online payments<br />
          Including Card 3D secure
        </li>
        <li>
          <!-- had class="missing-feature" -->
          <strong>Upgrade</strong> <br />
          <span
            class="switch"
            data-testid="switch-to-emerald-link"
            (click)="switchToEmerald()"
            >Switch to Emerald</span
          >
        </li>
      </ul>
    </div>
    <div *ngSwitchCase="programs.EMERALD">
      <div fxLayout="column" fxLayoutAlign="start center">
        <div class="price">9.95&euro;/month</div>
        <button
          mat-stroked-button
          color="accent"
          class="g-small"
          data-testid="choose-emerald-program-button-in-modal"
          (click)="chooseProgram(programs.EMERALD)"
        >
          Choose Emerald Account
        </button>
      </div>
      <ul>
        <li>
          <strong>
            Loading on CY IBAN
            <span *ngIf="!environment.DISABLE_WALLET"
              >(incl. e-Wallets)</span
            ></strong
          ><br />
          50,000&euro;/month, 200,000&euro;/year
        </li>
        <li *ngIf="!environment.DISABLE_CARDS">
          <strong>Loading on Card(s)</strong> <br />
          72,000&euro;/year, 6,000&euro;/month
        </li>
        <li *ngIf="!environment.DISABLE_CARDS">
          FREE IBAN loading via any Debit card
        </li>
        <li>
          <strong>SEPA transfers</strong><br />
          FREE all inbound transfers<br />
          FREE outbound transfers up to<br />
          €2,000/transfer
        </li>
        <li *ngIf="!environment.DISABLE_SWIFT">
          <strong>International transfers</strong><br />
          Outward Transfers via SWIFT from e- Wallets and IBAN
        </li>
        <li><strong>e2e Now</strong></li>
        Instant money transfers between eCREDO clients
        <li *ngIf="!environment.DISABLE_WALLET">
          <strong>E-Wallets</strong><br />
          10 major currency e-Wallets for live currency conversion and outward
          payments
        </li>
        <li *ngIf="!environment.DISABLE_CARDS">
          <strong>Card (s)</strong><br />
          1 physical card. Issuance and delivery €5.00 one time fee.
        </li>
        <li *ngIf="!environment.DISABLE_CARDS">
          FREE up to 3 additional cards<br />
          (optional)
        </li>
        <li *ngIf="!environment.DISABLE_CARDS">
          FREE ATM Cash withdrawals: 5 X €200<br />
          per month<br />
          Global use of Card<br />
          Instore and online payments<br />
          Including Card 3D secure
        </li>
      </ul>
    </div>
    <div *ngSwitchCase="programs.ONYX">
      <div fxLayout="column" fxLayoutAlign="start center">
        <div class="price">39.95&euro;/month</div>
        <button
          mat-stroked-button
          color="accent"
          class="g-small"
          data-testid="choose-onyx-program-button-in-modal"
          (click)="chooseProgram(programs.ONYX)"
        >
          Choose Onyx Account
        </button>
      </div>
      <ul>
        <li>
          <strong>
            Loading on CY IBAN
            <span *ngIf="!environment.DISABLE_WALLET"
              >(incl. e-Wallets)</span
            ></strong
          ><br />
          110,000&euro;/month, 1,320,000&euro;/year
        </li>
        <li *ngIf="!environment.DISABLE_CARDS">
          <strong>Loading on Card</strong> <br />
          660,000&euro;/year, 3,000&euro;/month
        </li>
        <li *ngIf="!environment.DISABLE_CARDS">
          FREE IBAN loading via any Debit card
        </li>
        <li>
          <strong>SEPA transfers</strong><br />
          FREE all inbound transfers<br />
        </li>
        <li *ngIf="!environment.DISABLE_SWIFT">
          <strong>International transfers</strong> <br />Unlimited outward
          transfers via SWIFT from e-Wallets and IBAN
        </li>
        <li><strong>Mass payments/Payroll feature</strong></li>
        <li><strong>e2e Now</strong></li>
        Instant money transfers between eCREDO clients
        <li *ngIf="!environment.DISABLE_WALLET">
          <strong>E-Wallets</strong><br />
          10 major currency e-Wallets for live currency conversion and outward
          payments
        </li>
        <li *ngIf="!environment.DISABLE_CARDS">
          <strong>Card (s)</strong><br />
          Create up to 9 Business card(s)
        </li>
        <li *ngIf="!environment.DISABLE_CARDS">
          FREE Issuance and delivery for ALL.<br />
        </li>
        <li *ngIf="!environment.DISABLE_CARDS">
          FREE ATM Cash withdrawals: 3 X €300<br />
          per month/per card<br />
          Global use of Card(s) Instore and online payments Including Card 3D
          secure
        </li>
        <li>
          <!-- had class="missing-feature" -->
          <strong>Upgrade</strong> <br />
          <span
            class="switch"
            data-testid="switch-to-ruby-link"
            (click)="switchToRuby()"
            >Switch to Ruby</span
          >
        </li>
      </ul>
    </div>
    <div *ngSwitchCase="programs.RUBY">
      <div fxLayout="column" fxLayoutAlign="start center">
        <div class="price">99.95&euro;/month</div>
        <button
          mat-stroked-button
          color="accent"
          class="g-small"
          data-testid="choose-ruby-program-button-in-modal"
          (click)="chooseProgram(programs.RUBY)"
        >
          Choose Ruby Account
        </button>
      </div>
      <ul>
        <li>
          <strong>
            Loading on CY IBAN
            <span *ngIf="!environment.DISABLE_WALLET"
              >(incl. e-Wallets)</span
            ></strong
          ><br />
          1,000,000&euro;/month, 3,000,000&euro;/year
        </li>
        <li *ngIf="!environment.DISABLE_CARDS">
          <strong>Loading on Card</strong> <br />
          1,320,000&euro;/year, 110,000&euro;/month
        </li>
        <li>
          <strong>SEPA transfers</strong><br />
          FREE all inbound transfers<br />
          FREE all outbound transfers<br />
        </li>
        <li *ngIf="!environment.DISABLE_SWIFT">
          <strong>International transfers</strong><br />
          Unlimited outward transfers via SWIFT from e-Wallets and IBAN
        </li>
        <li><strong>Mass payments/Payroll feature</strong></li>
        <li><strong>e2e Now</strong></li>
        Instant money transfers between eCREDO clients
        <li *ngIf="!environment.DISABLE_WALLET">
          <strong>E-Wallets</strong><br />
          10 major currency e-Wallets for live currency conversion and outward
          payments
        </li>
        <li *ngIf="!environment.DISABLE_CARDS">
          <strong>Card (s)</strong><br />
          Create up to 9 Business card(s)
        </li>
        <li *ngIf="!environment.DISABLE_CARDS">
          FREE Issuance and delivery for ALL<br />
        </li>
        <li *ngIf="!environment.DISABLE_CARDS">
          FREE ATM Cash withdrawals: 3 X €500<br />
          per month/per card<br />
          Global use of Card(s) Instore and online payments Including Card 3D
          secure
        </li>
      </ul>
    </div>
  </ng-container>
</mat-dialog-content>

export enum UserState {
  INITIATED_REGISTRATION = 1,
  VERIFIED_EMAIL = 2,
  VERIFIED_MOBILE_PHONE_NUMBER = 3,
  ADDITIONAL_INFORMATION_REQUESTED = 6,
  KYC_VERIFIED = 7,
  CLOSED = 10,
  DECISION_POINT = 33,
  EDD = 34,
  REJECTED = 35,
  EDD_SUBMITTED = 36,
  WITHDRAWN = 37,
  KEY_PERSONS_VERIFICATION = 38,
  KEY_PERSONS_DECISION_POINT = 39,
  BUSINESS_CDD_REVIEW = 40,
}

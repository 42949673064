<app-base-login-page
  [title]="'Log in'"
  buttonText="New account"
  buttonRouterLink="/register"
>
  <div
    *ngIf="isIosInfo"
    info
    class="info apple"
    fxLayoutAlign="space-between center"
    fxLayoutGap="10px"
  >
    <mat-icon>apple</mat-icon>
    <div>
      If you would like to keep our app on your desktop, please click on share
      and choose "Add to Home Screen"
    </div>
  </div>

  <div
    *ngIf="isAutomaticLogout"
    info
    class="info"
    fxLayoutAlign="space-between center"
    fxLayoutGap="10px"
  >
    <mat-icon>schedule</mat-icon>
    <div>You’ve been logged out due to inactivity in the application.</div>
  </div>

  <div
    *ngIf="isWrongPassword"
    info
    class="info"
    fxLayoutAlign="space-between center"
    fxLayoutGap="10px"
  >
    <mat-icon>schedule</mat-icon>
    <div>You’ve been logged out for security reasons.</div>
  </div>

  <div
    *ngIf="isPasswordReset"
    info
    class="info"
    fxLayoutAlign="start center"
    fxLayoutGap="12px"
  >
    <img src="/assets/img/checkmark.svg" alt="checkmark" />
    <div data-testid="correct-reset-password-message">
      You have just changed your password.<br />
      Please log in with this new password.
    </div>
  </div>

  <div
    *ngIf="isEmailConfirmationProblem"
    info
    class="info"
    fxLayoutAlign="start center"
    fxLayoutGap="12px"
  >
    <img src="/assets/img/clock.svg" alt="clock" />
    <div>
      The link has expired. Log in and follow the instructions to receive a new
      one.
    </div>
  </div>

  <div
    *ngIf="isVerificationProblem"
    info
    class="info"
    fxLayoutAlign="start center"
    fxLayoutGap="12px"
  >
    <img src="/assets/img/clock.svg" alt="clock" />
    <div>
      The link has expired. <br />Contact
      <a href="mailto:support@ecredo.com">support</a> to receive a new one.
    </div>
  </div>

  <div
    *ngIf="isEmailTokenExpired"
    info
    class="info"
    fxLayoutAlign="space-between center"
    fxLayoutGap="10px"
  >
    <mat-icon>schedule</mat-icon>
    <div>
      The link has expired. Please repeat the process to receive a new one.
    </div>
  </div>

  <div
    *ngIf="isForSecurityReasons"
    info
    class="info"
    fxLayoutAlign="space-between center"
    fxLayoutGap="10px"
  >
    <mat-icon>schedule</mat-icon>
    <div>You’ve been logged out for security reasons.</div>
  </div>

  <div
    *ngIf="isEmailChangeSuccess"
    info
    class="info"
    fxLayoutAlign="start center"
    fxLayoutGap="12px"
  >
    <img src="/assets/img/checkmark.svg" alt="checkmark" />
    <div>
      You have just changed your email.<br />
      Please log in with this new email.
    </div>
  </div>

  <div
    *ngIf="isEmailChangeProblem"
    info
    class="info"
    fxLayoutAlign="start center"
    fxLayoutGap="12px"
  >
    <img src="/assets/img/clock.svg" alt="clock" />
    <div>
      The link has expired. <br />
      Log in with your initial email.
    </div>
  </div>

  <app-login-card imgName="key.svg" [formGroup]="loginForm">
    <ng-container *ngIf="!requestOtp">
      <div class="text" fxFlexAlign="center">Enter your email and password</div>
      <div fxLayout="column">
        <label for="login">Email</label>
        <mat-form-field appearance="outline">
          <input
            matInput
            formControlName="login"
            name="login"
            data-testid="email-input"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <label for="password">Password</label>
        <mat-form-field appearance="outline">
          <input
            matInput
            formControlName="password"
            name="password"
            data-testid="password-input"
            [type]="hidePassword ? 'password' : 'text'"
            autocomplete="off"
          />
          <button
            mat-icon-button
            matIconSuffix
            (click)="hidePassword = !hidePassword"
            data-testid="show-hide-password-button"
          >
            <mat-icon class="material-icons-outlined">{{
              hidePassword ? 'visibility' : 'visibility_off'
            }}</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <mat-checkbox
        formControlName="rememberMe"
        fxFlexAlign="start"
        data-testid="remember-me-checkbox-text"
        color="primary"
        >Remember me</mat-checkbox
      >

      <div
        *ngIf="errorStatus"
        data-testid="validation-message-box"
        class="login-error"
        [ngSwitch]="errorStatus"
      >
        <ng-container *ngSwitchCase="400">
          Wrong email and/or password.<br />
          Please double-check and try again.<br />
          <strong>WARNING:</strong> If you incorrectly try to log in 3 times,
          your account will be temporarily locked and you will need to contact
          <a href="mailto:support@ecredo.com">support&#64;ecredo.com</a>
        </ng-container>
        <ng-container *ngSwitchCase="403">
          Your account has been locked for security reasons. Please proceed with
          <a href="/reset-password">I forgot my password</a> to unlock your
          account. Alternatively you may contact
          <a href="mailto:support@ecredo.com">support&#64;ecredo.com</a>.
        </ng-container>
        <ng-container *ngSwitchCase="422">
          Unable to login, please contact
          <a href="mailto:support@ecredo.com">support&#64;ecredo.com</a>.
        </ng-container>
      </div>
      <div
        *ngIf="isResetPassword403"
        data-testid="validation-message-box"
        class="login-error"
      >
        <ng-container>
          Your account has been locked for security reasons. Please contact
          <a href="mailto:support@ecredo.com">support&#64;ecredo.com</a> to
          unlock your account and reset your password.
        </ng-container>
      </div>
      <button
        mat-flat-button
        type="submit"
        (click)="checkOtpIfRequired()"
        fxFlexAlign="center"
        color="accent"
        data-testid="login-button"
        [disabled]="loginForm.invalid || isSending"
        [appButtonLoader]="isSending"
      >
        Log in
      </button>
      <a data-testid="forgot-password-link" routerLink="/reset-password"
        >I forgot my password</a
      >
    </ng-container>

    <div class="content">
      <ng-container *ngIf="requestOtp">
        <div class="title">Two-factor authentication</div>
        <div class="text">
          We’ve just sent you a verification SMS. Please
          <strong>check your mobile phone</strong> for new messages and insert
          the verification code within the next five minutes.
        </div>
        <div fxLayoutAlign="space-between center" class="verification">
          <div fxLayout="column">
            <label for="otp">Verification code</label>
            <mat-form-field appearance="outline">
              <input matInput formControlName="otp" name="otp" />
              <mat-error *ngIf="otpControl?.hasError('wrongCode')"
                >Wrong code</mat-error
              >
            </mat-form-field>
          </div>
          <button
            mat-flat-button
            color="accent"
            (click)="logIn()"
            [disabled]="
              loginForm.invalid || otpErrorStatus === 403 || isVerifying
            "
            [appButtonLoader]="isVerifying"
            class="verify"
          >
            Verify
          </button>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="device">
          <mat-checkbox
            formControlName="rememberDevice"
            color="primary"
            class="checkbox"
            >Remember this device</mat-checkbox
          >
          <mat-icon
            class="material-icons-outlined icon"
            color="accent"
            [matTooltip]="tooltips.otpTooltip"
            >info</mat-icon
          >
        </div>
        <mat-divider></mat-divider>
        <div class="send-sms">
          <button
            mat-stroked-button
            color="accent"
            class="g-small"
            [disabled]="
              isSendingAgain || otpErrorStatus === 403 || isSendAgainDisabled
            "
            [appButtonLoader]="isSendingAgain"
            (click)="checkOtpIfRequired(true)"
          >
            Send SMS again
            <span *ngIf="isSendAgainDisabled && otpErrorStatus !== 403"
              >({{ countdown }}s)</span
            >
          </button>
          <img
            *ngIf="smsSentAgain && otpErrorStatus !== 403"
            src="assets/img/accept.svg"
            alt="sent"
            class="sent-again"
          />
          <mat-icon
            *ngIf="otpErrorStatus === 403"
            svgIcon="cancel"
            class="sent-again cancel"
          ></mat-icon>
        </div>
        <div *ngIf="otpErrorStatus === 403" class="login-error">
          Your account has been locked for security reasons. Please proceed with
          <a href="/reset-password">I forgot my password</a> to unlock your
          account. Alternatively you may contact
          <a href="mailto:support@ecredo.com">support&#64;ecredo.com</a>.
        </div>
      </ng-container>
    </div>
  </app-login-card>
</app-base-login-page>

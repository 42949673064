<app-onboarding-layout-new [steps]="steps">
  <h1 onboardingLayoutTitle data-testid="page-title-text">
    Submitted application
  </h1>
  <mat-card class="title">
    <span *ngIf="isEDDSubmitted"
      >Your application will be reviewed shortly and you will be notified by
      email within 72 hours.</span
    >
    <span *ngIf="!isEDDSubmitted"
      >Your application will be reviewed shortly and you will be notified by
      email.</span
    >
  </mat-card>

  <!-- STEP 0 - Fill in the declaration form -->
  <app-expansion-panel-new [expanded]="false" name="Declaration Form">
    <div
      fxLayout="column"
      fxLayoutGap="0px"
      fxLayoutGap.lt-sm="8px"
      class="submitted-list"
    >
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">
          I acknowledge the industry that the individual is involved does NOT
          include: Arms & Weapons, Binary Options Trading/Cryptocurrency,
          Greencard via Lotteries, Illegal downloads, Illegal Gambling, Illegal
          Pharmaceuticals, Illegal Tobacco sales, Pyramid and Ponzi Schemes,
          Timeshares, Unregistered Charities, Unregulated Financial Services,
          Shell banks and companies.
        </div>
        <div fxFlex="50">
          {{
            getStringValue(onboardingData.personalForm?.activitiesNotInclude)
          }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">
          I declare that the information that will be provided in this
          application form, is to the best of my knowledge, true, accurate and
          complete. I understand that I need to immediately inform eCREDO
          Limited about any changes.
        </div>
        <div fxFlex="50">
          {{
            getStringValue(onboardingData.personalForm?.isCorrectInformation)
          }}
        </div>
      </div>
    </div>
  </app-expansion-panel-new>

  <!-- STEP 2 - Form -->
  <app-expansion-panel-new [expanded]="false" name="Form" class="step-2">
    <div
      fxLayout="column"
      fxLayoutGap="0px"
      fxLayoutGap.lt-sm="8px"
      class="submitted-list"
    >
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">First name</div>
        <div data-testid="first-name-input" fxFlex="50">
          {{ onboardingData.personalForm?.firstName }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Last name</div>
        <div data-testid="last-name-input" fxFlex="50">
          {{ onboardingData.personalForm?.lastName }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Date of Birth</div>
        <div data-testid="date-of-birth-input" fxFlex="50">
          {{ onboardingData.personalForm?.dateOfBirth | date }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Country of Birth</div>
        <div data-testid="country-of-birth-cy" fxFlex="50">
          {{
            getCountryById(
              countries,
              onboardingData.personalForm?.countryOfBirth
            )
          }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Nationality</div>
        <div data-testid="nationality-cy" fxFlex="50">
          {{
            getNationalityById(
              countries,
              onboardingData.personalForm?.nationality
            )
          }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Address</div>
        <div data-testid="street-address-input" fxFlex="50">
          {{ onboardingData.personalForm?.address?.streetAddress }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Address 2nd line</div>
        <div data-testid="street-address-second-part-input" fxFlex="50">
          {{
            onboardingData.personalForm?.address?.additionalStreetAddress || '-'
          }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Postal code, City</div>
        <div data-testid="post-code-input" fxFlex="50">
          {{ onboardingData.personalForm?.address?.postCode }}
          {{ onboardingData.personalForm?.address?.city }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Country</div>
        <div data-testid="country-option-cy" fxFlex="50">
          {{
            getCountryById(
              countries,
              onboardingData.personalForm?.address?.countryId
            )
          }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">
          The address above is my temporary residence in EEA
        </div>
        <div data-testid="is-temp-address" fxFlex="50">
          {{ getStringValue(onboardingData.personalForm?.isTempAddress) }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Additional phone number</div>
        <div data-testid="optional-phone-number-input" fxFlex="50">
          {{ onboardingData.personalForm?.additionalPhoneNumber || '-' }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Additional email</div>
        <div data-testid="secondary-email-input" fxFlex="50">
          {{ onboardingData.personalForm?.additionalEmail || '-' }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">What is your occupation status?</div>
        <div data-testid="profession-option-self-employed" fxFlex="50">
          {{ onboardingData.personalForm?.dataOccupationProfession }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">What industry are you involved in?</div>
        <div data-testid="occupation-option-education" fxFlex="50">
          {{ onboardingData.personalForm?.dataOccupation }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">What is the main purpose of your account?</div>
        <div data-testid="card-purpose-option-receive-my-salary" fxFlex="50">
          {{ onboardingData.personalForm?.dataCardPurpose }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">What is your monthly income?</div>
        <div data-testid="monthly-income-option-more-than-3000-eur" fxFlex="50">
          {{ getMonthlyIncome(onboardingData.personalForm?.dataMonthlyIncome) }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">What is your monthly spend?</div>
        <div data-testid="monthly-spend-option-more-than-3000-eur" fxFlex="50">
          {{ getMonthlySpend(onboardingData.personalForm?.dataMonthlySpend) }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">
          Are you (or have you been in the 12 previous months) a PEP or a close
          family member (spouse, children, parents) or a close associate (any
          business relations) of a PEP?
        </div>
        <div
          data-testid="is-pep"
          fxFlex="50"
          *ngIf="onboardingData.personalForm?.isPEP !== null"
        >
          {{ getStringValue(onboardingData.personalForm?.isPEP) }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Tax Identification Number</div>
        <div data-testid="tax-number" fxFlex="50">
          {{ onboardingData.personalForm?.dataTaxNumber }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">VAT Number</div>
        <div data-testid="vat-number" fxFlex="50">
          {{ onboardingData.personalForm?.dataVatNumber }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Country of Tax Residency/VAT Number</div>
        <div data-testid="tax-residency-country-option" fxFlex="50">
          {{
            getCountryById(
              countries,
              onboardingData.personalForm?.dataTaxAndVatCountry
            ) || '-'
          }}
        </div>
      </div>

      <ng-container *ngIf="showExtraQuestions">
        <div
          fxLayout="row"
          fxLayoutGap="20px grid"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="4px grid"
        >
          <div fxFlex="50">Source of Wealth</div>
          <div data-testid="source-of-wealth-option" fxFlex="50">
            {{ onboardingData.personalForm?.dataSourceOfWealth }}
          </div>
        </div>

        <div
          fxLayout="row"
          fxLayoutGap="20px grid"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="4px grid"
        >
          <div fxFlex="50">Country of Origin (Source of Wealth)</div>
          <div data-testid="country-of-source-of-wealth-option" fxFlex="50">
            {{
              getCountryById(
                countries,
                onboardingData.personalForm?.countryOfSourceOfWealth
              )
            }}
          </div>
        </div>

        <div
          fxLayout="row"
          fxLayoutGap="20px grid"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="4px grid"
        >
          <div fxFlex="50">Source of Income</div>
          <div data-testid="source-of-income-option" fxFlex="50">
            {{ onboardingData.personalForm?.dataSourceOfIncome }}
          </div>
        </div>

        <div
          fxLayout="row"
          fxLayoutGap="20px grid"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="4px grid"
        >
          <div fxFlex="50">Country of Origin (Source of Income)</div>
          <div data-testid="country-of-source-of-income-option" fxFlex="50">
            {{
              getCountryById(
                countries,
                onboardingData.personalForm?.countryOfSourceOfIncome
              )
            }}
          </div>
        </div>

        <div
          fxLayout="row"
          fxLayoutGap="20px grid"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="4px grid"
        >
          <div fxFlex="50">Size of Wealth</div>
          <div data-testid="size-of-wealth-option" fxFlex="50">
            {{ getSizeOfWealth(onboardingData.personalForm?.dataSizeOfWealth) }}
          </div>
        </div>
      </ng-container>

      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">Email of your referee (optional)</div>
        <div data-testid="email-of-your-referee" fxFlex="50">
          {{ onboardingData.personalForm?.referee || '-' }}
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="20px grid"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="4px grid"
      >
        <div fxFlex="50">How did you hear about us? (optional)</div>
        <div data-testid="heard-from-input" fxFlex="50">
          {{ onboardingData.personalForm?.heardFrom || '-' }}
        </div>
      </div>
    </div>
  </app-expansion-panel-new>

  <!-- STEP 1 - Uploaded documents -->
  <app-expansion-panel-new
    [expanded]="false"
    name="Uploaded documents"
    class="step-1"
  >
    <div
      fxLayout="row wrap"
      fxLayoutGap="25px grid"
      fxLayoutAlign="space-between"
    >
      <app-file-input
        *ngFor="let control of documentsControls"
        [formControl]="control"
        [viewOnly]="true"
        fxFlex="50"
        fxFlex.lt-sm="100"
      ></app-file-input>
    </div>
  </app-expansion-panel-new>
</app-onboarding-layout-new>

import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { Card } from '../../../dashboard/models/card.model';
import { DashboardService } from '../../../dashboard/dashboard.service';
import { ErrorService } from '../../../shared/error-dialog/error.service';

@Component({
  selector: 'app-show-pin-dialog',
  templateUrl: './show-pin-dialog.component.html',
  styleUrls: ['./show-pin-dialog.component.scss'],
})
export class ShowPinDialogComponent implements OnInit {
  card?: Card;

  constructor(
    private dialogRef: MatDialogRef<ShowPinDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data: Card,
    private dashboardService: DashboardService,
    private errorService: ErrorService
  ) {
    this.card = data;
  }

  ngOnInit() {
    this.dashboardService.pinRemind(this.card!.numAccountID).subscribe(
      () => {},
      (error) => {
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }
}

import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {
  getCountryById,
  getFoundValue,
  getScoreValue,
  getStringValue,
  isHigh,
  tooltips,
} from 'src/app/shared/helpers/various-helpers.helper';
import { Country } from 'src/app/shared/models/country.model';
import { RiskReportDirector } from 'src/app/shared/models/risk-report-director.model';
import { RiskReportGroupMember } from 'src/app/shared/models/risk-report-group-member.model';
import { RiskReport } from 'src/app/shared/models/risk-report.model';
import { MatTableDataSource } from '@angular/material/table';
import { CompanyDirector } from 'src/app/onboarding-new/models/company-director.model';
import { CompanyGroupMember } from 'src/app/onboarding-new/models/company-group-member.model';
import { RiskReportCounterparty } from 'src/app/shared/models/risk-report-bank-account.model';
import { RiskReportBankAccount } from 'src/app/shared/models/risk-report-counterparty.model';
import { UsersService } from 'src/app/admin/users/users.service';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { RiskReports } from 'src/app/shared/models/risk-reports.model';
import { RiskReportCloseLink } from 'src/app/shared/models/risk-report-close-link.model';
import { CompanyCloseLink } from 'src/app/onboarding-new/models/company-close-link.model';
import { ShowAmlHitsDialogComponent } from '../show-aml-hits-dialog/show-aml-hits-dialog.component';
import { RiskReportType } from '../enums/risk-report-type.enum';
import { DateTime } from 'luxon';
import { ShowKybHitsDialogComponent } from '../show-kyb-hits-dialog/show-kyb-hits-dialog.component';

export interface DetailedReportDialogData {
  isCorporate: boolean;
  personalReport: RiskReport;
  entityReport: RiskReport;
  directorReports: RiskReportDirector[];
  groupMemberReports: RiskReportGroupMember[];
  closeLinkReports: RiskReportCloseLink[];
  companyDirectors: CompanyDirector[];
  companyGroupMembers: CompanyGroupMember[];
  companyCloseLinks: CompanyCloseLink[];
  counterpartyRiskReport: RiskReportCounterparty[];
  bankAccountRiskReport: RiskReportBankAccount[];
  name: string;
  countries: Country[];
  currentReportId: number;
  proposalReportId: number;
  isProposalReport: boolean;
  isInOnboarding: boolean;
  adjustmentSuggestionMade: boolean;
  eddSuggestionMade: boolean;
  viewOnlyState: boolean;
}

@Component({
  selector: 'app-detailed-report-dialog',
  templateUrl: './detailed-report-dialog.component.html',
  styleUrls: ['./detailed-report-dialog.component.scss'],
})
export class DetailedReportDialogComponent implements OnInit {
  isCorporate!: boolean;
  personalReport!: RiskReport;
  entityReport!: RiskReport;
  directorReports!: RiskReportDirector[];
  groupMemberReports!: RiskReportGroupMember[];
  closeLinkReports!: RiskReportCloseLink[];
  companyDirectors!: CompanyDirector[];
  companyGroupMembers!: CompanyGroupMember[];
  companyCloseLinks!: CompanyCloseLink[];
  name!: string;
  currentReportId!: number;
  proposalReportId?: number;
  isProposalReport!: boolean;
  isInOnboarding!: boolean;
  adjustmentSuggestionMade!: boolean;
  eddSuggestionMade!: boolean;
  viewOnlyState!: boolean;

  counterpartyRiskReport!: RiskReportCounterparty[];
  bankAccountRiskReport!: RiskReportBankAccount[];

  public displayedColumns: string[] = [];
  public displayedColumnsAML: string[] = [];

  countries!: Country[];
  dataSourcePersonal!: MatTableDataSource<RiskReport>;
  dataSourceEntity!: MatTableDataSource<RiskReport>;
  dataSourceDirector!: MatTableDataSource<RiskReportDirector>;
  dataSourceGroupMember!: MatTableDataSource<RiskReportGroupMember>;
  dataSourceCloseLink!: MatTableDataSource<RiskReportCloseLink>;
  isSavingRiskForm: boolean = false;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DetailedReportDialogData,
    private errorService: ErrorService,
    private usersService: UsersService,
    private dialog: MatDialog
  ) {}

  riskReportChange = new EventEmitter<RiskReports>();

  getCountryById = getCountryById;
  getStringValue = getStringValue;
  getFoundValue = getFoundValue;
  getScoreValue = getScoreValue;
  isHigh = isHigh;
  RiskReportType = RiskReportType;

  form: FormGroup = this.fb.group({
    personalReportsForm: this.fb.array([]),
    entityReportsForm: this.fb.array([]),
    directorReportsForm: this.fb.array([]),
    groupMemberReportsForm: this.fb.array([]),
    closeLinkReportsForm: this.fb.array([]),
  });
  adjustmentOptions = ['Automatic', 'Low', 'Normal', 'High']; // Automatic means calculated by the system
  amlResultOptions = [
    { id: false, value: 'Not Found' },
    { id: true, value: 'Found' },
  ];
  tooltips = tooltips;

  getAmlLink(reference: string) {
    return (
      'https://backoffice.shuftipro.com/verification-request-details/' +
      reference
    );
  }

  ngOnInit(): void {
    this.isCorporate = this.data.isCorporate;
    this.personalReport = this.data.personalReport;
    this.directorReports = this.data.directorReports;
    this.groupMemberReports = this.data.groupMemberReports;
    this.closeLinkReports = this.data.closeLinkReports;
    this.entityReport = this.data.entityReport;
    this.companyDirectors = this.data.companyDirectors;
    this.companyGroupMembers = this.data.companyGroupMembers;
    this.companyCloseLinks = this.data.companyCloseLinks;
    this.counterpartyRiskReport = this.data.counterpartyRiskReport;
    this.bankAccountRiskReport = this.data.bankAccountRiskReport;
    this.name = this.data.name;
    this.countries = this.data.countries;
    this.currentReportId = this.data.currentReportId;
    this.proposalReportId = this.data.proposalReportId;
    this.isProposalReport = this.data.isProposalReport;
    this.isInOnboarding = this.data.isInOnboarding;
    this.adjustmentSuggestionMade = this.data.adjustmentSuggestionMade;
    this.eddSuggestionMade = this.data.eddSuggestionMade;
    this.viewOnlyState = this.data.viewOnlyState;

    this.displayedColumns = [
      'factors',
      'details',
      'fromAPI',
      'fromUser',
      'score',
      'average',
    ];

    this.displayedColumnsAML = [
      'riskReportId',
      'itemName',
      'foundInSanctionList',
      'foundInWarningList',
      'foundInFitnessProbityList',
      'foundInPEPList',
      'foundInAdverseMediaList',
      'amlHits',
      ...(this.isCorporate ? ['kybHits'] : []),
      'adjustedRiskLevel',
      'dueDiligenceLevel',
    ];

    if (this.isCorporate) {
      this.dataSourceEntity = new MatTableDataSource([this.entityReport]);
      this.dataSourceDirector = new MatTableDataSource(this.directorReports);
      this.dataSourceGroupMember = new MatTableDataSource(
        this.groupMemberReports
      );
      this.dataSourceCloseLink = new MatTableDataSource(this.closeLinkReports);

      this.entityReportsControl.push(
        this.fb.group({
          foundInWarningList: [
            this.entityReport.foundInWarningList,
            Validators.required,
          ],
          foundInFitnessProbityList: [
            this.entityReport.foundInFitnessProbityList,
            Validators.required,
          ],
          foundInPEPList: [
            this.entityReport.foundInPEPList,
            Validators.required,
          ],
          foundInAdverseMediaList: [
            this.entityReport.foundInAdverseMediaList,
            Validators.required,
          ],
          adjustedRiskLevel: [
            this.entityReport.adjustedRiskLevel,
            this.allowUpdateAdjustedRiskLevel() ? Validators.required : '',
          ],
          adjustmentReason: [
            {
              value: this.entityReport.adjustmentReason,
              disabled: !this.allowAmlListAndAdjustmentReasonChange(), // doesn't allow to modify
            },
            this.allowAmlListAndAdjustmentReasonChange()
              ? Validators.required
              : '', // not required e.g. if the report is an original (automatically calculated)
          ],
        })
      );

      this.directorReports.forEach((directorReport) => {
        this.directorReportsControl.push(
          this.fb.group({
            companyDirectorId: [directorReport.companyDirectorId],
            foundInWarningList: [
              directorReport.foundInWarningList,
              Validators.required,
            ],
            foundInFitnessProbityList: [
              directorReport.foundInFitnessProbityList,
              Validators.required,
            ],
            foundInPEPList: [
              directorReport.foundInPEPList,
              Validators.required,
            ],
            foundInAdverseMediaList: [
              directorReport.foundInAdverseMediaList,
              Validators.required,
            ],
          })
        );
      });

      this.groupMemberReports.forEach((groupMemberReport) => {
        this.groupMemberReportsControl.push(
          this.fb.group({
            companyGroupMemberId: [groupMemberReport.companyGroupMemberId],
            foundInWarningList: [
              groupMemberReport.foundInWarningList,
              Validators.required,
            ],
            foundInFitnessProbityList: [
              groupMemberReport.foundInFitnessProbityList,
              Validators.required,
            ],
            foundInPEPList: [
              groupMemberReport.foundInPEPList,
              Validators.required,
            ],
            foundInAdverseMediaList: [
              groupMemberReport.foundInAdverseMediaList,
              Validators.required,
            ],
          })
        );
      });

      this.closeLinkReports.forEach((closeLinkReport) => {
        this.closeLinkReportsControl.push(
          this.fb.group({
            companyCloseLinkId: [closeLinkReport.companyCloseLinkId],
            foundInWarningList: [
              closeLinkReport.foundInWarningList,
              Validators.required,
            ],
            foundInFitnessProbityList: [
              closeLinkReport.foundInFitnessProbityList,
              Validators.required,
            ],
            foundInPEPList: [
              closeLinkReport.foundInPEPList,
              Validators.required,
            ],
            foundInAdverseMediaList: [
              closeLinkReport.foundInAdverseMediaList,
              Validators.required,
            ],
          })
        );
      });
    } else {
      this.dataSourcePersonal = new MatTableDataSource([this.personalReport]);

      this.personalReportsControl.push(
        this.fb.group({
          foundInWarningList: [
            this.personalReport.foundInWarningList,
            Validators.required,
          ],
          foundInFitnessProbityList: [
            this.personalReport.foundInFitnessProbityList,
            Validators.required,
          ],
          foundInPEPList: [
            this.personalReport.foundInPEPList,
            Validators.required,
          ],
          foundInAdverseMediaList: [
            this.personalReport.foundInAdverseMediaList,
            Validators.required,
          ],
          adjustedRiskLevel: [
            this.personalReport.adjustedRiskLevel,
            this.allowUpdateAdjustedRiskLevel() ? Validators.required : '',
          ],
          adjustmentReason: [
            {
              value: this.personalReport.adjustmentReason,
              disabled: !this.allowAmlListAndAdjustmentReasonChange(), // doesn't allow to modify
            },
            this.allowAmlListAndAdjustmentReasonChange()
              ? Validators.required
              : '', // not required e.g. if the report is an original (automatically calculated)
          ],
        })
      );
    }
  }

  getAnnualIncome() {
    if (this.entityReport.annualIncome) {
      return (
        parseInt(this.entityReport.annualIncome).toLocaleString('en-US') +
        ' EUR'
      );
    } else {
      return '-';
    }
  }

  getOperateInHighRiskCountries() {
    return this.entityReport.operateInHighRiskCountries === true
      ? this.name + ' operates in high risk jurisdictions'
      : this.entityReport.operateInHighRiskCountries === false
        ? this.name + ' does not operate in high risk jurisdictions'
        : '-';
  }

  getCategory1IndividualAverage() {
    if (
      this.personalReport.pepScore &&
      this.personalReport.adverseMediaScore &&
      this.personalReport.criminalRecordScore &&
      this.personalReport.dataOccupationProfessionScore &&
      this.personalReport.dataOccupationScore &&
      this.personalReport.dataMonthlyIncomeScore &&
      this.personalReport.dataMonthlySpendScore &&
      this.personalReport.taxAndVatScore &&
      this.personalReport.isTempAddressScore
    ) {
      const sum =
        this.personalReport.pepScore +
        this.personalReport.adverseMediaScore +
        this.personalReport.criminalRecordScore +
        this.personalReport.dataOccupationProfessionScore +
        this.personalReport.dataOccupationScore +
        this.personalReport.dataMonthlyIncomeScore +
        this.personalReport.dataMonthlySpendScore +
        this.personalReport.taxAndVatScore +
        this.personalReport.isTempAddressScore;
      return Math.round(sum / 9);
    } else {
      return '-';
    }
  }

  getCategory1Average() {
    if (
      this.entityReport.directorsPEPScore &&
      this.entityReport.adverseMediaCriminalRecordScore &&
      this.entityReport.groupMemberAmlScore &&
      this.entityReport.financialStatementScore &&
      this.entityReport.companyActivityScore &&
      this.entityReport.percentageOfCashScore &&
      this.entityReport.canFundFromOwnAccountScore &&
      this.entityReport.taxAndVatScore &&
      this.entityReport.isTempAddressScore
    ) {
      const sum =
        this.entityReport.directorsPEPScore +
        this.entityReport.adverseMediaCriminalRecordScore +
        this.entityReport.groupMemberAmlScore +
        this.entityReport.financialStatementScore +
        this.entityReport.companyActivityScore +
        this.entityReport.percentageOfCashScore +
        this.entityReport.canFundFromOwnAccountScore +
        this.entityReport.taxAndVatScore +
        this.entityReport.isTempAddressScore;
      return Math.round(sum / 9);
    } else {
      return '-';
    }
  }

  getCategory3Average() {
    if (
      this.entityReport.jurisdictionsScore &&
      this.entityReport.incOpeCountryScore &&
      this.entityReport.directorCountryScore
    ) {
      const sum =
        this.entityReport.jurisdictionsScore +
        this.entityReport.incOpeCountryScore +
        this.entityReport.directorCountryScore;
      return Math.round(sum / 3);
    } else {
      return '-';
    }
  }

  /**
   * both in risk-assessment and detailed-report (highlight high numbers in red)
   * if single score or average isHigh then make the number red
   * if total risk score is high-risk or at least one score isHigh then make total risk score red
   * if Found in sanction/warning/fitness/adverse/pep list (API), make the word 'Found' red
   */
  atLeastOneHigh(): boolean {
    // red alert if total risk score is high-risk or at least one score isHigh
    if (this.isCorporate) {
      return (
        Number(this.entityReport.totalRiskScore) >= 10 ||
        isHigh(this.entityReport.canFundFromOwnAccountScore) ||
        isHigh(this.entityReport.taxAndVatScore) ||
        isHigh(this.entityReport.isTempAddressScore) ||
        isHigh(this.entityReport.directorsPEPScore) ||
        isHigh(this.entityReport.adverseMediaCriminalRecordScore) ||
        isHigh(this.entityReport.groupMemberAmlScore) ||
        isHigh(this.entityReport.financialStatementScore) ||
        isHigh(this.entityReport.companyActivityScore) ||
        isHigh(this.entityReport.percentageOfCashScore) ||
        isHigh(this.entityReport.jurisdictionsScore) ||
        isHigh(this.entityReport.incOpeCountryScore) ||
        isHigh(this.entityReport.directorCountryScore) ||
        isHigh(this.entityReport.accountPurposeScore)
      );
    } else {
      return (
        Number(this.personalReport.totalRiskScore) >= 10 ||
        isHigh(this.personalReport.pepScore) ||
        isHigh(this.personalReport.adverseMediaScore) ||
        isHigh(this.personalReport.criminalRecordScore) ||
        isHigh(this.personalReport.dataOccupationProfessionScore) ||
        isHigh(this.personalReport.dataOccupationScore) ||
        isHigh(this.personalReport.dataMonthlyIncomeScore) ||
        isHigh(this.personalReport.dataMonthlySpendScore) ||
        isHigh(this.personalReport.taxAndVatScore) ||
        isHigh(this.personalReport.isTempAddressScore) ||
        isHigh(this.personalReport.accountPurposeScore) ||
        isHigh(this.personalReport.countryScore)
      );
    }
  }

  saveProposal(): void {
    this.isSavingRiskForm = true;

    if (this.isCorporate) {
      if (this.isProposalReport) {
        const riskReports: RiskReports = {
          riskReports: this.entityReportsControl.value,
          riskReportsDirector: this.directorReportsControl.value,
          riskReportsGroupMember: this.groupMemberReportsControl.value,
          riskReportsCloseLink: this.closeLinkReportsControl.value,
        };
        this.usersService
          .saveRiskScoreProposal(this.entityReport?.userId, riskReports)
          .subscribe(
            (riskReports) => {
              this.isSavingRiskForm = false;
              this.form.markAsPristine();
              // emit updated risk reports
              this.entityReport = riskReports.riskReports[0];
              // need to update the adjusted risk level in form like this
              this.adjustedRiskLevelControl?.setValue(
                this.entityReport.adjustedRiskLevel
              );
              if (riskReports.riskReportsDirector) {
                this.directorReports = riskReports.riskReportsDirector;
              }
              if (riskReports.riskReportsGroupMember) {
                this.groupMemberReports = riskReports.riskReportsGroupMember;
              }
              if (riskReports.riskReportsCloseLink) {
                this.closeLinkReports = riskReports.riskReportsCloseLink;
              }
              // update adjusted risk level value under AML screening tab
              this.dataSourceEntity = new MatTableDataSource([
                this.entityReport,
              ]);
              const updatedRiskReports: RiskReports = {
                riskReports: riskReports.riskReports,
                riskReportsDirector: riskReports.riskReportsDirector
                  ? riskReports.riskReportsDirector
                  : [],
                riskReportsGroupMember: riskReports.riskReportsGroupMember
                  ? riskReports.riskReportsGroupMember
                  : [],
                riskReportsCloseLink: riskReports.riskReportsCloseLink
                  ? riskReports.riskReportsCloseLink
                  : [],
              };
              this.riskReportChange.emit(updatedRiskReports);
            },
            (error) => {
              this.isSavingRiskForm = false;
              this.errorService.showErrorDialog(error.error.message);
            }
          );
      }
    } else {
      if (this.isProposalReport) {
        const riskReports: RiskReports = {
          riskReports: this.personalReportsControl.value,
        };
        this.usersService
          .saveRiskScoreProposal(this.personalReport?.userId, riskReports)
          .subscribe(
            (riskReports) => {
              this.isSavingRiskForm = false;
              this.form.markAsPristine();
              // emit updated risk reports
              this.personalReport = riskReports.riskReports[0];
              // need to update the adjusted risk level in form like this
              this.adjustedRiskLevelControl?.setValue(
                this.personalReport.adjustedRiskLevel
              );
              // update adjusted risk level value under AML screening tab
              this.dataSourcePersonal = new MatTableDataSource([
                this.personalReport,
              ]);
              const updatedRiskReports: RiskReports = {
                riskReports: riskReports.riskReports,
              };
              this.riskReportChange.emit(updatedRiskReports);
            },
            (error) => {
              this.isSavingRiskForm = false;
              this.errorService.showErrorDialog(error.error.message);
            }
          );
      }
    }
  }

  showAmlHits(reportId: number, riskReportType: RiskReportType): void {
    this.dialog.open<ShowAmlHitsDialogComponent>(ShowAmlHitsDialogComponent, {
      panelClass: 'dialog-with-close-button',
      width: '1800px',
      disableClose: true,
      data: {
        userId: this.isCorporate
          ? this.entityReport.userId
          : this.personalReport.userId,
        reportId,
        riskReportType,
      },
    });
  }

  showKybHits(reportId: number): void {
    this.dialog.open<ShowKybHitsDialogComponent>(ShowKybHitsDialogComponent, {
      panelClass: 'dialog-with-close-button',
      width: '1800px',
      disableClose: true,
      data: {
        userId: this.entityReport.userId,
        reportId,
        kybPayload: this.entityReport.kybPayload,
      },
    });
  }

  // adjusted risk level can only be updated as below:
  // report is a proposal, user is verified and before the adjustment suggestion was sent for approval
  allowUpdateAdjustedRiskLevel() {
    return (
      this.isProposalReport &&
      !this.isInOnboarding &&
      !this.adjustmentSuggestionMade
    );
  }

  // Found/Not Found and adjustment reason can only be updated as below:
  // report is a proposal, and
  // user in onboarding and before edd suggestion was sent for approval or
  // user is verified and before the adjustment suggestion was sent for approval
  allowAmlListAndAdjustmentReasonChange() {
    return (
      this.isProposalReport &&
      ((this.isInOnboarding && !this.eddSuggestionMade) ||
        (!this.isInOnboarding && !this.adjustmentSuggestionMade)) &&
      this.viewOnlyState === false
    );
  }

  isLegalPerson(directorId: number) {
    const director = this.companyDirectors?.find((d) => d.id === directorId);
    return director?.isLegalPerson;
  }

  convertDate(val: string | undefined) {
    return val ? DateTime.fromISO(val).toLocaleString(DateTime.DATE_MED) : '';
  }

  getDirectorPEP() {
    const pepDirectors = this.directorReports
      .filter((report) => report.isPEP || report.foundInPEPList)
      .map((directorReport) => {
        const director = this.companyDirectors.find(
          (dir) => dir.id === directorReport.companyDirectorId
        );
        const name = director?.isLegalPerson
          ? director.legalPersonName
          : director?.firstName + ' ' + director?.lastName;
        if (directorReport.isPEP && directorReport.foundInPEPList) {
          return name + ' is a PEP and was found in PEP lists (API).';
        } else if (directorReport.isPEP) {
          return name + ' is a PEP.';
        } else if (directorReport.foundInPEPList) {
          return name + ' was found in PEP lists (API).';
        } else return '';
      });
    return this.filterResult(pepDirectors);
  }

  getIndividualPEP() {
    const name = this.name;
    if (this.personalReport.isPEP && this.personalReport.foundInPEPList) {
      return name + ' is a PEP and was found in PEP lists (API).';
    } else if (this.personalReport.isPEP) {
      return name + ' is a PEP.';
    } else if (this.personalReport.foundInPEPList) {
      return name + ' was found in PEP lists (API).';
    } else return '-';
  }

  getEntityAdverseCriminal() {
    const result = this.getAdverseCriminalText(this.entityReport, this.name);
    return result === '' ? '-' : result;
  }

  getIndividualAdverse() {
    const lists = [];
    if (this.personalReport.foundInFitnessProbityList) {
      lists.push('Fitness-Probity');
    }
    if (this.personalReport.foundInAdverseMediaList) {
      lists.push('Adverse Media');
    }
    if (lists.length === 0) {
      return '-';
    }
    const listsText = lists.join(', ');
    return `${this.name} was found in ${listsText} lists.`;
  }

  getDirectorAdverseCriminal() {
    const badDirectors = this.directorReports
      .filter(
        (report) =>
          report.foundInAdverseMediaList ||
          report.foundInWarningList ||
          report.foundInFitnessProbityList
      )
      .map((directorReport) => {
        const director = this.companyDirectors.find(
          (dir) => dir.id === directorReport.companyDirectorId
        );
        const name = director?.isLegalPerson
          ? director.legalPersonName
          : director?.firstName + ' ' + director?.lastName;
        return this.getAdverseCriminalText(directorReport, name);
      });
    return this.filterResult(badDirectors);
  }

  getAdverseCriminalText(report: any, name: string) {
    const lists = [];
    if (report.foundInWarningList) {
      lists.push('Warning');
    }
    if (report.foundInFitnessProbityList) {
      lists.push('Fitness-Probity');
    }
    if (report.foundInAdverseMediaList) {
      lists.push('Adverse Media');
    }
    if (lists.length === 0) {
      return '';
    }
    const listsText = lists.join(', ');
    return `${name} was found in ${listsText} lists.`;
  }

  getGroupMemberAML() {
    const badGroupMembers = this.groupMemberReports
      .filter(
        (report) =>
          report.foundInSanctionList ||
          report.foundInAdverseMediaList ||
          report.foundInWarningList ||
          report.foundInFitnessProbityList
      )
      .map((groupMemberReport) => {
        const groupMember = this.companyGroupMembers.find(
          (dir) => dir.id === groupMemberReport.companyGroupMemberId
        );
        const name = groupMember!.name;
        return this.getSanctionAdverseCriminalText(groupMemberReport, name);
      });
    return this.filterResult(badGroupMembers);
  }

  getSanctionAdverseCriminalText(report: any, name: string) {
    const lists = [];
    if (report.foundInSanctionList) {
      lists.push('Sanction');
    }
    if (report.foundInWarningList) {
      lists.push('Warning');
    }
    if (report.foundInFitnessProbityList) {
      lists.push('Fitness-Probity');
    }
    if (report.foundInAdverseMediaList) {
      lists.push('Adverse Media');
    }
    if (lists.length === 0) {
      return '';
    }
    const listsText = lists.join(', ');
    return `${name} was found in ${listsText} lists.`;
  }

  filterResult(result: any) {
    return result.length > 0 ? result.join('\n') : '-';
  }

  getGroupMemberCountries() {
    const highRiskCountries: string[] = this.groupMemberReports.map(
      (groupMemberReport) => {
        return getCountryById(
          this.countries,
          groupMemberReport.highRiskCountry
        );
      }
    );
    return this.filterCountries(highRiskCountries);
  }

  getCounterpartyCountries() {
    const highRiskCountries: string[] = this.counterpartyRiskReport.map(
      (counterpartyReport) => {
        return getCountryById(
          this.countries,
          counterpartyReport.highRiskCountry
        );
      }
    );
    return this.filterCountries(highRiskCountries);
  }

  getBankAccountCountries() {
    const highRiskCountries: string[] = this.bankAccountRiskReport.map(
      (accountReport) => {
        return getCountryById(this.countries, accountReport.highRiskCountry);
      }
    );
    return this.filterCountries(highRiskCountries);
  }

  getDirectorResidency() {
    const highRiskCountries: string[] = this.directorReports.map(
      (directorReport) => {
        return getCountryById(this.countries, directorReport.nonEEACountry);
      }
    );
    return this.filterCountries(highRiskCountries);
  }

  filterCountries(countries: string[]) {
    const highRiskCountries = countries.filter((country) => country !== '');
    return highRiskCountries.length > 0 ? highRiskCountries.join(', ') : '-';
  }

  get personalReportsControl(): FormArray {
    return this.form.get('personalReportsForm') as FormArray;
  }
  get entityReportsControl(): FormArray {
    return this.form.get('entityReportsForm') as FormArray;
  }
  get directorReportsControl(): FormArray {
    return this.form.get('directorReportsForm') as FormArray;
  }
  get groupMemberReportsControl(): FormArray {
    return this.form.get('groupMemberReportsForm') as FormArray;
  }
  get closeLinkReportsControl(): FormArray {
    return this.form.get('closeLinkReportsForm') as FormArray;
  }
  get adjustedRiskLevelControl(): AbstractControl | null {
    return this.isCorporate
      ? (this.entityReportsControl
          .at(0)
          .get('adjustedRiskLevel') as FormControl)
      : (this.personalReportsControl
          .at(0)
          .get('adjustedRiskLevel') as FormControl);
  }
  get adjustmentReasonControl(): AbstractControl | null {
    return this.isCorporate
      ? this.entityReportsControl.at(0).get('adjustmentReason')
      : this.personalReportsControl.at(0).get('adjustmentReason');
  }

  amlListMainReportControl(controlName: string): boolean {
    return this.isCorporate
      ? (this.entityReportsControl.at(0).get(controlName) as FormControl)?.value
      : (this.personalReportsControl.at(0).get(controlName) as FormControl)
          ?.value;
  }
  amlListDirectorReportsControl(index: number, controlName: string): boolean {
    return (this.directorReportsControl.at(index) as FormGroup).get(controlName)
      ?.value;
  }
  amlListGroupMemberReportsControl(
    index: number,
    controlName: string
  ): boolean {
    return (this.groupMemberReportsControl.at(index) as FormGroup).get(
      controlName
    )?.value;
  }
  amlListCloseLinkReportsControl(index: number, controlName: string): boolean {
    return (this.closeLinkReportsControl.at(index) as FormGroup).get(
      controlName
    )?.value;
  }
}

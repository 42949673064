import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { RiskAssessmentService } from '../risk-assessment.service';
import { getRelevanceDescription } from 'src/app/shared/helpers/various-helpers.helper';

export interface ShowAmlHitDialogData {
  amlHitId: number;
  reportId: number;
  userId: number;
}

@Component({
  selector: 'app-show-aml-hit-dialog',
  templateUrl: './show-aml-hit-dialog.component.html',
  styleUrls: ['./show-aml-hit-dialog.component.scss'],
})
export class ShowAmlHitDialogComponent implements OnInit {
  isLoadingAmlHit: boolean = false;

  amlHitId!: number;
  userId!: number;

  amlHit: any;

  getRelevanceDescription = getRelevanceDescription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ShowAmlHitDialogData,
    private riskAssessmentService: RiskAssessmentService,
    private errorService: ErrorService
  ) {}

  async ngOnInit(): Promise<void> {
    this.userId = this.data.userId;
    this.amlHitId = this.data.amlHitId;
    await this.getAmlHit();
  }

  async getAmlHit() {
    this.isLoadingAmlHit = true;
    (
      await this.riskAssessmentService.getAmlHit(this.amlHitId, this.userId)
    ).subscribe(
      (amlHit) => {
        this.amlHit = amlHit;
        this.isLoadingAmlHit = false;
      },
      (error) => {
        this.isLoadingAmlHit = false;
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-coming-soon-header',
  templateUrl: './coming-soon-header.component.html',
  styleUrls: ['./coming-soon-header.component.scss'],
})
export class ComingSoonHeaderComponent {
  @Input() svgIconName!: string;
  @Input() name?: string;

  constructor() {}
}

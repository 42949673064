<div mat-dialog-title fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <div fxLayout="row" fxLayoutAlign="start center">
    <h1>Legal Entities</h1>
    <button
      *ngIf="isFirstTimeEDD"
      mat-icon-button
      color="accent"
      class="g-small"
      (click)="addCompanyCloseLink()"
    >
      <mat-icon svgIcon="add"></mat-icon>
    </button>
  </div>
  <!-- Set to red when first time in EDD and said that has close links but close links not entered -->
  <div class="desc" [class.warning]="!closeLinksFilledInEDD()">
    Please add a new close link or select an existing one from the list below.
    <br />
    Please specify your status regarding the added or selected close link.
  </div>

  <div fxLayout="column" [formGroup]="form" fxLayoutGap="20px">
    <ng-container formArrayName="directorCloseLinksForm">
      <ng-container
        *ngFor="
          let element of directorCloseLinksControl.controls;
          let index = index
        "
        [formGroupName]="index"
      >
        <div>
          <div fxLayout="row" fxLayoutAlign="start start">
            <h2>{{ index + 1 | ordinalNumber }} Legal Entity</h2>
            <div fxLayout="row" fxLayoutAlign="start center">
              <button
                disableRipple
                mat-icon-button
                class="edit-icon"
                (click)="updateCompanyCloseLink(index)"
              >
                <mat-icon>edit</mat-icon>
              </button>
              <button
                *ngIf="
                  isReviewed &&
                  !isFirstTimeEDD &&
                  isInvalidCloseLink(element.value)
                "
                disableRipple
                mat-icon-button
                class="edit-icon"
                (click)="updateCompanyCloseLink(index)"
                [matTooltip]="tooltips.editCloseLinkTooltip"
              >
                <mat-icon class="warning">warning_amber</mat-icon>
              </button>
              <button
                *ngIf="isFirstTimeEDD && !isNotOnboarding"
                mat-icon-button
                (click)="removeCompanyCloseLink(index)"
                [disabled]="isRemoving"
                class="remove-icon"
              >
                <mat-icon svgIcon="cancel"></mat-icon>
              </button>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="start start">
            <div class="close-link">
              Name: {{ element.value.name }} <br />
              Registration number:
              {{ element.value.registrationNumber }} <br />
              Date of Incorporation:
              {{ convertDate(element.value.incorporationDate) }}
              <br />
              Address (Street, building number, place): <br />
              {{ element.value.address?.streetAddress }} <br />
              Address 2nd line (optional): <br />
              {{ element.value.address?.additionalStreetAddress || '-' }}
              <br />
              Zip/Postal code: {{ element.value.address?.postCode }}
              <br />
              City: {{ element.value.address?.city }} <br />
              Country:
              {{ getCountryById(countries, element.value.address?.countryId) }}
            </div>

            <div
              fxLayout="column"
              [class.roles]="isReviewed"
              fxLayoutGap="20px"
            >
              <!-- Show the following status when isReviewed and comment does not exist -->
              <ng-container
                *ngIf="
                  isReviewed &&
                  !isFirstTimeEDD &&
                  !getField(element.value.closeLinkId)?.comment
                "
              >
                <br />
                <div fxLayout="row" class="submitted">
                  <div class="status-header">Director</div>
                  <div class="value">
                    {{ getStringValue(element.value.isDirector) }}
                  </div>
                </div>
                <div fxLayout="row" class="submitted">
                  <div class="status-header">Shareholder</div>
                  <div class="value">
                    {{ getStringValue(element.value.isShareholder) }}
                  </div>
                </div>
                <div fxLayout="row" class="submitted">
                  <div class="status-header">
                    Percentage of shares owned by shareholder
                  </div>
                  <div class="value">
                    {{
                      element.value.isShareholder
                        ? element.value.percentageOwnedByShareholder + '%'
                        : '-'
                    }}
                  </div>
                </div>
                <div fxLayout="row" class="submitted">
                  <div class="status-header">UBO</div>
                  <div class="value">
                    {{ getStringValue(element.value.isUBO) }}
                  </div>
                </div>
                <div fxLayout="row" class="submitted">
                  <div class="status-header">
                    Percentage of shares owned by UBO
                  </div>
                  <div class="value">
                    {{
                      element.value.isUBO
                        ? element.value.percentageOwnedByUBO + '%'
                        : '-'
                    }}
                  </div>
                </div>
              </ng-container>

              <!-- Show the following status when isReviewed and comment exists -->
              <app-onboarding-form-item-new
                label=""
                [isReviewed]="isReviewed && !isFirstTimeEDD"
                [value]=""
                [field]="getField(element.value.closeLinkId)"
              >
                <div fxLayout="column" fxLayoutGap="20px">
                  <mat-checkbox
                    name="isDirector"
                    formControlName="isDirector"
                    color="primary"
                  >
                    Director
                  </mat-checkbox>
                  <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-checkbox
                      name="isShareholder"
                      formControlName="isShareholder"
                      color="primary"
                    >
                      Shareholder
                    </mat-checkbox>
                    <div
                      fxLayout="row"
                      class="field shares"
                      *ngIf="getIsShareholderControl(index)?.value"
                    >
                      <label
                        style="margin-left: 13px"
                        for="percentageOwnedByShareholder"
                      >
                        % of shares owned</label
                      >
                      <mat-form-field appearance="outline" class="small">
                        <input
                          placeholder="0.00"
                          type="number"
                          min="0.01"
                          max="100"
                          step=".01"
                          matInput
                          name="percentageOwnedByShareholder"
                          formControlName="percentageOwnedByShareholder"
                        />
                        <div matTextSuffix>%</div>
                        <mat-error
                          data-testid="validation-message-box"
                          *ngIf="
                            getPercentageOwnedByShareholderControl(
                              index
                            )?.hasError('required')
                          "
                        >
                          Required</mat-error
                        >
                        <mat-error
                          data-testid="validation-message-box"
                          *ngIf="
                            getPercentageOwnedByShareholderControl(
                              index
                            )?.hasError('pattern')
                          "
                        >
                          Use format: 0.00</mat-error
                        >
                        <mat-error
                          data-testid="validation-message-box"
                          *ngIf="
                            getPercentageOwnedByShareholderControl(
                              index
                            )?.hasError('min')
                          "
                        >
                          Value must be greater than or equal to 0.01</mat-error
                        >
                        <mat-error
                          data-testid="validation-message-box"
                          *ngIf="
                            getPercentageOwnedByShareholderControl(
                              index
                            )?.hasError('max')
                          "
                        >
                          Value must be less than or equal to 100</mat-error
                        >
                      </mat-form-field>
                    </div>
                  </div>

                  <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-checkbox
                      name="isUBO"
                      formControlName="isUBO"
                      color="primary"
                    >
                      UBO
                    </mat-checkbox>
                    <div
                      fxLayout="row"
                      class="field shares"
                      *ngIf="getIsUBOControl(index)?.value"
                    >
                      <label
                        style="margin-left: 56px"
                        for="percentageOwnedByUBO"
                        >% of shares owned</label
                      >
                      <mat-form-field appearance="outline" class="small">
                        <input
                          placeholder="0.00"
                          type="number"
                          min="0.01"
                          max="100"
                          step=".01"
                          matInput
                          name="percentageOwnedByUBO"
                          formControlName="percentageOwnedByUBO"
                        />
                        <div matTextSuffix>%</div>
                        <mat-error
                          data-testid="validation-message-box"
                          *ngIf="
                            getPercentageOwnedByUBOControl(index)?.hasError(
                              'required'
                            )
                          "
                        >
                          Required</mat-error
                        >
                        <mat-error
                          data-testid="validation-message-box"
                          *ngIf="
                            getPercentageOwnedByUBOControl(index)?.hasError(
                              'pattern'
                            )
                          "
                          >Use format: 0.00</mat-error
                        >
                        <mat-error
                          data-testid="validation-message-box"
                          *ngIf="
                            getPercentageOwnedByUBOControl(index)?.hasError(
                              'min'
                            )
                          "
                        >
                          Value must be greater than or equal to 0.01</mat-error
                        >
                        <mat-error
                          data-testid="validation-message-box"
                          *ngIf="
                            getPercentageOwnedByUBOControl(index)?.hasError(
                              'max'
                            )
                          "
                        >
                          Value must be less than or equal to 100</mat-error
                        >
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </app-onboarding-form-item-new>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
      </ng-container>
    </ng-container>

    <div fxLayoutAlign="end center" class="button-container">
      <button
        mat-flat-button
        [disabled]="isAdding || form.invalid || form.pristine"
        [appButtonLoader]="isAdding"
        (click)="saveDirectorCloseLinks()"
        color="accent"
        type="submit"
      >
        {{ isNotOnboarding ? 'Confirm' : 'Save' }}
      </button>
    </div>
  </div>
</mat-dialog-content>

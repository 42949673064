<div>
  <div
    fxLayout
    fxLayoutAlign="space-between center"
    [ngClass]="
      accountTable.accountName.includes('Wallet')
        ? 'header-container-wallet'
        : 'header-container'
    "
  >
    <div fxLayout fxLayoutAlign="start center">
      <button
        mat-stroked-button
        color="accent"
        (click)="goBack()"
        class="go-back-button"
        fxLayoutAlign="center center"
      >
        <mat-icon svgIcon="arrow" class="arrow-icon"></mat-icon> Back
      </button>
      <img
        *ngIf="accountTable.accountIconName === 'master-card'"
        src="assets/img/master-card.svg"
        alt="master-card"
      />
      <div class="account-name">
        {{ accountTable.accountName }}
      </div>
    </div>
    <div fxLayout fxLayoutAlign="end center">
      <div class="code">
        {{ accountTable.accountDetails.code }}
        <br />
        {{ accountTable.accountDetails.iban }}
      </div>
      <div
        class="account-status"
        [ngClass]="
          isAccountOpen(accountTable.accountDetails.status) ? 'open' : 'other'
        "
      >
        {{ getAccountStatusName(accountTable.accountDetails.status) }}
      </div>
    </div>
  </div>

  <table
    mat-table
    [dataSource]="dataSource"
    [fixedLayout]="true"
    matSort
    matSortStart="desc"
    matSortDisableClear
    multiTemplateDataRows
  >
    <!-- From Amount Column -->
    <ng-container matColumnDef="fromAmount">
      <th mat-header-cell *matHeaderCellDef>
        <div mat-sort-header="fromAmount">Amount</div>
      </th>
      <td mat-cell *matCellDef="let row" class="amount">
        <div
          [ngClass]="
            row?.typeName === TransactionTypeName.CREDIT ? 'credit' : 'debit'
          "
        >
          {{ row?.fromAmount | statementAmount: row?.typeName }}
          {{ row?.fromCCY }}
        </div>
      </td>
    </ng-container>

    <!-- To Amount Column -->
    <ng-container matColumnDef="toAmount">
      <th mat-header-cell *matHeaderCellDef>
        <div mat-sort-header="toAmount">Transaction Amount</div>
      </th>
      <td mat-cell *matCellDef="let row">
        <div>
          {{ row?.toAmount | statementAmount: row?.typeName }} {{ row?.toCCY }}
        </div>
      </td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>
        <div>Type</div>
      </th>
      <td mat-cell *matCellDef="let row">
        <div>
          {{ row?.typeName }}
        </div>
      </td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="transactionCodeName">
      <th mat-header-cell *matHeaderCellDef>
        <div mat-sort-header="transactionCodeName">Description</div>
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row?.transactionCodeName }}
      </td>
    </ng-container>

    <!-- Current Balance Column -->
    <ng-container matColumnDef="currentBalance">
      <th mat-header-cell *matHeaderCellDef>
        <div mat-sort-header="currentBalance">Balance</div>
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row?.currentBalance | amount }} {{ row?.currentBalanceCcy }}
      </td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef width="10%">
        <div mat-sort-header="createdAt">Date</div>
      </th>
      <td mat-cell *matCellDef="let row">
        <div>{{ row.createdAt | date: 'shortDate' }}</div>
      </td>
    </ng-container>

    <!-- Reference Column -->
    <ng-container matColumnDef="transactionRefId">
      <th mat-header-cell *matHeaderCellDef>
        <div mat-sort-header="transactionRefId">Reference No.</div>
      </th>
      <td mat-cell *matCellDef="let row">
        <div>{{ row?.transactionRefId }}</div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr *matNoDataRow>
      <td [attr.colspan]="9" *ngIf="dataSource.isLoadingObs | async">
        <div class="no-data-row" fxLayoutAlign="center center">
          <app-big-loader-not-centered></app-big-loader-not-centered>
        </div>
      </td>
      <td
        [attr.colspan]="9"
        *ngIf="
          (dataSource.isLoadingObs | async) !== true &&
          (dataSource.lengthSubjectObs | async) === 0
        "
      >
        <div class="no-data-row" fxLayoutAlign="center center">No results</div>
      </td>
    </tr>
  </table>
  <mat-paginator
    class="mat-paginator-container"
    [length]="dataSource.getLength() | async"
    [pageSize]="20"
    [pageSizeOptions]="[20, 50, 100]"
  ></mat-paginator>
</div>

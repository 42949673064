<button
  mat-flat-button
  class="g-medium"
  routerLink="users"
  data-testid="admin-users-tab"
  routerLinkActive="active"
>
  Users
</button>
<button
  mat-flat-button
  data-testid="admin-notifications-tab"
  class="g-medium"
  routerLink="notifications"
  routerLinkActive="active"
>
  Notifications
</button>
<button
  mat-flat-button
  data-testid="admin-reports-tab"
  class="g-medium"
  routerLink="reports"
  routerLinkActive="active"
>
  Reports
</button>
<!-- <button
  mat-flat-button
  data-testid="admin-actions-tab"
  class="g-medium"
  routerLink="actions"
  routerLinkActive="active"
>
  Actions
</button> -->
<router-outlet></router-outlet>

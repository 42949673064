<mat-card [ngSwitch]="isLoadingBalances">
  <app-big-loader *ngSwitchCase="true"></app-big-loader>
  <ng-container *ngSwitchDefault [formGroup]="transferForm">
    <div
      fxLayout
      fxLayoutAlign="start center"
      fxLayoutGap="20px"
      class="header-container"
    >
      <mat-icon>currency_exchange</mat-icon>
      <h2>Currency exchange</h2>
      <mat-icon class="material-icons-outlined info" (click)="showInfo()"
        >info</mat-icon
      >
    </div>
    <mat-divider></mat-divider>
    <strong>I want to buy</strong>

    <!-- TO CURRENCY -->
    <ng-container
      *ngTemplateOutlet="currencyTemplate; context: { control: toCurrControl }"
    ></ng-container>

    <strong>for</strong>
    <div
      fxLayout
      fxLayoutAlign="start end"
      fxLayoutGap="20px"
      fxLayoutGap.xs="2px"
    >
      <div fxLayout="column">
        <label>Amount (minimum 2 EUR or equivalent)</label>
        <mat-form-field class="small-resolution-field">
          <input matInput formControlName="amount" type="number" min="0" />
          <mat-error *ngIf="amountControl.hasError('required')"
            >Required</mat-error
          >
          <mat-error *ngIf="amountControl.hasError('pattern')"
            >Use format: 0.00</mat-error
          >
          <mat-error *ngIf="amountControl.hasError('min')"
            >Amount cannot be less than 2 EUR equivalent</mat-error
          >
        </mat-form-field>
      </div>

      <!-- FROM CURR -->
      <ng-container
        class="small-resolution-select"
        *ngTemplateOutlet="
          currencyTemplate;
          context: { control: fromCurrControl }
        "
      ></ng-container>
    </div>
    <br />
    <div
      fxLayout
      fxLayoutAlign="start center"
      fxLayoutGap="15px"
      fxLayout.xs="column"
      fxLayoutAlign.xs="start"
    >
      <div>
        <button
          mat-flat-button
          color="accent"
          [disabled]="!transferForm.valid"
          (click)="showRates()"
        >
          Show rates
        </button>
      </div>
      <div *ngIf="transferForm.valid" fxLayout>
        You will have 30 seconds to confirm the purchase.
      </div>
    </div>
  </ng-container>
</mat-card>

<!-- TEMPLATE -->
<ng-template #currencyTemplate let-control="control">
  <mat-form-field [class.small-resolution-select]="control === fromCurrControl">
    <mat-select [formControl]="control">
      <mat-select-trigger *ngIf="control.value">
        <div class="currency-row">
          <img [src]="getFlagPath(control.value)" [alt]="control.value.short" />
          <div>{{ control.value.name }}</div>
        </div>
      </mat-select-trigger>
      <mat-option *ngFor="let currency of currencies" [value]="currency">
        <div class="currency-row option">
          <img [src]="getFlagPath(currency)" [alt]="currency.short" />
          <div>{{ currency.name }}</div>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

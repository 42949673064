<mat-tab-group mat-stretch-tabs animationDuration="0ms" [disableRipple]="true">
  <mat-tab>
    <ng-template mat-tab-label>
      <div
        data-testid="individual-tab"
        class="tab"
        fxLayoutAlign="start center"
        fxLayoutGap="12px"
      >
        <!-- inline svg used for changing color in css -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13.227"
          height="18"
          viewBox="0 0 13.227 18"
        >
          <path
            d="M12.614 18H0c.489-4.069 2.4-7.5 6.614-7.5s6.125 3.431 6.613 7.5zm-1.068-1.2c-.565-2.706-1.975-4.8-4.932-4.8s-4.367 2.095-4.932 4.8zM2.114 4.5a4.5 4.5 0 1 1 4.5 4.5 4.5 4.5 0 0 1-4.5-4.5zm1.5 0a3 3 0 1 0 3-3 3 3 0 0 0-3 3z"
          />
        </svg>
        <span>Personal</span>
      </div>
    </ng-template>

    <div
      class="tab-content"
      fxLayout="column"
      fxLayoutAlign="start center"
      fxLayoutGap="16px"
    >
      <app-account-header [program]="programs.JADE"></app-account-header>
      <button
        mat-stroked-button
        color="accent"
        class="g-small"
        (click)="showDetails(programs.JADE)"
        data-testid="show-jade-details-button"
      >
        Show details
      </button>
      <button
        mat-flat-button
        color="accent"
        class="g-small"
        (click)="chooseProgram(programs.JADE)"
        data-testid="choose-jade-program-button"
      >
        Choose Jade Account
      </button>
      <mat-divider fxFill></mat-divider>
      <app-account-header [program]="programs.EMERALD"></app-account-header>
      <button
        mat-stroked-button
        color="accent"
        class="g-small"
        (click)="showDetails(programs.EMERALD)"
        data-testid="show-emerald-details-button"
      >
        Show details
      </button>
      <button
        mat-flat-button
        color="accent"
        class="g-small"
        (click)="chooseProgram(programs.EMERALD)"
        data-testid="choose-emerald-program-button"
      >
        Choose Emerald Account
      </button>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <div
        data-testid="business-tab"
        class="tab"
        fxLayoutAlign="start center"
        fxLayoutGap="12px"
      >
        <!-- inline svg used for changing color in svg -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            fill-rule="evenodd"
            d="M400.5 162.792h-15a1.5 1.5 0 0 1-1.5-1.5v-12a1.5 1.5 0 0 1 1.5-1.5h3.75v-1.5a1.5 1.5 0 0 1 1.5-1.5h4.5a1.5 1.5 0 0 1 1.5 1.5v1.5h3.75a1.5 1.5 0 0 1 1.5 1.5v12a1.5 1.5 0 0 1-1.5 1.5zm-5.25-16.5h-4.5v1.5h4.5zm5.25 9.75h-3.75a1.5 1.5 0 0 1-1.5 1.5h-4.5a1.5 1.5 0 0 1-1.5-1.5h-3.75v-1.5h3.75a1.5 1.5 0 0 1 1.5-1.5h4.5a1.5 1.5 0 0 1 1.5 1.5h3.75v-5.25h-15v12h15zm-5.25-.75a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 .75-.75z"
            transform="translate(-384 -144.792)"
          />
        </svg>
        <span>Business</span>
      </div>
    </ng-template>

    <div
      class="tab-content"
      fxLayout="column"
      fxLayoutAlign="start center"
      fxLayoutGap="16px"
    >
      <app-account-header [program]="programs.ONYX"></app-account-header>
      <button
        mat-stroked-button
        color="accent"
        class="g-small"
        (click)="showDetails(programs.ONYX)"
        data-testid="show-onyx-details-button"
      >
        Show details
      </button>
      <button
        mat-flat-button
        color="accent"
        class="g-small"
        (click)="chooseProgram(programs.ONYX)"
        data-testid="choose-onyx-program-button"
      >
        Choose Onyx Account
      </button>
      <mat-divider fxFill></mat-divider>
      <app-account-header [program]="programs.RUBY"></app-account-header>
      <button
        mat-stroked-button
        color="accent"
        class="g-small"
        (click)="showDetails(programs.RUBY)"
        data-testid="show-ruby-details-button"
      >
        Show details
      </button>
      <button
        mat-flat-button
        color="accent"
        class="g-small"
        (click)="chooseProgram(programs.RUBY)"
        data-testid="choose-ruby-program-button"
      >
        Choose Ruby Account
      </button>
    </div>
  </mat-tab>
</mat-tab-group>

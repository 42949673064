<div mat-dialog-title fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <div fxLayout="column">
    <h1>AML Hit Details | Id: {{ amlHitId }}</h1>
  </div>
  <mat-divider></mat-divider>
  <div class="spinner-container" *ngIf="isLoadingAmlHit">
    <app-big-loader></app-big-loader>
  </div>
  <div class="table-container" *ngIf="amlHit">
    <table>
      <tr>
        <th class="factors"></th>
        <th></th>
      </tr>

      <tr>
        <td class="field">Full Name</td>
        <td>{{ amlHit.name }}</td>
      </tr>

      <tr>
        <td class="field">Entity Type</td>
        <td>{{ amlHit.entity_type }}</td>
      </tr>
      <tr>
        <td class="field">Score</td>
        <td>{{ amlHit.score }}/2</td>
      </tr>

      <tr>
        <td class="field">Relevance</td>
        <td>
          <div *ngFor="let entry of amlHit.match_types">
            {{ entry | replaceUnderscore | titlecase }}
            {{ getRelevanceDescription(entry) }}
          </div>
        </td>
      </tr>

      <tr>
        <td class="field">Matched</td>
        <td>
          <div *ngFor="let entry of amlHit.types">
            {{ entry | titlecase }}
          </div>
        </td>
      </tr>

      <tr>
        <td colspan="2"><mat-divider></mat-divider></td>
      </tr>

      <tr>
        <td class="field">Details</td>
        <td>
          <div>
            <app-json-viewer [jsonObject]="amlHit"></app-json-viewer>
          </div>
        </td>
      </tr>

      <tr>
        <td colspan="2"><mat-divider></mat-divider></td>
      </tr>
    </table>
  </div>
</mat-dialog-content>

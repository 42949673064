<div [formGroup]="accountForm" class="main">
  <ng-container>
    <div fxLayoutAlign="start center" class="header">
      <h2>{{ index + 1 | ordinalNumber }} Bank/Payment Institution</h2>
      <ng-container
        *ngTemplateOutlet="removeButton; context: { $implicit: account }"
      ></ng-container>
    </div>

    <!-- ACCOUNT NAME -->
    <app-onboarding-form-item-new
      label="Bank/Payment Institution Name"
      [isReviewed]="isReviewed"
      [value]="account.name"
      [field]="
        fieldsMap.get(OnboardingFieldId.BUSINESS_BANK_PAYMENT_INSTITUTION_NAME)
      "
    >
      <div fxLayout="column" class="field">
        <label for="name">Bank/Payment Institution Name</label>
        <mat-form-field appearance="outline">
          <input matInput name="name" formControlName="name" />
          <mat-error
            data-testid="validation-message-box"
            *ngIf="nameControl?.hasError('required')"
            >Required</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="nameControl?.hasError('pattern')"
            >Invalid character</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="nameControl?.hasError('maxlength')"
            >Too many characters</mat-error
          >
        </mat-form-field>
      </div>
    </app-onboarding-form-item-new>

    <!-- ACCOUNT COUNTRY -->
    <app-onboarding-form-item-new
      label="Bank/Payment Institution Country"
      [isReviewed]="isReviewed"
      [value]="getCountryById(countries, account.country)"
      [field]="
        fieldsMap.get(
          OnboardingFieldId.BUSINESS_BANK_PAYMENT_INSTITUTION_COUNTRY
        )
      "
    >
      <div fxLayout="column" class="field">
        <label for="country">Bank/Payment Institution Country</label>
        <mat-form-field appearance="outline">
          <mat-select
            placeholder="Please select"
            name="country"
            formControlName="country"
          >
            <mat-option *ngFor="let country of countries" [value]="country.id"
              >{{ country.name | titlecase }}
            </mat-option>
          </mat-select>
          <mat-error
            data-testid="validation-message-box"
            *ngIf="countryControl?.hasError('required')"
            >Required</mat-error
          >
        </mat-form-field>
      </div>
    </app-onboarding-form-item-new>
  </ng-container>
</div>

<ng-template #removeButton>
  <button
    *ngIf="!isReviewed"
    mat-icon-button
    (click)="removeAccount(account)"
    [disabled]="isRemoving"
    [appButtonLoader]="isRemoving"
  >
    <mat-icon svgIcon="cancel"></mat-icon>
  </button>
</ng-template>

import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Country } from 'src/app/shared/models/country.model';
import { IndividualUserProfileData } from '../../models/individual-user-profile-data.model';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { phoneValidator } from 'src/app/shared/phone.validator';
import { distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ChangeEmailDialogNewComponent } from '../change-email-dialog/change-email-dialog.component';
import { ChangePhoneNumberDialogNewComponent } from '../change-phone-number-dialog/change-phone-number-dialog.component';
import { ChangePasswordDialogNewComponent } from '../change-password-dialog/change-password-dialog.component';
import { UserProfileService } from '../../user-profile.service';
import { Subscription } from 'rxjs';
import {
  getCountryById,
  getMonthlyIncomeNew,
  getMonthlySpend,
  getNationalityById,
  getSizeOfWealth,
  setExtraQuestionsToRequired,
  NA,
  addressLineValidator,
  postCodeCityValidator,
} from 'src/app/shared/helpers/various-helpers.helper';
import { AuthService } from 'src/app/login/services/auth.service';
import { Program } from 'src/app/login/models/program.enum';
import { AccountInformationDialogNewComponent } from '../account-information-dialog/account-information-dialog.component';
import { ServerSentEventType } from '../../../shared/models/server-sent-event-type.enum';
import { UserEventsService } from '../../../shared/services/user-events.service';
import { OccupationProfession } from 'src/app/onboarding-new/models/occupation-profession.enum';
import { Occupation } from 'src/app/onboarding-new/models/occupation.enum';
import { CardPurpose } from 'src/app/onboarding-new/models/card-purpose.enum';
import { MonthlyIncomeNew } from 'src/app/onboarding-new/models/monthly-income.enum';
import { SharedService } from 'src/app/shared/services/shared.service';
import { MonthlySpend } from 'src/app/onboarding-new/models/monthly-spend.enum';
import { SizeOfWealth } from 'src/app/onboarding-new/models/size-of-wealth.enum';
import { SourceOfIncome } from 'src/app/onboarding-new/models/source-of-income.enum';
import { SourceOfWealth } from 'src/app/onboarding-new/models/source-of-wealth.enum';
import { PersonalForm } from 'src/app/onboarding-new/models/personal-form.model';
import { SaveRegisteredUser } from 'src/app/admin/users/registered-users/models/save-registered-user.model';
import { taxOrVatExists } from 'src/app/onboarding-new/components/onboarding-individual-form/onboarding-individual-form.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-profile-individual-new',
  templateUrl: './user-profile-individual.component.html',
  styleUrls: ['./user-profile-individual.component.scss'],
})
export class UserProfileIndividualNewComponent implements OnInit, OnDestroy {
  @Input() requestMoreData!: boolean;
  @Input() individualUserProfileData!: IndividualUserProfileData;
  @Input() countries: Country[] = [];
  @Output() readonly dataChanged = new EventEmitter<void>();

  getCountryById = getCountryById;
  getNationalityById = getNationalityById;
  environment = environment;

  program?: Program;
  programs = Program;

  marketingForm!: FormGroup; // marketing consent
  personalForm!: FormGroup; // personal data
  isSavingPersonalForm: boolean = false;

  avatarUrl?: string | null = null;
  avatarErrorMessage = '';
  isAvatarUploading = false;
  isLoading?: boolean = false;
  userId!: number;

  private eventSubscription?: Subscription;
  private additionalPhoneNumberSubscription?: Subscription;
  private isMarketingConsentSubscription?: Subscription;
  private authenticatedUserSubscription?: Subscription;

  acceptedCountries: Country[] = [];
  showExtraQuestions: boolean = false;

  occupationProfessions = Object.entries(OccupationProfession);
  occupations = Object.entries(Occupation);
  cardPurposes = Object.entries(CardPurpose);
  monthlyIncomes = Object.entries(MonthlyIncomeNew);
  monthlySpends = Object.entries(MonthlySpend);
  getMonthlyIncome = getMonthlyIncomeNew;
  getMonthlySpend = getMonthlySpend;
  getSizeOfWealth = getSizeOfWealth;
  sizesOfWealth = Object.entries(SizeOfWealth);
  sourcesOfWealth = Object.entries(SourceOfWealth);
  sourcesOfIncome = Object.entries(SourceOfIncome);
  NA = NA;

  constructor(
    private fb: FormBuilder,
    private userProfileService: UserProfileService,
    private errorService: ErrorService,
    private dialog: MatDialog,
    private authService: AuthService,
    private userEventsService: UserEventsService,
    private ngZone: NgZone,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.marketingForm = this.fb.group({
      isMarketingConsent: this.individualUserProfileData.isMarketingConsent,
    });

    this.personalForm = this.fb.group({
      streetAddress: [
        this.individualUserProfileData?.address?.streetAddress,
        [Validators.required, ...addressLineValidator],
      ],
      additionalStreetAddress: [
        this.individualUserProfileData?.address?.additionalStreetAddress,
        [...addressLineValidator],
      ],
      postCode: [
        this.individualUserProfileData?.address?.postCode,
        [Validators.required, ...postCodeCityValidator],
      ],
      city: [
        this.individualUserProfileData?.address?.city,
        [Validators.required, ...postCodeCityValidator],
      ],
      countryId: [
        this.individualUserProfileData?.address?.countryId,
        Validators.required,
      ],
      isTempAddress: [this.individualUserProfileData?.isTempAddress],
      additionalPhoneNumber: [
        this.individualUserProfileData.additionalPhoneNumber || '',
        {
          validators: [phoneValidator()],
        },
      ],
      additionalEmail: [
        this.individualUserProfileData.additionalEmail || '',
        {
          validators: [
            Validators.email,
            Validators.pattern('^[a-zA-Z0-9@._-]{1,255}$'),
          ],
        },
      ],
      dataOccupationProfession: [
        this.individualUserProfileData?.dataOccupationProfession,
        Validators.required,
      ],
      dataOccupation: [
        this.individualUserProfileData?.dataOccupation,
        Validators.required,
      ],
      dataCardPurpose: [
        this.individualUserProfileData?.dataCardPurpose
          ? this.individualUserProfileData?.dataCardPurpose.split(', ')
          : '',
        Validators.required,
      ],
      dataMonthlyIncome: [
        this.individualUserProfileData?.dataMonthlyIncome,
        Validators.required,
      ],
      dataMonthlySpend: [
        this.individualUserProfileData?.dataMonthlySpend,
        Validators.required,
      ],
      isPEP: [this.individualUserProfileData?.isPEP, Validators.required],
      dataSourceOfWealth: [
        this.individualUserProfileData?.dataSourceOfWealth?.split(', '),
      ],
      dataSourceOfIncome: [
        this.individualUserProfileData?.dataSourceOfIncome?.split(', '),
      ],
      dataSizeOfWealth: [this.individualUserProfileData?.dataSizeOfWealth],
      countryOfSourceOfWealth: [
        this.individualUserProfileData?.countryOfSourceOfWealth,
      ],
      countryOfSourceOfIncome: [
        this.individualUserProfileData?.countryOfSourceOfIncome,
      ],
      dataTaxNumber: [
        this.individualUserProfileData?.dataTaxNumber,
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
          Validators.maxLength(50),
        ],
      ],
      dataTaxNumberNotAvailable: [''],
      dataVatNumber: [
        this.individualUserProfileData?.dataVatNumber,
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
          Validators.maxLength(50),
        ],
      ],
      dataVatNumberNotAvailable: [''],
      dataTaxAndVatCountry: [
        this.individualUserProfileData?.dataTaxAndVatCountry,
        taxOrVatExists(),
      ],
    });

    this.sharedService.getAllCountries().subscribe(
      (countries) => {
        this.acceptedCountries = countries;
      },
      () => {
        this.errorService.showErrorDialog();
      }
    );

    let firstAdditionalPhoneNumberChange = true;
    this.additionalPhoneNumberSubscription =
      this.additionalPhoneNumberControl.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((val) => {
          if (firstAdditionalPhoneNumberChange) {
            firstAdditionalPhoneNumberChange = false;
            // removes redundant prefix from phone number when set from backend
            this.additionalPhoneNumberControl.setValue(val, {
              emitEvent: false,
            });
          }
        });

    this.isMarketingConsentSubscription =
      this.isMarketingConsentControl.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((val) =>
          this.userProfileService
            .updateMarketingConsent({
              isMarketingConsent: val,
            })
            .subscribe(
              () => {},
              () => this.errorService.showErrorDialog()
            )
        );

    this.authenticatedUserSubscription = this.authService
      .getAuthenticatedUserObs()
      .subscribe((authenticatedUser) => {
        this.program = authenticatedUser?.program;
        this.avatarUrl = authenticatedUser?.avatarUrl;
        this.userId = authenticatedUser!.id;
      });

    // show extra questions (source of wealth/income etc) if: (Jade + submitted edd) or (Emerald) or (high risk)
    this.showExtraQuestions =
      (this.individualUserProfileData?.dtEddFormSubmitted &&
        this.program === Program.JADE) ||
      this.program === Program.EMERALD ||
      this.requestMoreData;
    if (this.showExtraQuestions) {
      setExtraQuestionsToRequired(this.personalForm);
    }

    // if tax number is N/A set the checkbox to true
    if (this.individualUserProfileData.dataTaxNumber === NA) {
      this.dataTaxNumberNotAvailableControl?.setValue(true);
      this.dataTaxNumberControl?.disable();
    } else {
      this.dataTaxNumberNotAvailableControl?.setValue(false);
      this.dataTaxNumberControl?.enable();
    }

    // if vat number is N/A set the checkbox to true
    if (this.individualUserProfileData.dataVatNumber === NA) {
      this.dataVatNumberNotAvailableControl?.setValue(true);
      this.dataVatNumberControl?.disable();
    } else {
      this.dataVatNumberNotAvailableControl?.setValue(false);
      this.dataVatNumberControl?.enable();
    }

    // if tax number is NA, disable the tax field and set the tax number to NA
    this.dataTaxNumberNotAvailableControl?.valueChanges.subscribe(
      (notAvailable) => {
        if (notAvailable) {
          this.dataTaxNumberControl?.disable();
          this.dataTaxNumberControl?.setValue(NA);
        } else {
          this.dataTaxNumberControl?.enable();
          this.dataTaxNumberControl?.setValue(null);
        }
        // run custom validator of country
        this.dataTaxAndVatCountryControl?.updateValueAndValidity();
      }
    );
    this.dataTaxNumberControl?.valueChanges.subscribe(() => {
      this.dataTaxAndVatCountryControl?.updateValueAndValidity();
    });

    // if vat number is NA, disable the vat field and set the vat number to NA
    this.dataVatNumberNotAvailableControl?.valueChanges.subscribe(
      (notAvailable) => {
        if (notAvailable) {
          this.dataVatNumberControl?.disable();
          this.dataVatNumberControl?.setValue(NA);
        } else {
          this.dataVatNumberControl?.enable();
          this.dataVatNumberControl?.setValue(null);
        }
        // run custom validator of country
        this.dataTaxAndVatCountryControl?.updateValueAndValidity();
      }
    );
    this.dataVatNumberControl?.valueChanges.subscribe(() => {
      this.dataTaxAndVatCountryControl?.updateValueAndValidity();
    });

    if (environment.DISABLE_PROFILE) {
      this.personalForm.disable({ emitEvent: false }); // don't emit event so it doesn't trigger any valueChanges e.g. taxNumber and enables its control
    }

    this.personalForm.markAllAsTouched(); // show user extra incomplete fields if they are high risk
  }

  async savePersonalForm(): Promise<void> {
    this.isSavingPersonalForm = true;
    const personalForm: PersonalForm = {
      address: {
        countryId: this.countryIdControl?.value,
        city: this.cityControl?.value,
        postCode: this.postCodeControl?.value,
        streetAddress: this.streetAddressControl?.value,
        additionalStreetAddress:
          this.additionalStreetAddressControl?.value || null,
      },
      isTempAddress: this.isTempAddressControl?.value,
      additionalPhoneNumber:
        this.additionalPhoneNumberControl?.value?.e164Number || '',
      additionalEmail: this.additionalEmailControl?.value || null,
      dataOccupationProfession: this.dataOccupationProfessionControl?.value,
      dataOccupation: this.dataOccupationControl?.value,
      dataCardPurpose: this.dataCardPurposeControl?.value.join(', '),
      dataMonthlyIncome: this.dataMonthlyIncomeControl?.value,
      dataMonthlySpend: this.dataMonthlySpendControl?.value,
      isPEP: this.isPEPControl?.value,
      dataSourceOfWealth: this.dataSourceOfWealthControl?.value
        ? this.dataSourceOfWealthControl?.value.join(', ')
        : null,
      dataSourceOfIncome: this.dataSourceOfIncomeControl?.value
        ? this.dataSourceOfIncomeControl?.value.join(', ')
        : null,
      dataSizeOfWealth: this.dataSizeOfWealthControl?.value,
      countryOfSourceOfWealth: this.countryOfSourceOfWealthControl?.value,
      countryOfSourceOfIncome: this.countryOfSourceOfIncomeControl?.value,
      dataTaxNumber: this.dataTaxNumberControl?.value,
      dataVatNumber: this.dataVatNumberControl?.value,
      dataTaxAndVatCountry: this.dataTaxAndVatCountryControl?.value,
    };
    const body: SaveRegisteredUser = {
      userId: this.userId,
      personalData: personalForm,
    };
    // save personal form
    (await this.userProfileService.updatePersonalForm(body)).subscribe(
      () => {
        this.isSavingPersonalForm = false;
        this.personalForm.markAsPristine();
        this.dataChanged.emit();
      },
      (error) => {
        this.isSavingPersonalForm = false;
        error.status === 403
          ? this.errorService.showErrorDialog(error.error.message)
          : this.errorService.showErrorDialog();
      }
    );
  }

  get isMarketingConsentControl(): AbstractControl {
    return this.marketingForm.get('isMarketingConsent')!;
  }
  get additionalPhoneNumberControl(): AbstractControl {
    return this.personalForm.get('additionalPhoneNumber')!;
  }
  get additionalEmailControl(): AbstractControl {
    return this.personalForm.get('additionalEmail')!;
  }
  get dataOccupationProfessionControl(): AbstractControl | null {
    return this.personalForm.get('dataOccupationProfession');
  }
  get dataOccupationControl(): AbstractControl | null {
    return this.personalForm.get('dataOccupation');
  }
  get dataCardPurposeControl(): AbstractControl | null {
    return this.personalForm.get('dataCardPurpose');
  }
  get dataMonthlyIncomeControl(): AbstractControl | null {
    return this.personalForm.get('dataMonthlyIncome');
  }
  get dataMonthlySpendControl(): AbstractControl | null {
    return this.personalForm.get('dataMonthlySpend');
  }
  get isPEPControl(): AbstractControl | null {
    return this.personalForm.get('isPEP');
  }
  get streetAddressControl(): AbstractControl | null | undefined {
    return this.personalForm.get('streetAddress');
  }
  get additionalStreetAddressControl(): AbstractControl | null | undefined {
    return this.personalForm.get('additionalStreetAddress');
  }
  get postCodeControl(): AbstractControl | null | undefined {
    return this.personalForm.get('postCode');
  }
  get cityControl(): AbstractControl | null | undefined {
    return this.personalForm.get('city');
  }
  get countryIdControl(): AbstractControl | null | undefined {
    return this.personalForm.get('countryId');
  }
  get isTempAddressControl(): AbstractControl | null | undefined {
    return this.personalForm.get('isTempAddress');
  }
  get dataSourceOfWealthControl(): AbstractControl | null {
    return this.personalForm.get('dataSourceOfWealth');
  }
  get dataSourceOfIncomeControl(): AbstractControl | null {
    return this.personalForm.get('dataSourceOfIncome');
  }
  get countryOfSourceOfWealthControl(): AbstractControl | null {
    return this.personalForm.get('countryOfSourceOfWealth');
  }
  get countryOfSourceOfIncomeControl(): AbstractControl | null {
    return this.personalForm.get('countryOfSourceOfIncome');
  }
  get dataSizeOfWealthControl(): AbstractControl | null {
    return this.personalForm.get('dataSizeOfWealth');
  }
  get dataTaxNumberControl(): AbstractControl | null {
    return this.personalForm.get('dataTaxNumber');
  }
  get dataTaxNumberNotAvailableControl(): AbstractControl | null {
    return this.personalForm.get('dataTaxNumberNotAvailable');
  }
  get dataVatNumberControl(): AbstractControl | null {
    return this.personalForm.get('dataVatNumber');
  }
  get dataVatNumberNotAvailableControl(): AbstractControl | null {
    return this.personalForm.get('dataVatNumberNotAvailable');
  }
  get dataTaxAndVatCountryControl(): AbstractControl | null {
    return this.personalForm.get('dataTaxAndVatCountry');
  }

  changeAvatar(file: File): void {
    this.isAvatarUploading = true;
    this.userProfileService.uploadAvatar(file).subscribe(
      (avatarUrl) => {
        this.avatarUrl = avatarUrl;
        this.authService.getAuthenticatedUser().subscribe();
        this.isAvatarUploading = false;
      },
      (err) => {
        const errorMessage =
          err.status === 400 ? 'Wrong file format' : 'Upload failed';
        this.avatarErrorMessage = errorMessage;
        this.isAvatarUploading = false;
      }
    );
  }

  removeAvatar() {
    this.userProfileService
      .deleteAvatar()
      .pipe(mergeMap(() => this.authService.getAuthenticatedUser()))
      .subscribe(
        () => {},
        () => this.errorService.showErrorDialog()
      );
  }

  changeEmail(): void {
    this.dialog.open<ChangeEmailDialogNewComponent>(
      ChangeEmailDialogNewComponent,
      {
        panelClass: 'dialog-with-close-button',
        width: '670px',
        disableClose: true,
      }
    );
  }

  changePhoneNumber(): void {
    let dialogRef = this.dialog.open<ChangePhoneNumberDialogNewComponent>(
      ChangePhoneNumberDialogNewComponent,
      {
        panelClass: 'dialog-with-close-button',
        width: '670px',
        disableClose: true,
      }
    );
    const phoneNumberChangeSub =
      dialogRef.componentInstance.phoneNumberChange.subscribe(
        (updatedPhoneNumber) => {
          this.individualUserProfileData.cellPhoneNumber = updatedPhoneNumber;
        }
      );
    dialogRef.afterClosed().subscribe(() => {
      phoneNumberChangeSub.unsubscribe();
    });
  }

  changePassword(): void {
    this.dialog.open<ChangePasswordDialogNewComponent>(
      ChangePasswordDialogNewComponent,
      {
        panelClass: 'dialog-with-close-button',
        width: '670px',
        disableClose: true,
      }
    );
  }

  showAccountsInformation(): void {
    this.dialog
      .open<AccountInformationDialogNewComponent>(
        AccountInformationDialogNewComponent,
        {
          panelClass: 'dialog-with-close-button',
          width: '670px',
          disableClose: true,
        }
      )
      .beforeClosed()
      .pipe(filter((shouldUpgrade) => !!shouldUpgrade))
      .subscribe(
        () => {
          this.upgradeToEmerald();
        },
        () => {
          this.errorService.showErrorDialog();
        }
      );
  }

  upgradeToEmerald(): void {
    this.eventSubscription = this.userEventsService.userEventsObservable
      .pipe(
        map((event) => JSON.parse(event)),
        filter(
          (event) => event.type === ServerSentEventType.PFS_ACCOUNT_CREATED // &&
          // event.subType === AccountsCreationEvents.PACKAGE_CREATED
        )
      )
      .subscribe(
        () => {
          this.ngZone.run(() => {
            this.isLoading = false;
            window.location.reload();
          });
        },
        (error) => {
          this.ngZone.run(() => {
            this.isLoading = false;
            this.errorService.showErrorDialog(error.error.message);
          });
        }
      );

    this.isLoading = true;
    this.userProfileService.upgradeAccount().subscribe(
      () => {},
      (error) => {
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  ngOnDestroy() {
    this.additionalPhoneNumberSubscription?.unsubscribe();
    this.isMarketingConsentSubscription?.unsubscribe();
    this.authenticatedUserSubscription?.unsubscribe();
    this.eventSubscription?.unsubscribe();
  }
}

<div class="relative-container">
  <div
    fxLayout
    fxLayoutAlign="center end"
    [class.cursor]="!isLoadingCards && (isCardActive || isLocked)"
  >
    <div
      fxLayoutAlign="center center"
      class="more cursor"
      [class.more-open]="open"
      *ngIf="isCardDropdown"
      (click)="openCardsDropDown()"
    >
      <img src="assets/img/more.svg" alt="more" />
    </div>
    <div
      class="main-container"
      fxLayout
      (click)="
        !isLoadingCards && (isCardActive || isLocked)
          ? redirectToCard()
          : undefined
      "
    >
      <div class="left-section" fxLayout fxLayoutAlign="space-between center">
        <div fxLayout="column" class="name-balance-container">
          <div fxLayout fxLayoutAlign="space-between">
            <div fxLayout fxLayoutAlign="center center" *ngIf="!isLoadingCards">
              <mat-icon
                svgIcon="card"
                *ngIf="!isLocked"
                [class.inactive-card]="accentColor()"
              ></mat-icon>
              <img
                src="assets/img/lock-clock-grey.svg"
                alt="locked"
                class="locked-icon"
                *ngIf="isLocked"
              />
              <div class="name" [class.inactive-card]="accentColor()">
                <app-small-loader *ngIf="isLoadingCards"></app-small-loader>
                <span *ngIf="cards?.length === 0 && !isLoadingCards">
                  Cards
                </span>
                <span *ngIf="!isLoadingCards && name"> {{ name }}</span>
                <span
                  *ngIf="isLocked"
                  [class.inactive-card]="accentColor()"
                  class="locked-span"
                  >locked</span
                >
              </div>
            </div>
            <div class="value" fxLayoutAlign="center center">
              <app-small-loader *ngIf="isLoadingCards"> </app-small-loader>
              <span
                *ngIf="
                  !isLoadingCards &&
                  (availableBalance || availableBalance === 0) &&
                  !showActiveButton() &&
                  (isCardActive || isLocked)
                "
              >
                {{ availableBalance | amount }} EUR
              </span>
            </div>
          </div>
          <div class="sub-section">
            <div fxLayoutAlign="left-end center">
              <img
                src="assets/img/master-card.svg"
                alt="master-card"
                *ngIf="!isLoadingCards && cardholder"
              />
              <span class="cardholder" *ngIf="!isLoadingCards && cardholder">{{
                cardholder | titlecase
              }}</span>
              <app-small-loader
                class="spinner-margin"
                *ngIf="isLoadingCards"
              ></app-small-loader>
            </div>
          </div>
        </div>
        <div
          *ngIf="!isLoadingCards && showActiveButton() && !isLocked"
          class="activation-button"
        >
          <button
            mat-stroked-button
            color="accent"
            class="g-small"
            *ngIf="cards"
            (click)="activateCard(currentCard)"
          >
            Activate
          </button>
        </div>
      </div>
      <div
        class="right-section"
        fxLayoutAlign="center center"
        *ngIf="!isLoadingCards && showChevron() && isChevron"
      >
        <mat-icon svgIcon="chevron-contracted"></mat-icon>
      </div>
    </div>
  </div>
  <div *ngIf="open && cards" class="card-dropdown">
    <app-cards-dropdown
      [cards]="notCurrentCards"
      [program]="program"
      [isLoadingCards]="isLoadingCards"
      (cardActivated)="onCardActivated()"
      (pickedCard)="onPickedCard($event)"
    ></app-cards-dropdown>
  </div>
</div>

<div class="main-container">
  <div fxLayout="column" class="header-container">
    <div fxLayout fxLayoutAlign="space-between center">
      <img src="assets/img/logo-dark.svg" alt="eCredo logo" />
      <h1>account Statement</h1>
    </div>
    <div fxFlexAlign="end" class="description">
      <span *ngIf="dateFrom"> from {{ dateFrom | date: 'shortDate' }}</span>
      <span *ngIf="dateTo"> to {{ dateTo | date: 'shortDate' }}</span>
    </div>
  </div>

  <div class="account" fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="row" fxLayoutGap="20px">
      <div fxFlex="296px">Date</div>
      <div fxFlex="100">{{ today | date: 'shortDate' }}</div>
    </div>
    <div fxLayout="row" fxLayoutGap="20px">
      <div fxFlex="296px">Name</div>
      <div fxFlex="100">{{ userName }}</div>
    </div>
    <div fxLayout="row" fxLayoutGap="20px">
      <div fxFlex="296px">Account Type</div>
      <div fxFlex="100">
        {{ accountName }}
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="20px">
      <div fxFlex="296px">Available Balance</div>
      <div fxFlex="100">{{ availableBalance | amount }} {{ currency }}</div>
    </div>
    <div fxLayout="row" fxLayoutGap="20px">
      <div fxFlex="296px">Book Balance</div>
      <div fxFlex="100">{{ bookBalance | amount }} {{ currency }}</div>
    </div>
  </div>

  <table
    mat-table
    [dataSource]="data"
    [fixedLayout]="true"
    matSort
    matSortDisableClear
    multiTemplateDataRows
  >
    <!-- From Amount Column -->
    <ng-container matColumnDef="fromAmount">
      <th mat-header-cell *matHeaderCellDef>
        <div mat-sort-header="fromAmount">Amount</div>
      </th>
      <td mat-cell *matCellDef="let row" class="amount">
        <div
          [ngClass]="
            row?.typeName === TransactionTypeName.CREDIT ? 'credit' : 'debit'
          "
        >
          {{ row?.fromAmount | statementAmount: row?.typeName }}
          {{ row?.fromCCY }}
        </div>
      </td>
    </ng-container>

    <!-- To Amount Column -->
    <ng-container matColumnDef="toAmount">
      <th mat-header-cell *matHeaderCellDef>
        <div mat-sort-header="toAmount">Transaction Amount</div>
      </th>
      <td mat-cell *matCellDef="let row">
        <div>
          {{ row?.toAmount | statementAmount: row?.typeName }} {{ row?.toCCY }}
        </div>
      </td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>
        <div>Type</div>
      </th>
      <td mat-cell *matCellDef="let row">
        <div>
          {{ row?.typeName }}
        </div>
      </td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="transactionCodeName">
      <th mat-header-cell *matHeaderCellDef>
        <div mat-sort-header="transactionCodeName">Description</div>
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row?.transactionCodeName }}
      </td>
    </ng-container>

    <!-- Current Balance Column -->
    <ng-container matColumnDef="currentBalance">
      <th mat-header-cell *matHeaderCellDef width="15%">
        <div mat-sort-header="currentBalance">Balance</div>
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row?.currentBalance | amount }} {{ row?.currentBalanceCcy }}
      </td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef width="15%">
        <div mat-sort-header="createdAt">Date</div>
      </th>
      <td mat-cell *matCellDef="let row">
        <div>{{ row.createdAt | date: 'shortDate' }}</div>
      </td>
    </ng-container>

    <!-- Reference Column -->
    <ng-container matColumnDef="transactionRefId">
      <th mat-header-cell *matHeaderCellDef>
        <div mat-sort-header="transactionRefId">Reference No.</div>
      </th>
      <td mat-cell *matCellDef="let row">
        <div>{{ row?.transactionRefId }}</div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      matRipple
      class="element-row"
    ></tr>
  </table>
</div>

<mat-card style="width: 70%">
  <div class="table-container">
    <div class="spinner-container" *ngIf="isLoading">
      <app-big-loader></app-big-loader>
    </div>

    <button mat-stroked-button color="accent" class="g-small unread">
      {{ countNumUnreadNotifications() }} Unread
      {{
        countNumUnreadNotifications() === 1 ? 'Notification' : 'Notifications'
      }}
    </button>

    <table mat-table [dataSource]="notifications">
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <div
            class="dot"
            (click)="changeReadStatus(row.numId)"
            *ngIf="row.isUnread"
          ></div>
          <div
            class="read-dot"
            (click)="changeReadStatus(row.numId)"
            *ngIf="!row.isUnread"
          ></div>
          <div style="margin-left: 23px">{{ row.title }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="content">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <div>
            <span *ngIf="containsActionRequired(row.content)"
              >[Action Required]</span
            >
            {{ withoutActionRequired(row.content) }}
            <a
              role="button"
              class="redirect-button"
              (click)="redirectToApplication(row)"
              >{{ hasLinkToApplication(row) }}</a
            >
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="dateCreated">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          {{ row.dateCreated | date: 'dd/MM/yyyy HH:mm' }}
        </td>
      </ng-container>

      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</mat-card>

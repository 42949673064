<div *ngIf="corporateGroup" [formGroup]="corporateGroup">
  <app-admin-field-new
    [userId]="userId"
    label="Company legal name"
    formControlName="businessName"
    [disableComment]="true"
  ></app-admin-field-new>
  <app-admin-field
    [userId]="userId"
    label="Trading name (if applicable)"
    formControlName="businessTradingName"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="Company legal status"
    formControlName="legalStatus"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="Registration number"
    formControlName="registrationNumber"
  >
  </app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="Date of Incorporation"
    formControlName="incorporationDate"
  ></app-admin-field>
  <ng-container [formGroup]="incorporationAddressGroup">
    <app-admin-field-new
      [userId]="userId"
      label="Country of Incorporation"
      formControlName="countryId"
      [disableComment]="isSameAddress"
    ></app-admin-field-new>
  </ng-container>
  <app-admin-field
    [userId]="userId"
    label="Tax Identification Number"
    formControlName="taxNumber"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="VAT Number"
    formControlName="vatNumber"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="Country of Tax Residency/VAT Number"
    formControlName="taxAndVatCountry"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="Company URLs (if applicable)"
    formControlName="companyUrls"
  ></app-admin-field>
  <ng-container [formGroup]="incorporationAddressGroup">
    <app-admin-field-new
      [userId]="userId"
      label="Registered address (Street, building number, place)"
      formControlName="streetAddress"
      [disableComment]="isSameAddress"
    ></app-admin-field-new>
    <app-admin-field-new
      [userId]="userId"
      label="Registered address 2nd line (optional)"
      formControlName="additionalStreetAddress"
      [disableComment]="isSameAddress"
    ></app-admin-field-new>
    <app-admin-field-new
      [userId]="userId"
      label="Zip/Postal code"
      formControlName="postCode"
      [disableComment]="isSameAddress"
    ></app-admin-field-new>
    <app-admin-field-new
      [userId]="userId"
      label="City"
      formControlName="city"
      [disableComment]="isSameAddress"
    ></app-admin-field-new>
  </ng-container>
  <!-- if isSameAddress admin can't comment on the registered address because by adding comment 
    applicant can change the operating address as they are binded,
    but we don't want the operating address to change because it was cross-verified with the document -->
  <ng-container [formGroup]="operatingAddressGroup">
    <div>
      <span *ngIf="isSameAddress"
        >Operating address (Same as Registered address)</span
      >
    </div>
    <app-admin-field-new
      [userId]="userId"
      label="Operating address (Street, building number, place)"
      formControlName="streetAddress"
      [disableComment]="true"
    ></app-admin-field-new>
    <app-admin-field-new
      [userId]="userId"
      label="Operating address 2nd line (optional)"
      formControlName="additionalStreetAddress"
      [disableComment]="true"
    ></app-admin-field-new>
    <app-admin-field-new
      [userId]="userId"
      label="Zip/Postal code"
      formControlName="postCode"
      [disableComment]="true"
    ></app-admin-field-new>
    <app-admin-field-new
      [userId]="userId"
      label="City"
      formControlName="city"
      [disableComment]="true"
    ></app-admin-field-new>
    <app-admin-field-new
      [userId]="userId"
      label="Operating address country"
      formControlName="countryId"
      [disableComment]="true"
    ></app-admin-field-new>
  </ng-container>
  <app-admin-field
    [userId]="userId"
    label="Business activity"
    formControlName="companyActivity"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="Please provide a brief description of the company's business
    activities"
    formControlName="companyActivityDescr"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="Annual income of the last year's financial statement"
    formControlName="annualIncome"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="Is your annual income expected to at least double within the next 12
    months?"
    formControlName="doubleAnnualIncome"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="Percentage of cash transactions on total activities"
    formControlName="percentageOfCash"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="Are you regulated by any authority?"
    formControlName="isRegulated"
  ></app-admin-field>
  <app-admin-field-new
    [userId]="userId"
    label="Please specify which authority"
    formControlName="regulatoryAuthority"
    [disableComment]="true"
  ></app-admin-field-new>
  <app-admin-field
    [userId]="userId"
    label="Do you operate in any of the following countries:"
    formControlName="operateInHighRiskCountries"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="Are you able to initially fund your eCREDO account from an existing
    account at a bank registered and licensed in an EEA country?"
    formControlName="canFundFromOwnAccount"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="Do you have financial statements (no older than two years) audited by a
    registered certified public accountant/auditor?"
    formControlName="isFinancialStatementAudited"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="Do your last year's financial statements contain zero balance in “Total 
    assets” or “Total equities and liabilities” in the balance sheet?"
    formControlName="zeroBalanceStatement"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="Account opening purpose"
    formControlName="accountPurpose"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="Expected monthly loading"
    formControlName="monthlyLoading"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="Expected number of physical cards required"
    formControlName="expectedNumOfPhysicalCards"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="Additional phone number (optional)"
    formControlName="additionalPhoneNumber"
  ></app-admin-field>
</div>

<div *ngIf="corporateGroup" [formGroup]="accountGroup">
  <ng-container formArrayName="accountForm">
    <h2>Company's Accounts</h2>
    <div [ngSwitch]="accountControl.length" class="count">
      <ng-container *ngSwitchCase="1"
        >{{ accountControl.length }} Company’s Account</ng-container
      >
      <ng-container *ngSwitchDefault
        >{{ accountControl.length }} Company’s Accounts
      </ng-container>
    </div>
    <mat-divider></mat-divider>
    <ng-container
      *ngFor="let element of accountControl.controls; let index = index"
      [formGroupName]="index"
    >
      <h3>{{ index + 1 | ordinalNumber }} Bank/Payment Institution</h3>
      <app-admin-field-new
        [userId]="userId"
        label="Bank/Payment Institution Name"
        formControlName="name"
      ></app-admin-field-new>
      <app-admin-field-new
        [userId]="userId"
        label="Bank/Payment Institution Country"
        formControlName="country"
      ></app-admin-field-new>
    </ng-container>
  </ng-container>
</div>

<div *ngIf="corporateGroup" [formGroup]="counterpartyGroup">
  <ng-container formArrayName="counterpartyForm">
    <h2>Company's Counterparties</h2>
    <div [ngSwitch]="counterpartyControl.length" class="count">
      <ng-container *ngSwitchCase="1"
        >{{ counterpartyControl.length }} Company's Counterparty</ng-container
      >
      <ng-container *ngSwitchDefault
        >{{ counterpartyControl.length }} Company's Counterparties
      </ng-container>
    </div>
    <mat-divider></mat-divider>
    <ng-container
      *ngFor="let element of counterpartyControl.controls; let index = index"
      [formGroupName]="index"
    >
      <h3>{{ index + 1 | ordinalNumber }} Counterparty</h3>
      <app-admin-field-new
        [userId]="userId"
        label="Name"
        formControlName="name"
      ></app-admin-field-new>
      <app-admin-field-new
        [userId]="userId"
        label="Country"
        formControlName="country"
      ></app-admin-field-new>
      <app-admin-field-new
        [userId]="userId"
        label="Business Activity"
        formControlName="businessActivity"
      ></app-admin-field-new>
      <app-admin-field-new
        [userId]="userId"
        label="Incoming Transaction"
        formControlName="isIncomingTransaction"
      ></app-admin-field-new>
      <app-admin-field-new
        [userId]="userId"
        label="Outgoing Transaction"
        formControlName="isOutgoingTransaction"
        [disableComment]="true"
      ></app-admin-field-new>
    </ng-container>
  </ng-container>
</div>

<div *ngIf="closeLinkGroup" [formGroup]="closeLinkGroup">
  <ng-container formArrayName="closeLinkForm">
    <h2>Key Persons' Close Links</h2>
    <div [ngSwitch]="closeLinkControl.length" class="count">
      <ng-container *ngSwitchCase="1"
        >{{ closeLinkControl.length }} Key Persons' Close Link</ng-container
      >
      <ng-container *ngSwitchDefault
        >{{ closeLinkControl.length }} Key Persons' Close Links
      </ng-container>
    </div>
    <mat-divider></mat-divider>
    <ng-container
      *ngFor="let element of closeLinkControl.controls; let index = index"
      [formGroupName]="index"
    >
      <h3>{{ index + 1 | ordinalNumber }} Close Link</h3>
      <app-admin-field-new
        [userId]="userId"
        label="Company name"
        formControlName="name"
      ></app-admin-field-new>
      <app-admin-field-new
        [userId]="userId"
        label="Registration number"
        formControlName="registrationNumber"
      ></app-admin-field-new>
      <app-admin-field-new
        [userId]="userId"
        label="Date of Incorporation"
        formControlName="incorporationDate"
      ></app-admin-field-new>
      <div formGroupName="address">
        <app-admin-field-new
          [userId]="userId"
          label="Registered address (Street, building number, place)"
          formControlName="streetAddress"
        ></app-admin-field-new>
        <app-admin-field-new
          [userId]="userId"
          label="Registered address 2nd line (optional)"
          formControlName="additionalStreetAddress"
        ></app-admin-field-new>
        <app-admin-field-new
          [userId]="userId"
          label="Zip/Postal code"
          formControlName="postCode"
        ></app-admin-field-new>
        <app-admin-field-new
          [userId]="userId"
          label="City"
          formControlName="city"
        ></app-admin-field-new>
        <app-admin-field-new
          [userId]="userId"
          label="Country"
          formControlName="countryId"
        ></app-admin-field-new>
      </div>
    </ng-container>
  </ng-container>
</div>

<div *ngIf="groupMemberGroup" [formGroup]="groupMemberGroup">
  <ng-container formArrayName="groupMemberForm">
    <h2>Company's Group Members</h2>
    <div [ngSwitch]="groupMemberControl.length" class="count">
      <ng-container *ngSwitchCase="1"
        >{{ groupMemberControl.length }} Company's Group Member</ng-container
      >
      <ng-container *ngSwitchDefault
        >{{ groupMemberControl.length }} Company's Group Members
      </ng-container>
    </div>
    <mat-divider></mat-divider>
    <ng-container
      *ngFor="let element of groupMemberControl.controls; let index = index"
      [formGroupName]="index"
    >
      <h3>{{ index + 1 | ordinalNumber }} Group Member</h3>
      <app-admin-field-new
        [userId]="userId"
        label="Company name"
        formControlName="name"
      ></app-admin-field-new>
      <app-admin-field-new
        [userId]="userId"
        label="Registration number"
        formControlName="registrationNumber"
      ></app-admin-field-new>
      <app-admin-field-new
        [userId]="userId"
        label="Date of Incorporation"
        formControlName="incorporationDate"
      ></app-admin-field-new>
      <div formGroupName="address">
        <app-admin-field-new
          [userId]="userId"
          label="Registered address (Street, building number, place)"
          formControlName="streetAddress"
        ></app-admin-field-new>
        <app-admin-field-new
          [userId]="userId"
          label="Registered address 2nd line (optional)"
          formControlName="additionalStreetAddress"
        ></app-admin-field-new>
        <app-admin-field-new
          [userId]="userId"
          label="Zip/Postal code"
          formControlName="postCode"
        ></app-admin-field-new>
        <app-admin-field-new
          [userId]="userId"
          label="City"
          formControlName="city"
        ></app-admin-field-new>
        <app-admin-field-new
          [userId]="userId"
          label="Country"
          formControlName="countryId"
        ></app-admin-field-new>
      </div>
    </ng-container>
  </ng-container>
</div>

<!-- Disable the ability of admin to comment on form fields when in EDD_SUBMITTED -->
<div *ngIf="personalGroup" [formGroup]="personalGroup">
  <app-admin-field
    [userId]="userId"
    label="First name"
    formControlName="firstName"
    [isEDD]="isEDD"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="Last name"
    formControlName="lastName"
    [isEDD]="isEDD"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="Date of Birth"
    formControlName="dateOfBirth"
    [isEDD]="isEDD"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="Country of Birth"
    formControlName="countryOfBirth"
    [isEDD]="isEDD"
  >
  </app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="Nationality"
    formControlName="nationality"
    [isEDD]="isEDD"
  ></app-admin-field>

  <ng-container [formGroup]="addressGroup">
    <app-admin-field
      [userId]="userId"
      label="Address (Street, building number, place)"
      formControlName="streetAddress"
      [isEDD]="isEDD"
    ></app-admin-field>
    <app-admin-field
      [userId]="userId"
      label="Address 2nd line (optional)"
      formControlName="additionalStreetAddress"
      [isEDD]="isEDD"
    >
    </app-admin-field>
    <app-admin-field
      [userId]="userId"
      label="Zip/Postal code"
      formControlName="postCode"
      [isEDD]="isEDD"
    ></app-admin-field>
    <app-admin-field
      [userId]="userId"
      label="City"
      formControlName="city"
      [isEDD]="isEDD"
    ></app-admin-field>
    <app-admin-field
      [userId]="userId"
      label="Country"
      formControlName="countryId"
      [isEDD]="isEDD"
    ></app-admin-field>
  </ng-container>

  <app-admin-field
    [userId]="userId"
    label="The address above is my temporary residence in EEA"
    formControlName="isTempAddress"
    [isEDD]="isEDD"
  ></app-admin-field>

  <app-admin-field
    [userId]="userId"
    label="Additional phone number (optional)"
    formControlName="additionalPhoneNumber"
    [isEDD]="isEDD"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="Secondary email (optional)"
    formControlName="additionalEmail"
    [isEDD]="isEDD"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="What is your occupation status?"
    formControlName="dataOccupationProfession"
    [isEDD]="isEDD"
  >
  </app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="What industry are you involved in?"
    formControlName="dataOccupation"
    [isEDD]="isEDD"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="What is the main purpose of your account?"
    formControlName="dataCardPurpose"
    [isEDD]="isEDD"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="What is your monthly income?"
    formControlName="dataMonthlyIncome"
    [isEDD]="isEDD"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="What is your monthly spend?"
    formControlName="dataMonthlySpend"
    [isEDD]="isEDD"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="Are you (or have you been in the 12 previous months) a PEP or a close family member (spouse, children, parents) or a close associate (any business relations) of a PEP?"
    formControlName="isPEP"
    [isEDD]="isEDD"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="Tax Identification Number"
    formControlName="dataTaxNumber"
    [isEDD]="isEDD"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="VAT Number"
    formControlName="dataVatNumber"
    [isEDD]="isEDD"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="Country of Tax Residency/VAT Number"
    formControlName="dataTaxAndVatCountry"
    [isEDD]="isEDD"
  ></app-admin-field>
  <div *ngIf="showExtraQuestions">
    <app-admin-field
      [userId]="userId"
      label="Source of Wealth"
      formControlName="dataSourceOfWealth"
      [isEDD]="isEDD"
    ></app-admin-field>
    <app-admin-field
      [userId]="userId"
      label="Country of Origin (Source of Wealth)"
      formControlName="countryOfSourceOfWealth"
      [isEDD]="isEDD"
    ></app-admin-field>
    <app-admin-field
      [userId]="userId"
      label="Source of Income"
      formControlName="dataSourceOfIncome"
      [isEDD]="isEDD"
    ></app-admin-field>
    <app-admin-field
      [userId]="userId"
      label="Country of Origin (Source of Income)"
      formControlName="countryOfSourceOfIncome"
      [isEDD]="isEDD"
    ></app-admin-field>
    <app-admin-field
      [userId]="userId"
      label="Size of Wealth"
      formControlName="dataSizeOfWealth"
      [isEDD]="isEDD"
    ></app-admin-field>
  </div>
  <app-admin-field
    [userId]="userId"
    label="Email of your referee (optional)"
    formControlName="referee"
    [isEDD]="isEDD"
  ></app-admin-field>
  <app-admin-field
    [userId]="userId"
    label="How did you hear about us? (optional)"
    formControlName="heardFrom"
    [isEDD]="isEDD"
  ></app-admin-field>
</div>

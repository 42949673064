<app-big-loader *ngIf="isLoading"></app-big-loader>
<div *ngIf="!isLoading" fxLayout="column" [formGroup]="transferForm">
  <!-- FIRST STEP -->
  <div
    *ngIf="!isConfirmationStep"
    fxLayout="column"
    fxLayoutGap="30px"
    class="first-step"
  >
    <!-- Accounts selection -->
    <app-ext-accounts-selector
      [formGroup]="accountsGroup"
      [accounts]="accounts"
      [accountOwnerGuid]="accountOwnerGuid"
      (selectFav)="onFavouriteSelect($event)"
      (selectBen)="onBeneficiarySelect($event)"
    ></app-ext-accounts-selector>
    <app-transfer-details-form
      [formGroup]="otherFieldsGroup"
      [isExternal]="true"
      [currency]="fromCurrencyControl.value"
      [countries]="countries"
      [ibanDetails]="ibanDetails"
      [bicDetails]="bicDetails"
      [intermediaryBicDetails]="intermediaryBicDetails"
      [currencyCodeList]="currencyCodeList"
      [lastUpdated]="lastUpdated"
      [hideTransferSection]="hideTransferSection"
      [disabledDays]="disabledDays"
      [forwardDays]="forwardDays"
      [isProcessing]="isProcessing"
      (shouldTransfer)="goToConfirmationStep()"
    ></app-transfer-details-form>
  </div>

  <!-- CONFIRMATION STEP -->
  <app-transfer-confirmation
    *ngIf="isConfirmationStep"
    fxFlexAlign="center"
    [fromAccount]="fromAccountControl.value"
    [isExternal]="true"
    [currency]="fromCurrencyControl.value"
    [toCurrency]="toCurrencyControl.value"
    [beneficiaryName]="beneficiaryNameControl.value"
    [ibanOrAccountNumber]="ibanOrAccountNumberControl.value"
    [isSepa]="isSepaControl.value"
    [fee]="feeControl.value"
    [bic]="bicControl.value"
    [bankName]="ibanDetails.bank_data.bank || bicDetails.bankName"
    [intermediaryBic]="intermediaryBicControl?.value"
    [intermediaryBankName]="intermediaryBicDetails?.bankName"
    [executionDate]="executionDateControl?.value"
    [charges]="chargesControl?.value"
    [country]="getCountryByCode(countries, creditorCountryCodeControl.value)"
    [address1]="creditorAddress1Control.value"
    [address2]="creditorAddress2Control.value"
    [zipCode]="creditorZipControl.value"
    [city]="creditorCityControl.value"
    [amount]="amountControl.value"
    [payerDescription]="payerDescriptionControl.value"
    [beneficiaryDescription]="beneficiaryDescriptionControl.value"
    [isTransferring]="isTransferring"
    [isInvalidOtp]="isInvalidOtp"
    [transactionId]="transactionId"
    [transferBody]="transferBody"
    (transfer)="transfer($event!)"
    (cancel)="cancelTransfer($event)"
  ></app-transfer-confirmation>
</div>

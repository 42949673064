import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DashboardService } from '../../dashboard.service';
import { ErrorService } from '../../../shared/error-dialog/error.service';
import { OrderExtraCardForm } from '../../models/orderExtraCardForm.model';
import { jointLengthValidator } from '../../../shared/joint-length.validator';
import { firstOrLastNameValidator } from 'src/app/shared/helpers/various-helpers.helper';

@Component({
  selector: 'app-order-new-card-dialog',
  templateUrl: './order-new-card-dialog.component.html',
  styleUrls: ['./order-new-card-dialog.component.scss'],
})
export class OrderNewCardDialogComponent implements OnInit {
  orderNewCardForm!: FormGroup;
  isOrderLoading: boolean = false;
  isSuccess: boolean = false;
  shouldRefresh: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<OrderNewCardDialogComponent>,
    private fb: FormBuilder,
    private dashboardService: DashboardService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.orderNewCardForm = this.fb.group(
      {
        firstName: ['', [Validators.required, ...firstOrLastNameValidator]],
        lastName: ['', [Validators.required, ...firstOrLastNameValidator]],
      },
      {
        validators: [jointLengthValidator('firstName', 'lastName', 64)],
      }
    );
  }

  submitOrderNewCard(): void {
    this.isOrderLoading = true;

    const body: OrderExtraCardForm = {
      strFirstName: this.firstNameControl?.value,
      strLastName: this.lastNameControl?.value,
    };

    this.dashboardService.orderExtraCard(body).subscribe(
      () => {
        this.isSuccess = true;
        this.shouldRefresh = true;
        this.isOrderLoading = false;
      },
      (error) => {
        this.errorService.showErrorDialog(error.error.massage);
        this.isOrderLoading = false;
      }
    );
  }

  get firstNameControl(): AbstractControl | null {
    return this.orderNewCardForm.get('firstName');
  }

  get lastNameControl(): AbstractControl | null {
    return this.orderNewCardForm.get('lastName');
  }
}

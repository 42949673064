import { Component, EventEmitter, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { phoneValidator } from 'src/app/shared/phone.validator';
import { UserProfileService } from '../../user-profile.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/login/services/auth.service';
import { COUNTDOWN_IN_SECONDS } from 'src/app/shared/helpers/various-helpers.helper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-phone-number-dialog-new',
  templateUrl: './change-phone-number-dialog.component.html',
  styleUrls: ['./change-phone-number-dialog.component.scss'],
})
export class ChangePhoneNumberDialogNewComponent implements OnInit {
  hidePassword = true;
  isSent = false;
  isSending = false;
  isVerifying = false;
  isSendingAgain = false;
  isSuccess = false;
  smsSentAgain = false;
  isSendAgainDisabled = false;
  countdown: number = COUNTDOWN_IN_SECONDS;

  form = this.fb.group({
    phoneNumber: ['', [Validators.required, phoneValidator()]],
    otp: ['', Validators.required],
    password: ['', Validators.required],
  });
  phoneNumberChange = new EventEmitter<string>();

  constructor(
    private fb: FormBuilder,
    private userProfileService: UserProfileService,
    private errorService: ErrorService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<ChangePhoneNumberDialogNewComponent>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.otpControl.disable();
  }

  async submit(again?: boolean) {
    if (again) this.startCountdown();
    again ? (this.isSendingAgain = true) : (this.isSending = true);
    (
      await this.userProfileService.requestMobilePhoneNumberChange(
        this.phoneNumberControl.value?.e164Number,
        this.passwordControl.value,
        again
      )
    ).subscribe(
      () => {
        this.isSent = true;
        if (again) {
          this.smsSentAgain = true;
          this.isSendingAgain = false;
        } else {
          this.isSending = false;
        }
        this.otpControl.enable();
      },
      (err) => {
        again ? (this.isSendingAgain = false) : (this.isSending = false);
        if (err.status === 400) {
          this.passwordControl.setErrors({ wrongPassword: true });
        } else if (err.status === 403) {
          this.authService.logOut(false, true);
          this.dialogRef.close(); // too many wrong passwords
        } else if (err.status === 409) {
          this.logOutUser(); // too many sms resends
        } else if (err.status === 422) {
          this.phoneNumberControl.setErrors({ unacceptableData: true });
        } else if (err.status === 405) {
          this.phoneNumberControl.setErrors({
            phoneNumberSameWithExisting: true,
          });
        } else {
          this.errorService.showErrorDialog();
        }
      }
    );
  }

  async startCountdown() {
    this.isSendAgainDisabled = true;
    const countdownInterval = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        clearInterval(countdownInterval);
        this.isSendAgainDisabled = false;
        this.smsSentAgain = false;
        this.countdown = COUNTDOWN_IN_SECONDS;
      }
    }, 1000);
  }

  logOutUser() {
    this.authService.logOut(false, false, true);
    this.dialogRef.close();
  }

  verify(): void {
    this.isVerifying = true;
    this.userProfileService
      .verifyPhoneNumber(
        this.otpControl.value,
        this.phoneNumberControl.value?.e164Number
      )
      .subscribe(
        () => {
          this.isVerifying = false;
          this.isSuccess = true;
          this.phoneNumberChange.emit(
            this.phoneNumberControl.value?.e164Number
          );
        },
        (err) => {
          this.isVerifying = false;
          if (err.status === 400) {
            this.otpControl.setErrors({ wrongCode: true });
          } else if (err.status === 403) {
            this.logOutUser();
          } else {
            this.errorService.showErrorDialog();
          }
        }
      );
  }

  get otpControl(): AbstractControl {
    return this.form.get('otp')!;
  }

  get phoneNumberControl(): AbstractControl {
    return this.form.get('phoneNumber')!;
  }

  get passwordControl(): AbstractControl {
    return this.form.get('password')!;
  }
}

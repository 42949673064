<app-login-card [formGroup]="registerForm">
  <div fxLayoutAlign="space-between center" class="account-type">
    <div data-testid="program-name-text" [ngClass]="[selectedProgramName]">
      {{ selectedProgramName | titlecase }} Account
    </div>
    <button
      mat-stroked-button
      color="accent"
      class="g-small"
      (click)="changeProgram.emit()"
      data-testid="change-program-button"
    >
      Change
    </button>
  </div>
  <div fxLayout="column">
    <label for="firstName" [ngSwitch]="userType">
      <ng-container *ngSwitchCase="userTypes.CORPORATE"
        >Contact person - First name</ng-container
      >
      <ng-container *ngSwitchDefault>First name</ng-container>
    </label>
    <mat-form-field appearance="outline">
      <input
        matInput
        name="firstName"
        formControlName="firstName"
        data-testid="first-name-input"
      />
      <mat-error
        data-testid="validation-message-box"
        *ngIf="firstNameControl?.hasError('required')"
        >Required</mat-error
      >
      <mat-error
        data-testid="validation-message-box"
        *ngIf="firstNameControl?.hasError('pattern')"
        >Invalid character</mat-error
      >
      <mat-error
        data-testid="validation-message-box"
        *ngIf="firstNameControl?.hasError('maxlength')"
        >Too many characters</mat-error
      >
      <mat-error
        data-testid="validation-message-box"
        *ngIf="firstNameControl?.hasError('jointLength')"
        >First name + Last name is too long</mat-error
      >
    </mat-form-field>
  </div>
  <div fxLayout="column">
    <label for="lastName" [ngSwitch]="userType">
      <ng-container *ngSwitchCase="userTypes.CORPORATE"
        >Contact person - Last name</ng-container
      >
      <ng-container *ngSwitchDefault>Last name</ng-container>
    </label>
    <mat-form-field appearance="outline">
      <input
        matInput
        name="lastName"
        formControlName="lastName"
        data-testid="last-name-input"
      />
      <mat-error
        data-testid="validation-message-box"
        *ngIf="lastNameControl?.hasError('required')"
        >Required</mat-error
      >
      <mat-error
        data-testid="validation-message-box"
        *ngIf="lastNameControl?.hasError('pattern')"
        >Invalid character</mat-error
      >
      <mat-error
        data-testid="validation-message-box"
        *ngIf="lastNameControl?.hasError('maxlength')"
        >Too many characters</mat-error
      >
      <mat-error
        data-testid="validation-message-box"
        *ngIf="lastNameControl?.hasError('jointLength')"
        >First name + Last name is too long</mat-error
      >
    </mat-form-field>
  </div>
  <div fxLayout="column">
    <label for="country" [ngSwitch]="userType">
      <ng-container *ngSwitchCase="userTypes.PERSONAL"
        >Country of Residence</ng-container
      >
      <ng-container *ngSwitchDefault
        ><span
          class="operating-address-title"
          [matTooltip]="tooltips.operatingAddressTooltip"
          >Operating address country</span
        ></ng-container
      >
    </label>
    <mat-form-field appearance="outline">
      <mat-select
        name="country"
        formControlName="country"
        placeholder="Please select"
        data-testid="country-of-residence-select"
      >
        <mat-option
          *ngFor="let country of acceptedCountriesObs | async"
          [value]="country"
          [attr.data-testid]="'country-option-' + country.code.toLowerCase()"
          >{{ country.name | titlecase }}</mat-option
        >
      </mat-select>
      <mat-error data-testid="validation-message-box">Required</mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="column">
    <label for="email">Email</label>
    <mat-form-field appearance="outline">
      <input
        matInput
        name="email"
        formControlName="email"
        data-testid="email-input"
      />
      <mat-error
        data-testid="validation-message-box"
        *ngIf="registerForm.get('email')?.hasError('required')"
        >Required</mat-error
      >
      <mat-error
        data-testid="validation-message-box"
        *ngIf="registerForm.get('email')?.hasError('email')"
        >Invalid</mat-error
      >
      <mat-error
        data-testid="validation-message-box"
        *ngIf="registerForm.get('email')?.hasError('pattern')"
        >Invalid</mat-error
      >
    </mat-form-field>
  </div>
  <app-password></app-password>
  <mat-checkbox color="primary" formControlName="termsAccepted">
    <span data-testid="terms-accepted-checkbox-text"
      >I have read and accepted the</span
    ><br />
    <a
      href="https://ecredo.com/wp-content/uploads/2024/11/TERMS-AND-CONDITIONS.pdf"
      target="_blank"
      >Terms and Conditions</a
    >
  </mat-checkbox>
  <label style="margin-bottom: 10px; line-height: 18px; text-align: justify"
    >I consent to receive marketing materials and promotions from eCREDO and its
    authorized service providers by email or via SMS.</label
  >
  <mat-radio-group
    fxLayout="row"
    fxLayoutGap="13px"
    name="consent"
    formControlName="consent"
  >
    <mat-radio-button [value]="true">
      <div>Yes</div>
    </mat-radio-button>
    <mat-radio-button [value]="false">
      <div>No</div>
    </mat-radio-button>
  </mat-radio-group>
  <label style="margin-top: 11px; line-height: 18px; text-align: justify">
    We are committed to treat your data with responsibility and confidentiality.
    You can change your mind at any time by unsubscribing through your account
    in our web application or by contacting our
    <a href="mailto:dpo@ecredo.com">Data Protection Officer</a>.
  </label>

  <label style="margin-top: 11px; line-height: 18px; text-align: justify">
    eCREDO is a fully GDPR compliant entity and if you wish to learn about our
    Privacy Statement and how you can exercise your rights, please click on this
    <a
      href="https://ecredo.com/wp-content/uploads/2024/11/PRIVACY-STATEMENT.pdf"
      target="_blank"
      >link</a
    >.
  </label>
  <button
    mat-flat-button
    fxFlexAlign="center"
    color="accent"
    [disabled]="registerForm.invalid || isLoading"
    [appButtonLoader]="isLoading"
    (click)="register()"
    data-testid="register-button"
  >
    Register
  </button>
</app-login-card>

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CardsComingSoonGuard {
  constructor() {}

  canActivate(): boolean {
    return !environment.DISABLE_CARDS; // return true to allow access
  }
}

import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';

export function e2ePhoneNumberValidator(
  getIbanAccountByPhoneNumber: (phoneNumber: string) => Promise<void>
): AsyncValidatorFn {
  return (formControl: AbstractControl): Promise<ValidationErrors | null> => {
    return getIbanAccountByPhoneNumber(formControl.value)
      .then(() => null)
      .catch((err) => {
        if (err.message === '409') {
          formControl.setErrors({
            ...formControl?.errors,
            e2ePhoneNumberSameWithUser: true,
          });
          return { e2ePhoneNumberSameWithUser: true };
        } else {
          formControl.setErrors({
            ...formControl?.errors,
            e2ePhoneNumber: true,
          });
          return { e2ePhoneNumber: true };
        }
      });
  };
}

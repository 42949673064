<app-base-login-page
  title="Register"
  [buttonText]="isLoggedIn ? '' : 'Log in'"
  [buttonRouterLink]="isLoggedIn ? '' : '/login'"
>
  <app-login-card imgName="mail.svg" [ngSwitch]="isLoggedIn">
    <!-- When logged out (registration flow) -->
    <ng-container *ngSwitchCase="false">
      <h2>Success!</h2>
      <div class="text">
        We’ve just sent you a verification email. Please check your mailbox and
        follow the instructions.
        <h2 class="still-nothing">...still nothing?</h2>
        <div>1. Check your SPAM/Junk mail.</div>
        <div>
          2. Log in and follow the instructions to receive a new verification
          email.
        </div>
        <button
          mat-stroked-button
          class="g-small log-in"
          data-testid="go-to-login-page-button"
          color="accent"
          routerLink="/login"
        >
          Log in
        </button>
      </div>
    </ng-container>

    <!-- When logged in -->
    <ng-container *ngSwitchCase="true">
      <h2>Welcome back!</h2>
      <div class="text">
        It looks like we lost you during your last session. No worries, please
        check your mailbox and follow the instructions.
        <h2 class="still-nothing">...still nothing?</h2>
        <div>1. Check your SPAM/Junk mail.</div>
        <div>
          2. Click the button below to receive a new verification email.
        </div>
        <div class="send-email" *ngIf="isLoggedIn">
          <button
            mat-stroked-button
            fxFlexAlign="center"
            color="accent"
            class="g-small"
            data-testid="send-email-again-button"
            [disabled]="isSending"
            [appButtonLoader]="isSending"
            (click)="sendEmail()"
          >
            Send email again
          </button>
          <img
            *ngIf="sentAgain"
            src="assets/img/accept.svg"
            alt="sent"
            class="sent-again"
          />
        </div>
      </div>
    </ng-container>
  </app-login-card>
</app-base-login-page>

<mat-card>
  <div
    fxLayout
    fxLayout.lt-sm="column"
    fxLayoutGap="30px"
    fxLayoutGap.lt-sm="10px"
    fxLayoutAlign="start center"
    class="filters-container"
    [formGroup]="filtersGroup"
  >
    <div fxLayout="column">
      <label for="search">Search</label>
      <mat-form-field appearance="outline">
        <input
          data-testid="admin-search-input"
          matInput
          formControlName="search"
          name="search"
        />
        <mat-icon matIconSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <mat-checkbox
      data-testid="individuals-checkbox-text"
      color="accent"
      formControlName="individuals"
      >Individuals</mat-checkbox
    >
    <mat-checkbox
      data-testid="businesses-checkbox-text"
      color="accent"
      formControlName="businesses"
      >Businesses</mat-checkbox
    >
  </div>
  <div class="table-container">
    <div class="spinner-container" *ngIf="dataSource.isLoadingObs | async">
      <app-big-loader></app-big-loader>
    </div>

    <table
      mat-table
      [dataSource]="dataSource"
      [fixedLayout]="true"
      matSort
      matSortDisableClear
    >
      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User Id</th>
        <td mat-cell *matCellDef="let row">
          {{ row.id }}
        </td>
      </ng-container>

      <!-- Names Column -->
      <ng-container matColumnDef="names">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header="firstName">First name</div>
          <div mat-sort-header="lastName">Last name</div>
        </th>
        <td
          data-testid="name-cell"
          mat-cell
          *matCellDef="let row"
          [routerLink]="[row.id]"
        >
          <div>{{ row.firstName }}</div>
          <div>{{ row.lastName }}</div>
        </td>
      </ng-container>

      <!-- CompanyName Column -->
      <ng-container matColumnDef="companyName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Company name</th>
        <td data-testid="company-name-cell" mat-cell *matCellDef="let row">
          {{ row.companyName || '-' }}
        </td>
      </ng-container>

      <!-- Program Column -->
      <ng-container matColumnDef="program">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Account</th>
        <td data-testid="program-cell" mat-cell *matCellDef="let row">
          {{ programs[row.program] | titlecase }}
        </td>
      </ng-container>

      <!-- Started Column -->
      <ng-container matColumnDef="activatedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Activation</th>
        <td data-testid="activation-date-cell" mat-cell *matCellDef="let row">
          {{ row.activatedDate | date }}
        </td>
      </ng-container>

      <!-- NoteCount Column -->
      <ng-container matColumnDef="noteCount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Notes</th>
        <td mat-cell *matCellDef="let row">
          <ng-container [ngSwitch]="row.noteCount">
            <button
              data-testid="add-note-button"
              *ngSwitchCase="0"
              mat-icon-button
              (click)="addNote(row)"
            >
              <mat-icon svgIcon="add"></mat-icon>
            </button>
            <button
              mat-stroked-button
              *ngSwitchDefault
              class="note-button"
              color="accent"
              (click)="addNote(row)"
            >
              {{ row.noteCount }}
            </button>
          </ng-container>
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td data-testid="status-cell" mat-cell *matCellDef="let row">
          {{ convertUserState(row.state) | titlecase }}
        </td>
      </ng-container>

      <!-- Contact Column -->
      <ng-container matColumnDef="contact">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header="email">Email</div>
          <div mat-sort-header="cellPhoneNumber">Phone number</div>
        </th>
        <td data-testid="email-phone-cell" mat-cell *matCellDef="let row">
          <div>{{ row.email }}</div>
          <div>{{ row.cellPhoneNumber }}</div>
        </td>
      </ng-container>

      <!-- CountryName Column -->
      <ng-container matColumnDef="countryName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Country</th>
        <td data-testid="country-cell" mat-cell *matCellDef="let row">
          {{ row.countryName | titlecase }}
        </td>
      </ng-container>

      <!-- Started Column -->
      <ng-container matColumnDef="dateOfBirth">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date of Birth</th>
        <td data-testid="date-of-birth-cell" mat-cell *matCellDef="let row">
          {{ row.dateOfBirth | date }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [length]="dataSource.getLength() | async"
      [pageSize]="20"
      [pageSizeOptions]="[20, 50, 100]"
    ></mat-paginator>
  </div>
</mat-card>

import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { AuthService } from '../login/services/auth.service';
import { UserType } from '../login/models/user-type.enum';
import { environment } from 'src/environments/environment';

@Component({
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss'],
})
export class TransferComponent implements OnInit {
  isCorporateUser: boolean = false;
  environment = environment;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService
      .getAuthenticatedUserObs()
      .pipe(first())
      .subscribe((user) => {
        this.isCorporateUser = user?.type === UserType.CORPORATE;
      });
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { Card } from '../../models/card.model';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CardActivationForm } from '../../models/cardActivationForm.model';
import { DashboardService } from '../../dashboard.service';
import { ErrorService } from '../../../shared/error-dialog/error.service';

export interface CardActivationDialogData {
  card: Card;
  onSuccess: () => void;
}

@Component({
  selector: 'app-card-activation-dialog',
  templateUrl: './card-activation-dialog.component.html',
  styleUrls: ['./card-activation-dialog.component.scss'],
})
export class CardActivationDialogComponent implements OnInit {
  cardActivationForm!: FormGroup;
  errorMessage: string = '';
  isSuccess: boolean = false;
  pin: string = '';
  isLoadingActivation: boolean = false;
  newCardNumber: string = '';

  get card(): Card {
    return this.data.card;
  }

  constructor(
    private dialogRef: MatDialogRef<CardActivationDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: CardActivationDialogData,
    private dashboardService: DashboardService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.cardActivationForm = this.fb.group({
      activationCode: ['', Validators.required],
      postalCode: ['', Validators.required],
    });
  }

  submitCardActivation() {
    this.errorMessage = '';
    this.isLoadingActivation = true;

    const body: CardActivationForm = {
      strProvidedKey: this.activationCode.value,
      strZipCode: this.postalCode.value,
      numAccountID: this.data.card.numAccountID,
    };

    this.dashboardService.activateCard(body).subscribe(
      (cardNumber) => {
        this.isSuccess = true;
        this.isLoadingActivation = false;
        this.data.onSuccess();
        if (cardNumber) this.newCardNumber = cardNumber;
      },
      (error) => {
        this.isLoadingActivation = false;
        this.activationCode.setErrors(Validators.nullValidator);
        this.postalCode.setErrors(Validators.nullValidator);
        if (error.status === 400 || error.status === 422) {
          this.errorMessage =
            'The activation key and/or postal code are wrong. Please try again.';
          return;
        }
        this.errorService.showErrorDialog(error.error.message);
      }
    );
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onFocus() {
    this.activationCode.setErrors(null);
    this.postalCode.setErrors(null);
  }
  get activationCode(): FormControl {
    return this.cardActivationForm.controls.activationCode as FormControl;
  }

  get postalCode(): FormControl {
    return this.cardActivationForm.controls.postalCode as FormControl;
  }
}

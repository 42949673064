import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { CardPurpose } from '../../models/card-purpose.enum';
import { MonthlyIncomeNew } from '../../models/monthly-income.enum';
import { MonthlySpend } from '../../models/monthly-spend.enum';
import { OccupationProfession } from '../../models/occupation-profession.enum';
import { Occupation } from '../../models/occupation.enum';
import { OnboardingData } from '../../models/onboarding-data.model';
import { PersonalForm } from '../../models/personal-form.model';
import { OnboardingService } from '../../onboarding.service';
import { OnboardingSubmitDialogNewComponent } from '../onboarding-submit-dialog/onboarding-submit-dialog.component';
import { DateTime } from 'luxon';
import { Country } from 'src/app/shared/models/country.model';
import { jointLengthValidator } from 'src/app/shared/joint-length.validator';
import { phoneValidator } from 'src/app/shared/phone.validator';
import { CountryISO } from 'ngx-intl-tel-input-gg';
import { AuthService } from 'src/app/login/services/auth.service';
import { first, skip } from 'rxjs/operators';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { Subscription } from 'rxjs';
import { OnboardingFieldId } from 'src/app/admin/users/models/onboarding-field-id.enum';
import {
  getCountryById,
  getMonthlyIncomeNew,
  getMonthlySpend,
  getNationalityById,
  getSizeOfWealth,
  getStringValue,
  setExtraQuestionsToRequired,
  NA,
  addressLineValidator,
  firstOrLastNameValidator,
  postCodeCityValidator,
} from 'src/app/shared/helpers/various-helpers.helper';
import { Step } from '../../stepper/stepper.component';
import { AppDocumentType } from '../../models/document-type.enum';
import { SizeOfWealth } from '../../models/size-of-wealth.enum';
import { Program } from 'src/app/login/models/program.enum';
import { SourceOfWealth } from '../../models/source-of-wealth.enum';
import { SourceOfIncome } from '../../models/source-of-income.enum';
import {
  VerificationDialogComponent,
  VerificationDialogData,
} from '../verification-dialog/verification-dialog.component';
import { EventType } from '../../models/event-type.enum';
import { AppDocument } from '../../models/document.model';
import { VerificationResponse } from 'src/app/admin/users/ongoing-registration-new/ongoing-registration-user/risk-assessment/verification/models/verification-response.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-onboarding-individual-form-new',
  templateUrl: './onboarding-individual-form.component.html',
  styleUrls: ['./onboarding-individual-form.component.scss'],
})
export class OnboardingIndividualFormNewComponent implements OnInit, OnDestroy {
  @Input() set onboardingData(val: OnboardingData) {
    this._onboardingData = val;
    const formValue = {
      ...val.personalForm,
      dateOfBirth: val.personalForm?.dateOfBirth
        ? DateTime.fromISO(val.personalForm?.dateOfBirth)
        : null,
      dataCardPurpose: val?.personalForm?.dataCardPurpose
        ? val?.personalForm?.dataCardPurpose.split(', ')
        : '',
      dataSourceOfWealth: val?.personalForm?.dataSourceOfWealth?.split(', '),
      dataSourceOfIncome: val?.personalForm?.dataSourceOfIncome?.split(', '),
    };

    this.personalForm.patchValue(formValue);

    // if tax number is N/A set the checkbox to true
    if (val?.personalForm?.dataTaxNumber === NA) {
      this.dataTaxNumberNotAvailableControl?.setValue(true);
      this.dataTaxNumberControl?.disable();
    } else {
      this.dataTaxNumberNotAvailableControl?.setValue(false);
      this.dataTaxNumberControl?.enable();
    }

    // if vat number is N/A set the checkbox to true
    if (val?.personalForm?.dataVatNumber === NA) {
      this.dataVatNumberNotAvailableControl?.setValue(true);
      this.dataVatNumberControl?.disable();
    } else {
      this.dataVatNumberNotAvailableControl?.setValue(false);
      this.dataVatNumberControl?.enable();
    }

    if (this.personalForm.valid) {
      this.step2Control.setValue(true);
    }
    this.setFieldsMap();

    // show that verification was done only if id and address docs exist and are accepted (in case documents failed to be saved)
    if (
      this.identityDocsUploaded(val.documents) &&
      val.onboardingVerificationDone
    ) {
      this.verificationAccepted = true;
      this.disableFormFields();
    }
  }
  get onboardingData(): OnboardingData {
    return this._onboardingData;
  }
  @Input() countries: Country[] = [];
  @Input() acceptedCountries: Country[] = [];
  @Input() isReviewed!: boolean; // true when userState === UserState.EDD
  @Input() isEDD!: boolean; // true when userState === UserState.EDD
  @Input() steps: Step[] = [];
  @Input() showExtraQuestions!: boolean;
  @Input() program?: Program;

  Program = Program;
  getCountryById = getCountryById;
  getNationalityById = getNationalityById;
  getMonthlyIncome = getMonthlyIncomeNew;
  getMonthlySpend = getMonthlySpend;
  getSizeOfWealth = getSizeOfWealth;
  getStringValue = getStringValue;
  OnboardingFieldId = OnboardingFieldId;
  occupationProfessions = Object.entries(OccupationProfession);
  occupations = Object.entries(Occupation);
  cardPurposes = Object.entries(CardPurpose);
  monthlyIncomes = Object.entries(MonthlyIncomeNew);
  monthlySpends = Object.entries(MonthlySpend);
  sizesOfWealth = Object.entries(SizeOfWealth);
  sourcesOfWealth = Object.entries(SourceOfWealth);
  sourcesOfIncome = Object.entries(SourceOfIncome);
  NA = NA;

  today: Date = new Date();
  readonly minAge = 18;
  minDob: Date = new Date(
    this.today.getFullYear() - this.minAge,
    this.today.getMonth(),
    this.today.getDate()
  );

  countryCode: CountryISO = CountryISO.Cyprus;
  isFromGreece = false;
  isSavingStep2: boolean = false;
  isGettingVerificationUrl: boolean = false;
  verificationAccepted: boolean = false;
  referenceId!: string;

  fieldsMap: Map<
    string,
    { comment: string | null; commentedValue: string | null } | null
  > = new Map<
    string,
    { comment: string | null; commentedValue: string | null } | null
  >();

  personalForm: FormGroup = this.fb.group(
    {
      firstName: ['', [Validators.required, ...firstOrLastNameValidator]],
      lastName: ['', [Validators.required, ...firstOrLastNameValidator]],
      dateOfBirth: [null, Validators.required],
      countryOfBirth: ['', Validators.required],
      nationality: ['', Validators.required],
      address: this.fb.group({
        streetAddress: ['', [Validators.required, ...addressLineValidator]],
        additionalStreetAddress: ['', [...addressLineValidator]],
        postCode: ['', [Validators.required, ...postCodeCityValidator]],
        city: ['', [Validators.required, ...postCodeCityValidator]],
        countryId: [{ value: '', disabled: true }, Validators.required], // country is disabled because of tax clearance
      }),
      isTempAddress: [null],
      additionalPhoneNumber: ['', phoneValidator()],
      additionalEmail: [
        '',
        [Validators.email, Validators.pattern('^[a-zA-Z0-9@._-]{1,255}$')],
      ],
      dataOccupationProfession: ['', Validators.required],
      dataOccupation: ['', Validators.required],
      dataCardPurpose: ['', Validators.required],
      dataMonthlyIncome: ['', Validators.required],
      dataMonthlySpend: ['', Validators.required],
      referee: ['', Validators.email],
      heardFrom: [
        '',
        [
          Validators.pattern("^[a-zA-Z0-9 .,`'\\/()-]*$"),
          Validators.maxLength(100),
        ],
      ],
      isPEP: [null, Validators.required],
      dataSourceOfWealth: [''],
      dataSourceOfIncome: [''],
      dataSizeOfWealth: [''],
      countryOfSourceOfWealth: [''],
      countryOfSourceOfIncome: [''],
      dataTaxNumber: [
        '',
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
          Validators.maxLength(50),
        ],
      ],
      dataTaxNumberNotAvailable: [''],
      dataVatNumber: [
        '',
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9 .`'\\/&()@_+#=-]*$"),
          Validators.maxLength(50),
        ],
      ],
      dataVatNumberNotAvailable: [''],
      dataTaxAndVatCountry: ['', taxOrVatExists()], // country should be required if at least one of the fields exist
    },
    {
      validators: [jointLengthValidator('firstName', 'lastName', 64)],
    }
  );

  appCompleteForm: FormGroup = this.fb.group({
    step0: [false, Validators.requiredTrue],
    step1: [false, Validators.requiredTrue],
    step2: [false, Validators.requiredTrue],
  });

  private _onboardingData!: OnboardingData;
  private personalFormSub: Subscription | undefined;
  private formSub: Subscription | undefined;
  eddDocumentTypes: AppDocumentType[] = [];
  dialogRef!: MatDialogRef<VerificationDialogComponent>;

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private authService: AuthService,
    private errorService: ErrorService,
    private onboardingService: OnboardingService
  ) {}

  ngOnInit(): void {
    // show extra questions (source of wealth/income etc) if: (Jade + submitted edd) or (Emerald)
    if (this.showExtraQuestions) {
      setExtraQuestionsToRequired(this.personalForm);
      // mark the form as 'Incomplete' if any of the following is missing
      if (
        !this.onboardingData.personalForm?.dataSourceOfWealth ||
        !this.onboardingData.personalForm?.countryOfSourceOfWealth ||
        !this.onboardingData.personalForm?.dataSourceOfIncome ||
        !this.onboardingData.personalForm?.countryOfSourceOfIncome ||
        !this.onboardingData.personalForm?.dataSizeOfWealth
      ) {
        this.step2Control.setValue(false);
      }
    }

    // if tax number is NA, disable the tax field and set the tax number to NA
    this.dataTaxNumberNotAvailableControl?.valueChanges.subscribe(
      (notAvailable) => {
        if (notAvailable) {
          this.dataTaxNumberControl?.disable();
          this.dataTaxNumberControl?.setValue(NA);
        } else {
          this.dataTaxNumberControl?.enable();
          this.dataTaxNumberControl?.setValue(null);
        }
        // run custom validator of country
        this.dataTaxAndVatCountryControl?.updateValueAndValidity();
      }
    );
    this.dataTaxNumberControl?.valueChanges.subscribe(() => {
      this.dataTaxAndVatCountryControl?.updateValueAndValidity();
    });

    // if vat number is NA, disable the vat field and set the vat number to NA
    this.dataVatNumberNotAvailableControl?.valueChanges.subscribe(
      (notAvailable) => {
        if (notAvailable) {
          this.dataVatNumberControl?.disable();
          this.dataVatNumberControl?.setValue(NA);
        } else {
          this.dataVatNumberControl?.enable();
          this.dataVatNumberControl?.setValue(null);
        }
        // run custom validator of country
        this.dataTaxAndVatCountryControl?.updateValueAndValidity();
      }
    );
    this.dataVatNumberControl?.valueChanges.subscribe(() => {
      this.dataTaxAndVatCountryControl?.updateValueAndValidity();
    });

    // if form is EDD, disable personalForm so that data and risk score do not change
    if (this.isEDD) {
      // If PEP, add Letter of Recommendation
      if (this.onboardingData?.personalForm?.isPEP) {
        this.eddDocumentTypes.push(AppDocumentType.LETTER_OF_RECOMMENDATION);
      }
      // Add rest of documents and Another File
      this.eddDocumentTypes.push(AppDocumentType.PROOF_OF_OCCUPATION_STATUS);
      this.eddDocumentTypes.push(AppDocumentType.SOURCE_OF_WEALTH);
      this.eddDocumentTypes.push(AppDocumentType.ORIGIN_OF_FUNDS);
      this.eddDocumentTypes.push(
        AppDocumentType.DESTINATION_OF_OUTWARD_PAYMENTS
      );
      this.eddDocumentTypes.push(AppDocumentType.ANOTHER_FILE);
    }

    this.authService
      .getAuthenticatedUserObs()
      .pipe(first())
      .subscribe((user) => {
        // set country code only if exists in ngx-intl-tel-input
        if (
          Object.values(CountryISO).includes(
            user?.country.code.toLowerCase() as CountryISO
          )
        ) {
          this.countryCode = user?.country.code as CountryISO;
        }
        this.isFromGreece =
          user?.country.code.toLowerCase() === CountryISO.Greece.toLowerCase();
      });

    /* It skips 2 changes because of additionalPhoneNumber
      (library emits change event when setting value programatically, and we set it 2 times:
      - @Input set onboardingData
      - below in valueChanges sub)
    */
    this.personalFormSub = this.personalForm.valueChanges
      .pipe(skip(2))
      .subscribe(() => this.step2Control.setValue(false));

    // removes redundant prefix from phone number when set from backend
    this.additionalPhoneNumberControl?.valueChanges
      .pipe(first())
      .subscribe((val) =>
        this.additionalPhoneNumberControl?.setValue(val, {
          emitEvent: false,
        })
      );
  }

  ngOnDestroy(): void {
    this.personalFormSub?.unsubscribe();
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.formSub?.unsubscribe();
  }

  savePersonalForm(): void {
    this.isSavingStep2 = true;
    this.step2Control.setValue(false); // prevents submitting application when saving
    const body: PersonalForm = {
      ...this.personalForm.getRawValue(), // getRawValue is used to get also blocked countryId field value
      dateOfBirth: (this.dateOfBirthControl?.value as DateTime).toISODate(),
      additionalPhoneNumber:
        this.additionalPhoneNumberControl?.value?.e164Number || '',
      additionalEmail: this.additionalEmailControl?.value
        ? this.additionalEmailControl?.value
        : null,
      referee: this.refereeControl?.value ? this.refereeControl?.value : null,
      isPEP: this.isPEPControl?.value,
      isTempAddress: this.isTempAddressControl?.value,
      dataCardPurpose: this.dataCardPurposeControl?.value.join(', '),
      dataSourceOfWealth: this.dataSourceOfWealthControl?.value
        ? this.dataSourceOfWealthControl?.value.join(', ')
        : null,
      dataSourceOfIncome: this.dataSourceOfIncomeControl?.value
        ? this.dataSourceOfIncomeControl?.value.join(', ')
        : null,
    };
    this.onboardingService.savePersonalForm(body).subscribe(
      () => {
        this.isSavingStep2 = false;
        this.personalForm.markAsPristine();
        this.step2Control.setValue(true);
      },
      () => {
        this.isSavingStep2 = false;
        this.errorService.showErrorDialog();
      }
    );
  }

  identityDocsUploaded(documents: AppDocument[]) {
    const hasDocumentTypes = (
      types: AppDocumentType[],
      documents: AppDocument[]
    ) =>
      types.every((type) =>
        documents.some((obj) => obj.type === type && obj.isAccepted)
      );
    const hasDocs1 = hasDocumentTypes(
      [
        AppDocumentType.IDENTITY_CARD,
        AppDocumentType.IDENTITY_CARD_SECOND_PAGE,
        AppDocumentType.PROOF_OF_ADDRESS,
      ],
      documents
    );
    const hasDocs2 = hasDocumentTypes(
      [AppDocumentType.PASSPORT, AppDocumentType.PROOF_OF_ADDRESS],
      documents
    );
    return hasDocs1 || hasDocs2;
  }

  disableFormFields() {
    // disable fields that were cross-verified with the documents
    this.firstNameControl?.disable();
    this.lastNameControl?.disable();
    this.dateOfBirthControl?.disable();
    this.personalForm.get('address')?.disable();
  }

  @HostListener('window:message', ['$event'])
  async onMessage(event: MessageEvent) {
    if (event.origin === environment.SP_URL) {
      const verificationStatus = event.data.verification_status;
      if (verificationStatus === EventType.VERIFICATION_ACCEPTED) {
        const body: VerificationResponse = {
          requestId: event.data.request_id,
          verificationStatus: event.data.verification_status,
          referenceId: this.referenceId,
        };
        (await this.onboardingService.getVerificationResponse(body)).subscribe(
          (res: { documents: AppDocument[] }) => {
            if (this.dialogRef) {
              // get the documents from backend and show that they were uploaded (dont show extra info e.g. expiry date)
              this.onboardingData.documents = res.documents;
              // show that verification was done only if id and utiity docs exist (in case documents failed to be saved)
              if (this.identityDocsUploaded(res.documents)) {
                this.verificationAccepted = true;
                // disable the form because if verification is succesful user to not change data
                this.disableFormFields();
                if (this.personalForm.valid) {
                  // Fixed: used to show Incomplete
                  this.step2Control.setValue(true);
                }
              }
              // show the close button to close the dialog
              this.dialogRef.componentInstance.updateCloseButtonVisibility(
                true
              );
            }
          },
          () => {
            this.errorService.showErrorDialog();
          }
        );
      } else if (
        verificationStatus === EventType.VERIFICATION_DECLINED ||
        verificationStatus === EventType.VERIFICATION_CANCELLED
      ) {
        // show the close button to close the dialog
        this.dialogRef.componentInstance.updateCloseButtonVisibility(true);
      }
    }
  }

  async getVerificationUrl(): Promise<void> {
    this.isGettingVerificationUrl = true;
    this.onboardingService.getVerificationUrl(true, true).subscribe(
      (res) => {
        this.isGettingVerificationUrl = false;
        // open dialog to not allow data changes
        this.referenceId = res.reference_id;
        this.dialogRef = this.dialog.open<
          VerificationDialogComponent,
          VerificationDialogData
        >(VerificationDialogComponent, {
          width: '600px',
          height: '700px',
          disableClose: true,
          data: {
            src: res.verification_url,
          },
        });
      },
      () => {
        this.isGettingVerificationUrl = false;
        this.errorService.showErrorDialog();
      }
    );
  }

  submit(): void {
    this.dialog.open(OnboardingSubmitDialogNewComponent, {
      panelClass: 'dialog-with-close-button',
      width: '690px',
      disableClose: true,
    });
  }

  get firstNameControl(): AbstractControl | null {
    return this.personalForm.get('firstName');
  }
  get lastNameControl(): AbstractControl | null {
    return this.personalForm.get('lastName');
  }
  get dateOfBirthControl(): AbstractControl | null {
    return this.personalForm.get('dateOfBirth');
  }
  get countryOfBirthControl(): AbstractControl | null {
    return this.personalForm.get('countryOfBirth');
  }
  get dataSourceOfWealthControl(): AbstractControl | null {
    return this.personalForm.get('dataSourceOfWealth');
  }
  get dataOccupationProfessionControl(): AbstractControl | null {
    return this.personalForm.get('dataOccupationProfession');
  }
  get dataOccupationControl(): AbstractControl | null {
    return this.personalForm.get('dataOccupation');
  }
  get dataMonthlyIncomeControl(): AbstractControl | null {
    return this.personalForm.get('dataMonthlyIncome');
  }
  get dataMonthlySpendControl(): AbstractControl | null {
    return this.personalForm.get('dataMonthlySpend');
  }
  get dataCardPurposeControl(): AbstractControl | null {
    return this.personalForm.get('dataCardPurpose');
  }
  get dataSizeOfWealthControl(): AbstractControl | null {
    return this.personalForm.get('dataSizeOfWealth');
  }
  get dataSourceOfIncomeControl(): AbstractControl | null {
    return this.personalForm.get('dataSourceOfIncome');
  }
  get countryOfSourceOfWealthControl(): AbstractControl | null {
    return this.personalForm.get('countryOfSourceOfWealth');
  }
  get countryOfSourceOfIncomeControl(): AbstractControl | null {
    return this.personalForm.get('countryOfSourceOfIncome');
  }
  get dataTaxNumberControl(): AbstractControl | null {
    return this.personalForm.get('dataTaxNumber');
  }
  get dataTaxNumberNotAvailableControl(): AbstractControl | null {
    return this.personalForm.get('dataTaxNumberNotAvailable');
  }
  get dataVatNumberControl(): AbstractControl | null {
    return this.personalForm.get('dataVatNumber');
  }
  get dataVatNumberNotAvailableControl(): AbstractControl | null {
    return this.personalForm.get('dataVatNumberNotAvailable');
  }
  get dataTaxAndVatCountryControl(): AbstractControl | null {
    return this.personalForm.get('dataTaxAndVatCountry');
  }
  get nationalityControl(): AbstractControl | null {
    return this.personalForm.get('nationality');
  }
  get streetAddressControl(): AbstractControl | null | undefined {
    return this.personalForm.get('address')?.get('streetAddress');
  }
  get additionalStreetAddressControl(): AbstractControl | null | undefined {
    return this.personalForm.get('address')?.get('additionalStreetAddress');
  }
  get postCodeControl(): AbstractControl | null | undefined {
    return this.personalForm.get('address')?.get('postCode');
  }
  get cityControl(): AbstractControl | null | undefined {
    return this.personalForm.get('address')?.get('city');
  }
  get countryIdControl(): AbstractControl | null | undefined {
    return this.personalForm.get('address')?.get('countryId');
  }
  get isTempAddressControl(): AbstractControl | null {
    return this.personalForm.get('isTempAddress');
  }
  get additionalPhoneNumberControl(): AbstractControl | null {
    return this.personalForm.get('additionalPhoneNumber');
  }
  get additionalEmailControl(): AbstractControl | null {
    return this.personalForm.get('additionalEmail');
  }
  get refereeControl(): AbstractControl | null {
    return this.personalForm.get('referee');
  }
  get heardFromControl(): AbstractControl | null {
    return this.personalForm.get('heardFrom');
  }
  get isPEPControl(): AbstractControl | null {
    return this.personalForm.get('isPEP');
  }
  get step0Control(): FormControl {
    return this.appCompleteForm.get('step0') as FormControl;
  }
  get step1Control(): FormControl {
    return this.appCompleteForm.get('step1') as FormControl;
  }
  get step2Control(): FormControl {
    return this.appCompleteForm.get('step2') as FormControl;
  }

  private setFieldsMap(): void {
    this.fieldsMap.clear();
    this.onboardingData?.fields
      .filter((field) => !!field.comment)
      .forEach((field) => {
        let commentedValue = field.commentedValue;
        // converts id to readable string
        if (
          field.id.endsWith('.countryId') ||
          field.id.endsWith('.countryOfBirth') ||
          field.id.endsWith('.countryOfSourceOfIncome') ||
          field.id.endsWith('.countryOfSourceOfWealth')
        ) {
          commentedValue = this.getCountryById(
            this.countries,
            parseInt(commentedValue)
          );
        }
        // converts id to readable string
        if (field.id.endsWith('.nationality')) {
          commentedValue = this.getNationalityById(
            this.countries,
            parseInt(commentedValue)
          );
        }
        // converts date
        if (field.id.endsWith('.dateOfBirth')) {
          commentedValue = DateTime.fromISO(commentedValue).toLocaleString(
            DateTime.DATE_MED
          );
        }
        this.fieldsMap.set(field.id, {
          comment: field.comment,
          commentedValue,
        });
      });
  }
}

export function taxOrVatExists(isCorporate = false): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control?.parent) {
      let [taxStr, vatStr, taxAndVatCountryStr] = isCorporate
        ? ['taxNumber', 'vatNumber', 'taxAndVatCountry']
        : ['dataTaxNumber', 'dataVatNumber', 'dataTaxAndVatCountry'];
      var tax = control?.parent?.get(taxStr)?.value;
      var vat = control?.parent?.get(vatStr)?.value;
      var country = control?.parent?.get(taxAndVatCountryStr)?.value;
      // country is required if tax or vat are not NA and country is empty
      if (((tax && tax !== NA) || (vat && vat !== NA)) && !country) {
        return { required: true };
      }
      // if tax and vat are NA, set the country to null
      if (tax === NA && vat === NA && country) {
        control.parent.get(taxAndVatCountryStr)?.setValue(null);
      }
    }
    return null;
  };
}

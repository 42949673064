<div
  class="buttons-container"
  fxLayoutAlign="start center"
  fxLayoutGap="20px"
  fxLayoutGap.lt-sm="12px"
>
  <button
    mat-flat-button
    class="g-medium"
    routerLink="iban-account"
    routerLinkActive="active"
    color="accent"
  >
    IBAN Account
  </button>
  <button
    *ngIf="!environment.DISABLE_CARDS"
    mat-flat-button
    class="g-medium"
    routerLink="cards"
    routerLinkActive="active"
    color="accent"
  >
    Cards
  </button>
  <button
    *ngIf="!environment.DISABLE_WALLET"
    mat-flat-button
    class="g-medium"
    routerLink="wallet"
    routerLinkActive="active"
    color="accent"
  >
    Wallet
  </button>
</div>
<router-outlet></router-outlet>

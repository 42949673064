import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserType } from '../login/models/user-type.enum';
import { AuthService } from '../login/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserTypeGuard {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const allowedTypes = route.data.allowedTypes;

    return this.authService.getAuthenticatedUser().pipe(
      map((user) => {
        if (allowedTypes.includes(user?.type)) {
          return true;
        } else {
          switch (user?.type) {
            case UserType.PERSONAL:
              return this.router.parseUrl('dashboard');
            case UserType.CORPORATE:
              return this.router.parseUrl('dashboard');
            case UserType.ADMINISTRATOR:
            case UserType.ONBOARDING_AGENT:
            case UserType.OPERATIONS_MANAGER:
            case UserType.AMLCO:
            case UserType.BOD:
              return this.router.parseUrl('admin');
            default:
              return this.router.parseUrl('login');
          }
        }
      })
    );
  }
}

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AccountsTable } from '../../shared/accounts-table.model';
import { MatSort } from '@angular/material/sort';
import { accountStatusNamesMap } from '../../../../../../dashboard/shared/account-status-names.map';
import { MatPaginator } from '@angular/material/paginator';
import { merge, Subscription } from 'rxjs';
import { ErrorService } from '../../../../../../shared/error-dialog/error.service';
import { GenericAccountsTableDatasource } from './generic-accounts-table.datasource';
import { calculateDateRange } from '../../../../../../dashboard/shared/calculateDateRange';
import { StatementSearch } from '../../../../../../statement/models/statement-search.model';
import { AccountStatusNew } from 'src/app/dashboard/shared/account-status-new.enum';
import { StatementService } from 'src/app/statement/statement.service';
import { TransactionTypeName } from 'src/app/shared/models/transaction-type-name.enum';

@Component({
  selector: 'app-generic-accounts-table',
  templateUrl: './generic-accounts-table.component.html',
  styleUrls: ['./generic-accounts-table.component.scss'],
})
export class GenericAccountsTableComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @Input() userId!: number;
  @Input() accountTable!: AccountsTable;
  @Output() resetCurrentTable = new EventEmitter<void>();

  dataSource!: GenericAccountsTableDatasource;
  displayedColumns: string[] = [
    'createdAt',
    'type',
    'transactionCodeName',
    'fromAmount',
    'toAmount',
    'currentBalance',
    'transactionRefId',
  ];

  TransactionTypeName = TransactionTypeName;

  private sortSub?: Subscription;
  private mergeSub?: Subscription;

  constructor(
    private errorService: ErrorService,
    private statementService: StatementService
  ) {}

  ngOnInit(): void {
    this.dataSource = new GenericAccountsTableDatasource(
      this.statementService,
      this.errorService
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.loadPage()); // listens for filters values

    // reset the paginator after sorting
    this.sortSub = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );

    this.mergeSub = merge(this.sort.sortChange, this.paginator.page).subscribe(
      () => {
        this.loadPage();
      }
    );
  }

  loadPage(): void {
    const data = calculateDateRange();
    const params: StatementSearch = {
      accountCode: this.accountTable.accountDetails.code,
      dtStartDate: this.accountTable.accountDetails.dateCreated,
      dtEndDate: data.present,
      page: this.paginator.pageIndex,
      limit: this.paginator.pageSize,
      orderField: this.sort.active,
      orderAscending: this.sort.direction === 'asc',
      currency: this.accountTable.accountDetails.ccy,
      userId: this.userId,
    };

    this.dataSource.clear();
    this.dataSource.loadStatements(params);
  }

  isAccountOpen(status: AccountStatusNew): boolean {
    return status === AccountStatusNew.ACTIVE;
  }

  getAccountStatusName(status: AccountStatusNew): string {
    return accountStatusNamesMap.get(status)!;
  }

  goBack(): void {
    this.resetCurrentTable.emit();
  }

  ngOnDestroy(): void {
    this.sortSub?.unsubscribe();
    this.mergeSub?.unsubscribe();
  }
}

<ng-container [ngSwitch]="state" [formGroup]="saveForm">
  <!-- INITIAL -->
  <ng-container *ngSwitchCase="SaveFavouriteState.initial">
    <button
      mat-stroked-button
      color="accent"
      class="g-medium"
      (click)="state = SaveFavouriteState.editing"
      data-testid="save-transfer-to-favourites-button"
    >
      Save transfer to Favourites
    </button>
  </ng-container>

  <!-- EDITING -->
  <div *ngSwitchCase="SaveFavouriteState.editing" class="editing-container">
    <div fxLayout="column">
      <label>Transfer label</label>
      <mat-form-field>
        <input
          matInput
          formControlName="ftName"
          data-testid="favourite-transaction-name-input"
        />
        <mat-error *ngIf="ftNameControl.hasError('required')"
          >Required</mat-error
        >
        <mat-error *ngIf="ftNameControl.hasError('maxlength')"
          >Too long</mat-error
        >
      </mat-form-field>
    </div>
    <div fxLayoutAlign="space-between">
      <button
        mat-stroked-button
        color="accent"
        class="g-small"
        (click)="cancel()"
        [disabled]="isSaving"
        data-testid="cancel-save-to-favourites-button"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        color="accent"
        class="g-small"
        [disabled]="!saveForm.valid || isSaving"
        [appButtonLoader]="isSaving"
        (click)="save()"
        data-testid="confirm-save-to-favourites-button"
      >
        Save
      </button>
    </div>
  </div>

  <!-- SAVED -->
  <div *ngSwitchCase="SaveFavouriteState.saved" class="saved">
    Transfer saved to Favourites
  </div>
</ng-container>

<div mat-dialog-title fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <div fxLayout="row" *ngIf="userType === userTypes.PERSONAL">
    <div>
      <app-account-header [program]="programs.JADE"></app-account-header>
      <div>
        <div fxLayout="column" fxLayoutAlign="start center">
          <div class="price">Free</div>
          <div class="user-account">Your current Account</div>
        </div>
        <ul>
          <li *ngIf="!environment.DISABLE_CARDS">
            <strong> Loading on card</strong><br />
            3,000&euro;/month, 36,000&euro;/year
          </li>
          <li>
            <strong>Loading on CY IBAN</strong><br />
            6,000&euro;/month, 72,000&euro;/year
          </li>
          <li *ngIf="!environment.DISABLE_CARDS">
            <strong>eCREDO Card</strong><br />
            10€ fee including delivery within EU
          </li>
          <li>
            <strong>SEPA transfers</strong><br />
            FREE all inbound transfers<br />
            FREE outbound transfers up to<br />
            €1,000/transfer
          </li>
          <li *ngIf="!environment.DISABLE_SWIFT">
            <strong>International Payments (SWIFT)</strong><br />
            Transfers in major currencies worldwide
          </li>
          <li>
            <strong>e2e Now</strong><br />
            Instant money transfers between eCREDO clients
          </li>
          <li *ngIf="!environment.DISABLE_WALLET">
            <strong>Currency Conversion Exchange</strong><br />
            Convert between 10 major currencies
          </li>
          <li *ngIf="!environment.DISABLE_CARDS">
            <strong>Low fees ATM Cash Withdrawals</strong><br />
            Up to 900&euro;/month
          </li>
          <li *ngIf="!environment.DISABLE_CARDS">
            <strong>In-store and online Payments</strong><br />
            Including Card 3D secure
          </li>
        </ul>
      </div>
    </div>

    <div class="vertical-line"></div>

    <div>
      <app-account-header [program]="programs.EMERALD"></app-account-header>
      <div>
        <div fxLayout="column" fxLayoutAlign="start center">
          <div class="price">9.95&euro;/month</div>
          <button
            mat-stroked-button
            color="accent"
            class="g-small"
            (click)="upgradeAccount()"
          >
            Upgrade to Emerald
          </button>
        </div>
        <ul>
          <li *ngIf="!environment.DISABLE_CARDS">
            <strong> Loading on card</strong> <br />
            6,000&euro;/month, 72,000&euro;/year
          </li>
          <li>
            <strong>Loading on CY IBAN</strong><br />
            50,000&euro;/month, 200,000&euro;/year
          </li>
          <li *ngIf="!environment.DISABLE_CARDS">
            <strong>eCREDO Card</strong><br />
            5€ fee including delivery within EU
          </li>
          <li *ngIf="!environment.DISABLE_CARDS">
            Up to <strong>3 additional cards</strong>
          </li>
          <li>
            <strong>SEPA transfers</strong><br />
            FREE all inbound transfers<br />
            FREE outbound transfers up to<br />
            €2,000/transfer
          </li>
          <li *ngIf="!environment.DISABLE_SWIFT">
            <strong>International Payments (SWIFT)</strong><br />
            Transfers in major currencies worldwide
          </li>
          <li>
            <strong>e2e Now</strong><br />
            Instant money transfers between eCREDO clients
          </li>
          <li *ngIf="!environment.DISABLE_WALLET">
            <strong>Currency Conversion Exchange</strong><br />
            Convert between 10 major currencies
          </li>
          <li *ngIf="!environment.DISABLE_CARDS">
            <strong>Low fees ATM Cash Withdrawals</strong><br />
            Up to 900&euro;/month
          </li>
          <li *ngIf="!environment.DISABLE_CARDS">
            <strong>In-store and online Payments</strong><br />
            Including Card 3D secure
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div fxLayout="row" *ngIf="userType === userTypes.CORPORATE">
    <div>
      <app-account-header [program]="programs.ONYX"></app-account-header>
      <div>
        <div fxLayout="column" fxLayoutAlign="start center">
          <div class="price">39.95&euro;/month</div>
          <div class="user-account">Your current Account</div>
        </div>

        <ul>
          <li *ngIf="!environment.DISABLE_CARDS">
            <strong> Loading on card</strong><br />
            55,000&euro;/month, 660,000&euro;/year
          </li>
          <li>
            <strong>Loading on CY IBAN</strong> <br />
            110,000&euro;/month, 1,320,000&euro;/year
          </li>
          <li *ngIf="!environment.DISABLE_CARDS">
            Up to <strong>9 Corporate cards</strong>
          </li>
          <li *ngIf="!environment.DISABLE_CARDS">
            <strong>Free eCREDO Card</strong><br />
            Including delivery within EU
          </li>
          <li>
            <strong>SEPA transfers</strong><br />
            FREE all inbound transfers<br />
          </li>
          <li *ngIf="!environment.DISABLE_SWIFT">
            <strong>International Payments (SWIFT)</strong><br />
            Transfers in major currencies worldwide
          </li>
          <li><strong>Mass payments/Payroll feature</strong></li>
          <li>
            <strong>e2e Now</strong><br />
            Instant money transfers between eCREDO clients
          </li>
          <li *ngIf="!environment.DISABLE_WALLET">
            <strong>Currency Conversion Exchange</strong><br />
            Convert between 10 major currencies
          </li>
          <li *ngIf="!environment.DISABLE_CARDS">
            <strong>No fee ATM Cash Withdrawals</strong><br />
            Up to 900&euro;/month
          </li>
          <li *ngIf="!environment.DISABLE_CARDS">
            <strong>In-store and online Payments</strong><br />
            Including Card 3D secure
          </li>
        </ul>
      </div>
    </div>

    <div class="vertical-line"></div>

    <div>
      <app-account-header [program]="programs.RUBY"></app-account-header>
      <div>
        <div fxLayout="column" fxLayoutAlign="start center">
          <div class="price">99.95&euro;/month</div>
          <button
            mat-stroked-button
            color="accent"
            class="g-small"
            (click)="upgradeAccount()"
          >
            Upgrade to Ruby Account
          </button>
        </div>
        <ul>
          <li *ngIf="!environment.DISABLE_CARDS">
            <strong> Loading on card</strong><br />
            110,000&euro;/month, 1,320,000&euro;/year
          </li>
          <li>
            <strong>Loading on CY IBAN</strong><br />
            1,000,000&euro;/month, 3,000,000&euro;/year
          </li>
          <li *ngIf="!environment.DISABLE_CARDS">
            Up to <strong>9 Corporate cards</strong>
          </li>
          <li *ngIf="!environment.DISABLE_CARDS">
            <strong>Free eCREDO Card</strong><br />
            Including delivery within EU
          </li>
          <li>
            <strong>SEPA transfers</strong><br />
            FREE all inbound transfers<br />
            FREE all outbound transfers<br />
          </li>
          <li *ngIf="!environment.DISABLE_SWIFT">
            <strong>International Payments (SWIFT)</strong><br />
            Transfers in major currencies worldwide
          </li>
          <li><strong>Mass payments/Payroll feature</strong></li>
          <li>
            <strong>e2e Now</strong><br />
            Instant money transfers between eCREDO clients
          </li>
          <li *ngIf="!environment.DISABLE_WALLET">
            <strong>Currency Conversion Exchange</strong><br />
            Convert between 10 major currencies
          </li>
          <li *ngIf="!environment.DISABLE_CARDS">
            <strong>No fee ATM Cash Withdrawals</strong><br />
            Up to 900&euro;/month
          </li>
          <li *ngIf="!environment.DISABLE_CARDS">
            <strong>In-store and online Payments</strong><br />
            Including Card 3D secure
          </li>
        </ul>
      </div>
    </div>
  </div>
</mat-dialog-content>

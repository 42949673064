import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { TransactionTypeName } from './models/transaction-type-name.enum';

@Pipe({
  name: 'statementAmount',
})
export class StatementAmountPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(
    value: number | string | undefined | null,
    typeName: string | undefined,
    digitsInfo: string = '1.2-2'
  ): any {
    if (value == null) return null;
    const transformedNumber = this.decimalPipe.transform(
      value,
      digitsInfo,
      'en-US'
    ); // line same as in AmountPipe
    return typeName === TransactionTypeName.CREDIT
      ? `+${transformedNumber}`
      : `-${transformedNumber}`;
  }
}

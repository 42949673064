import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccountDetailsDialogComponent } from './account-details-dialog/account-details-dialog.component';
import { Program } from '../../../models/program.enum';

@Component({
  selector: 'app-choose-account',
  templateUrl: './choose-account.component.html',
  styleUrls: ['./choose-account.component.scss'],
})
export class ChooseAccountComponent {
  @Output() selectProgram = new EventEmitter<Program>();

  programs = Program;
  constructor(private dialog: MatDialog) {}

  chooseProgram(selectedProgram: Program): void {
    this.selectProgram.emit(selectedProgram);
  }

  showDetails(program: Program): void {
    this.dialog
      .open(AccountDetailsDialogComponent, {
        data: program,
        width: '100vw',
        maxWidth: '360px',
        panelClass: 'dialog-with-close-button',
      })
      .beforeClosed()
      .subscribe((selectedProgram) => this.chooseProgram(selectedProgram));
  }
}

import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Country } from 'src/app/shared/models/country.model';
import { OnboardingData } from '../../models/onboarding-data.model';
import {
  OnboardingSubmitDialogModel,
  OnboardingSubmitDialogNewComponent,
} from '../onboarding-submit-dialog/onboarding-submit-dialog.component';
import { Step } from '../../stepper/stepper.component';
import { VerificationResponse } from 'src/app/admin/users/ongoing-registration-new/ongoing-registration-user/risk-assessment/verification/models/verification-response.model';
import { environment } from 'src/environments/environment.local';
import { AppDocumentType } from '../../models/document-type.enum';
import { AppDocument } from '../../models/document.model';
import { EventType } from '../../models/event-type.enum';
import {
  VerificationDialogComponent,
  VerificationDialogData,
} from '../verification-dialog/verification-dialog.component';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { OnboardingService } from '../../onboarding.service';

@Component({
  selector: 'app-onboarding-business-form-new',
  templateUrl: './onboarding-business-form.component.html',
  styleUrls: ['./onboarding-business-form.component.scss'],
})
export class OnboardingBusinessFormNewComponent implements OnInit, OnDestroy {
  @Input() onboardingData!: OnboardingData;
  @Input() countries: Country[] = [];
  @Input() acceptedCountries: Country[] = [];
  @Input() isReviewed!: boolean; // true when userState === ADDITIONAL_INFORMATION_REQUESTED or EDD
  @Input() steps: Step[] = [];
  @Input() isEDD!: boolean; // true when userState === UserState.EDD
  @Input() isFirstTimeEDD!: boolean; // true when userState === UserState.EDD and dtEddFormSubmitted is empty (reached EDD for the first time)

  step1Progress = '';

  appCompleteForm: FormGroup = this.fb.group({
    step0: [false, Validators.requiredTrue],
    step1: [false, Validators.requiredTrue],
    step2: [false, Validators.requiredTrue],
    step3: [false, Validators.requiredTrue],
    step4: [false, Validators.requiredTrue],
    step5: [false, Validators.requiredTrue],
    step6: [false, Validators.requiredTrue],
  });

  isGettingVerificationUrl: boolean = false;
  verificationAccepted: boolean = false;
  referenceId!: string;
  dialogRef!: MatDialogRef<VerificationDialogComponent>;

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private onboardingService: OnboardingService,
    private errorService: ErrorService
  ) {}

  ngOnInit() {
    const doc = this.onboardingData.documents.find(
      (d) => d.type === AppDocumentType.PROOF_OF_ADDRESS
    );
    if (
      doc !== undefined &&
      doc.isAccepted &&
      this.onboardingData.onboardingVerificationDone
    ) {
      this.verificationAccepted = true;
    }
  }

  ngOnDestroy() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  referenceIdChanged(referenceId: string) {
    this.referenceId = referenceId;
  }

  // used for emitting verification dialog reference
  dialogRefChanged(dialogRef: MatDialogRef<VerificationDialogComponent>) {
    this.dialogRef = dialogRef;
  }

  @HostListener('window:message', ['$event'])
  async onMessage(event: MessageEvent) {
    if (event.origin === environment.SP_URL) {
      const verificationStatus = event.data.verification_status;
      if (verificationStatus === EventType.VERIFICATION_ACCEPTED) {
        const body: VerificationResponse = {
          requestId: event.data.request_id,
          verificationStatus: event.data.verification_status,
          referenceId: this.referenceId,
        };
        (await this.onboardingService.getVerificationResponse(body)).subscribe(
          (res: { documents: AppDocument[]; companyDirectorId?: number }) => {
            if (this.dialogRef) {
              // get the documents from backend and show that they were uploaded
              if (res.companyDirectorId) {
                if (this.onboardingData.companyDirectors) {
                  // update docs like that so director component is triggered and updates the documents
                  this.onboardingData.companyDirectors =
                    this.onboardingData.companyDirectors.map((director) =>
                      director.id === res.companyDirectorId
                        ? { ...director, documents: res.documents }
                        : director
                    );
                }
              } else {
                this.onboardingData.documents = res.documents;
                // show that verification was done only if proof of address exists and is accepted (in case document failed to be saved)
                const doc = res.documents.find(
                  (d) => d.type === AppDocumentType.PROOF_OF_ADDRESS
                );
                if (doc !== undefined && doc.isAccepted) {
                  // triggers to disable some fields in onboarding form
                  this.verificationAccepted = true;
                }
              }
              // show the close button to close the dialog
              this.dialogRef.componentInstance.updateCloseButtonVisibility(
                true
              );
            }
          },
          () => {
            this.errorService.showErrorDialog();
          }
        );
      } else if (
        verificationStatus === EventType.VERIFICATION_DECLINED ||
        verificationStatus === EventType.VERIFICATION_CANCELLED
      ) {
        // show the close button to close the dialog
        this.dialogRef.componentInstance.updateCloseButtonVisibility(true);
      }
    }
  }

  async getVerificationUrl(): Promise<void> {
    this.isGettingVerificationUrl = true;
    this.onboardingService.getVerificationUrl(false, true).subscribe(
      (res) => {
        this.isGettingVerificationUrl = false;
        // open dialog to not allow data changes
        this.referenceId = res.reference_id;
        this.dialogRef = this.dialog.open<
          VerificationDialogComponent,
          VerificationDialogData
        >(VerificationDialogComponent, {
          width: '600px',
          height: '700px',
          disableClose: true,
          data: {
            src: res.verification_url,
          },
        });
      },
      () => {
        this.isGettingVerificationUrl = false;
        this.errorService.showErrorDialog();
      }
    );
  }

  submitApplication(): void {
    this.dialog.open<
      OnboardingSubmitDialogNewComponent,
      OnboardingSubmitDialogModel
    >(OnboardingSubmitDialogNewComponent, {
      panelClass: 'dialog-with-close-button',
      width: '690px',
      disableClose: true,
      data: {
        directorsNumber: this.directorsNumber,
        counterpartiesNumber: this.counterpartiesNumber,
        groupMembersNumber: this.groupMembersNumber,
        accountsNumber: this.accountsNumber,
      },
    });
  }

  setCloseLinks(event: any) {
    // assign it like that to trigger ngOnChanges in every child director so each director emits isComplete to parent
    this.onboardingData.companyCloseLinks = Object.assign([], event);
  }

  get directorsNumber(): number {
    return this.onboardingData.companyDirectors!.length;
  }
  get counterpartiesNumber(): number {
    return this.onboardingData.companyCounterparties?.length || 0;
  }
  get accountsNumber(): number {
    return this.onboardingData.companyAccounts?.length || 0;
  }
  get groupMembersNumber(): number {
    return this.onboardingData.companyGroupMembers?.length || 0;
  }

  get step0Control(): FormControl {
    return this.appCompleteForm.get('step0') as FormControl;
  }
  get step1Control(): FormControl {
    return this.appCompleteForm.get('step1') as FormControl;
  }
  get step2Control(): FormControl {
    return this.appCompleteForm.get('step2') as FormControl;
  }
  get step3Control(): FormControl {
    return this.appCompleteForm.get('step3') as FormControl;
  }
  get step4Control(): FormControl {
    return this.appCompleteForm.get('step4') as FormControl;
  }
  get step5Control(): FormControl {
    return this.appCompleteForm.get('step5') as FormControl;
  }
  get step6Control(): FormControl {
    return this.appCompleteForm.get('step6') as FormControl;
  }
}

<div mat-dialog-title fxLayoutAlign="end">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content fxLayout="column" fxLayoutGap="20px">
  <h1>Change email address</h1>
  <ng-container *ngIf="!isSuccess">
    <div class="description">
      Please just type the new email address and your password.<br />
      We will send you a verification link on this new address.
    </div>
    <div class="fields" [formGroup]="form">
      <div fxLayout="column">
        <label>New Email</label>
        <mat-form-field appearance="outline">
          <input matInput formControlName="email" />
          <mat-error *ngIf="emailControl.hasError('required')"
            >Required</mat-error
          >
          <mat-error *ngIf="emailControl.hasError('email')">Invalid</mat-error>
          <mat-error *ngIf="emailControl.hasError('pattern')"
            >Invalid</mat-error
          >
          <mat-error *ngIf="emailControl.hasError('emailInUse')"
            >Email is already in use</mat-error
          >
          <mat-error *ngIf="emailControl.hasError('disposableEmail')"
            >Email is temporary</mat-error
          >
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <label>Password</label>
        <mat-form-field appearance="outline">
          <input
            matInput
            formControlName="password"
            [type]="hidePassword ? 'password' : 'text'"
          />
          <mat-error *ngIf="passwordControl.hasError('required')"
            >Required</mat-error
          >
          <mat-error *ngIf="passwordControl.hasError('wrongPassword')"
            >Wrong password</mat-error
          >
          <button
            mat-icon-button
            matIconSuffix
            (click)="hidePassword = !hidePassword"
          >
            <mat-icon class="material-icons-outlined">{{
              hidePassword ? 'visibility' : 'visibility_off'
            }}</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <button
      mat-flat-button
      fxFlexAlign="center"
      color="accent"
      class="submit-button"
      (click)="submit()"
      [disabled]="form.invalid || isSending"
      [appButtonLoader]="isSending"
    >
      Send email confirmation
    </button>
    <button
      mat-stroked-button
      mat-dialog-close
      fxFlexAlign="end"
      color="accent"
      class="cancel"
    >
      Cancel
    </button>
  </ng-container>
  <ng-container *ngIf="isSuccess">
    <div class="description">
      We’ve just sent you an e-mail. Please check your mailbox and click the
      verification link.
    </div>
    <button
      mat-flat-button
      mat-dialog-close
      fxFlexAlign="end"
      color="accent"
      class="cancel"
    >
      Close
    </button>
  </ng-container>
</mat-dialog-content>

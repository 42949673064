<mat-card>
  <div class="reviewed">
    <strong>Your application has been reviewed.</strong>
    <div>
      Please check any comments below and provide the requested data.<br />
      Once you complete everything, please submit the changes.
    </div>
  </div>
  <div *ngIf="generalComment" class="general-comment">
    <h2>General comment (Action Required)</h2>
    <div>{{ generalComment }}</div>
  </div>
</mat-card>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, throwError } from 'rxjs';
import { catchError, first, mergeMap } from 'rxjs/operators';
import { AuthService } from 'src/app/login/services/auth.service';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { UserProfileService } from '../../user-profile.service';

@Component({
  selector: 'app-confirm-email-change',
  templateUrl: './confirm-email-change.component.html',
})
export class ConfirmEmailChangeNewComponent implements OnInit {
  isLoading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private errorService: ErrorService,
    private userProfileService: UserProfileService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.route.queryParams
      .pipe(
        first(),
        mergeMap(({ token }) =>
          this.userProfileService.confirmEmail(token).pipe(
            catchError((err) => {
              if (err.status === 400) {
                this.router.navigate(['login'], {
                  state: { isEmailChangeProblem: true },
                });
                return EMPTY;
              } else {
                return throwError(err);
              }
            })
          )
        ),
      )
      .subscribe(
        () => {
          this.isLoading = false;
          if (this.authService.isLoggedIn()) {
            this.router.navigate(['my-profile-new'], {
              state: { isEmailChangeSuccess: true },
            });
          } else {
            this.router.navigate(['login'], {
              state: { isEmailChangeSuccess: true },
            });
          }
        },
        () => {
          this.errorService.showErrorDialog();
          this.isLoading = false;
        }
      );
  }
}

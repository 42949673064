import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { InfoService } from '../../../shared/info-dialog/info.service';
import { AccountDetails } from 'src/app/dashboard/models/account-details.model';
import { AccountType } from 'src/app/dashboard/shared/account-type.enum';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-accounts-selector',
  templateUrl: './accounts-selector.component.html',
  styleUrls: ['./accounts-selector.component.scss'],
})
export class AccountsSelectorComponent implements OnInit {
  @Input() accounts: AccountDetails[] = [];

  accountsGroup?: FormGroup;
  $breakpointObserver?: Observable<boolean>;
  fromTrigger!: MatMenuTrigger;
  toTrigger!: MatMenuTrigger;

  constructor(
    private controlContainer: ControlContainer,
    private breakpointObserver: BreakpointObserver,
    private infoService: InfoService
  ) {}

  ngOnInit(): void {
    this.accountsGroup = this.controlContainer.control as FormGroup;
    this.$breakpointObserver = this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(map((state) => state.breakpoints[Breakpoints.XSmall]));
  }

  selectAccount(account: AccountDetails, isFrom: boolean) {
    if (isFrom) {
      this.fromAccountControl.setValue(account);
      if (this.toAccountControl.value === account || this.invalidTransfer()) {
        this.toAccountControl.reset();
      }
    } else {
      this.toAccountControl.setValue(account);
      if (this.fromAccountControl.value === account || this.invalidTransfer()) {
        this.fromAccountControl.reset();
      }
    }
  }

  // Card to Wallet and Wallet to Card: invalid transfer
  invalidTransfer(): boolean {
    // FROM or TO must be IBAN
    return (
      this.toAccountControl?.value?.type !== AccountType.IBAN &&
      this.fromAccountControl?.value?.type !== AccountType.IBAN
    );
  }

  switchAccounts(): void {
    const copy = this.fromAccountControl.value;
    this.fromAccountControl.setValue(this.toAccountControl.value);
    this.toAccountControl.setValue(copy);
  }

  showInfo(): void {
    let title: string = 'Between Your Accounts';
    let message: string =
      'Transfer funds between your IBAN, Card and EUR currency wallet:';
    let message2: string = '1. Card and EUR currency wallet to IBAN.';
    let message3: string = '2. IBAN to Card and EUR currency wallet.';
    this.infoService.showInfoDialog(title, message, '', message2, message3);
  }

  noAccountOptions(isFrom: boolean) {
    return isFrom
      ? this.fromAccountsList.length === 0
      : this.toAccountsList.length === 0;
  }

  get fromAccountsList(): AccountDetails[] {
    var accounts = this.accounts.filter(
      (acc) =>
        acc !== this.fromAccountControl.value &&
        acc !== this.toAccountControl.value
    );
    // if TO is card/wallet then FROM should be iban
    if (
      this.toAccountControl?.value?.type === AccountType.CARDS ||
      this.toAccountControl?.value?.type === AccountType.WALLETS
    ) {
      accounts = accounts.filter((acc) => acc.type === AccountType.IBAN);
    }
    return accounts;
  }

  get toAccountsList(): AccountDetails[] {
    var accounts = this.accounts.filter(
      (acc) =>
        acc !== this.toAccountControl.value &&
        acc !== this.fromAccountControl?.value
    );
    // if FROM is card/wallet then TO should be iban
    if (
      this.fromAccountControl?.value?.type === AccountType.CARDS ||
      this.fromAccountControl?.value?.type === AccountType.WALLETS
    ) {
      accounts = accounts.filter((acc) => acc.type === AccountType.IBAN);
    }
    return accounts;
  }

  get fromAccountControl(): FormControl {
    return this.accountsGroup?.get('fromAccount') as FormControl;
  }
  get toAccountControl(): FormControl {
    return this.accountsGroup?.get('toAccount') as FormControl;
  }
}

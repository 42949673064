<div
  fxLayout="row"
  fxLayout.lt-lg="column"
  fxLayoutAlign="space-between top"
  fxLayoutGap="30px"
  fxLayoutGap.lt-md="15px"
>
  <!-- IBAN -->
  <app-account-section
    fxFlex="33%"
    svgIconName="iban"
    name="IBAN Account"
    [section]="Sections.IBAN"
    [isLoadingIBAN]="isLoadingIBAN"
    [availableBalance]="availableIbanBalance"
    [balance]="ibanBalance"
    [accountNumber]="accountNumber"
    [accountTransactions]="ibanTransactions"
    [isLoadingIBANTransactions]="isLoadingIBANTransactions"
  ></app-account-section>
  <!-- CARD -->
  <app-account-section
    fxFlex="33%"
    svgIconName="card"
    [name]="currentCard?.name"
    [cardholder]="currentCard?.cardholder"
    [section]="Sections.CARD"
    [availableBalance]="currentCard?.balance"
    [isCardActive]="
      currentCard?.status === AccountStatus.OPEN ||
      currentCard?.status === AccountStatus.DEPOSIT_ONLY
    "
    [isLoadingCards]="isLoadingCards"
    [program]="program"
    [accountTransactions]="cardTransactions"
    [cards]="cards"
    [isLoadingCardTransactions]="isLoadingCardTransactions"
    [isLocked]="currentCard?.status === AccountStatus.DEPOSIT_ONLY"
    [isIssued]="currentCard?.status === AccountStatus.ISSUED"
    [currentCard]="currentCard"
    (cardActivated)="refresh()"
    (pickedCard)="setCurrentCard($event)"
  ></app-account-section>
  <!-- WALLET -->
  <app-account-section
    fxFlex="33%"
    svgIconName="wallet"
    name="Wallet"
    [section]="Sections.WALLET"
    [wallet]="wallet"
    [isLoadingWallet]="isLoadingWallet"
  ></app-account-section>
</div>

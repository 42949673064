<div
  *ngIf="accountsGroup"
  fxLayout="column"
  [formGroup]="accountsGroup"
  class="accounts-container"
>
  <div fxHide.lt-sm fxLayout="row" fxLayoutAlign="space-between center">
    <h1>From</h1>
    <button mat-icon-button class="info-button" (click)="showInfo()">
      <mat-icon class="info-icon">info_outline</mat-icon>
    </button>
    <div></div>
    <h1>To</h1>
  </div>
  <div
    fxLayout="row"
    fxLayout.lt-sm="column"
    fxLayoutAlign="space-between center"
  >
    <!-- FROM -->
    <div fxLayoutAlign.lt-sm="start" fxLayout.lt-sm="column" class="from-to">
      <div fxLayout="row">
        <h1 class="small-screen-h1" fxHide.gt-xs>From</h1>
        <button
          mat-icon-button
          fxHide.gt-xs
          class="small-info-button"
          (click)="showInfo()"
        >
          <mat-icon
            class="small-info-icon small-screen-h1 material-icons-outlined"
            >info_outline</mat-icon
          >
        </button>
      </div>
      <div
        fxLayout
        class="account-selector"
        [class.open]="fromTrigger.menuOpen"
      >
        <app-account-list-item
          [account]="fromAccountControl.value"
          [currency]="fromCurrencyControl.value"
        ></app-account-list-item>
        <div
          class="right-section"
          fxLayoutAlign="center center"
          [matMenuTriggerFor]="fromMenu"
          #fromTrigger="matMenuTrigger"
        >
          <mat-icon svgIcon="chevron-contracted"></mat-icon>
        </div>
      </div>
    </div>
    <!-- ARROW -->
    <button mat-icon-button color="accent" class="arrow">
      <mat-icon
        svgIcon="arrow"
        [class.switch-accounts-button]="$breakpointObserver | async"
      ></mat-icon>
    </button>

    <!-- TO -->
    <div fxLayoutAlign.lt-sm="start" fxLayout.lt-sm="column" class="from-to">
      <h1 fxHide.gt-xs class="negative-margin small-screen-h1">To</h1>
      <div
        fxLayout="column"
        class="to-selector"
        cdkOverlayOrigin
        #triggerFav="cdkOverlayOrigin"
        #triggerBen="cdkOverlayOrigin"
      >
        <label>IBAN (15 to 34 characters) or Account Number </label>
        <mat-form-field>
          <input
            matInput
            formControlName="ibanOrAccountNumber"
            maxlength="100"
          />

          <!-- FAV BUTTON -->
          <button
            mat-icon-button
            matIconSuffix
            class="favourite-button"
            [class.is-open]="isOpenFav"
            (click)="
              isOpenFav = !isOpenFav;
              isOpenBen = false;
              $event.stopPropagation()
            "
          >
            <mat-icon class="material-icons-outlined">book</mat-icon>
          </button>

          <!-- BEN BUTTON -->
          <button
            mat-icon-button
            matIconSuffix
            class="beneficiary-button"
            [class.is-open]="isOpenBen"
            (click)="
              isOpenBen = !isOpenBen;
              isOpenFav = false;
              $event.stopPropagation()
            "
          >
            <mat-icon class="material-icons-outlined">history</mat-icon>
          </button>
        </mat-form-field>
        <mat-error
          *ngIf="ibanOrAccountNumberControl.hasError('ibanBelongsToSender')"
          >IBAN belongs to sender</mat-error
        >
      </div>
    </div>
  </div>

  <!-- FAVOURITES MENU -->
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayWidth]="triggerFav.elementRef.nativeElement.offsetWidth"
    [cdkConnectedOverlayOrigin]="triggerFav"
    [cdkConnectedOverlayOpen]="isOpenFav"
  >
    <app-select-favourite-overlay
      [isE2e]="false"
      [accountOwnerGuid]="accountOwnerGuid"
      (selectFav)="onSelectFav($event)"
    ></app-select-favourite-overlay>
  </ng-template>

  <!-- BENEFICIARIES MENU -->
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayWidth]="triggerBen.elementRef.nativeElement.offsetWidth"
    [cdkConnectedOverlayOrigin]="triggerBen"
    [cdkConnectedOverlayOpen]="isOpenBen"
  >
    <app-select-beneficiary-overlay
      [accountOwnerGuid]="accountOwnerGuid"
      (selectBen)="onSelectBen($event)"
    ></app-select-beneficiary-overlay>
  </ng-template>
</div>

<!-- FROM MENU -->
<mat-menu
  #fromMenu="matMenu"
  xPosition="before"
  class="g-accounts-selector-menu"
>
  <div *ngFor="let account of fromAccountsList" class="menu-item">
    <app-account-list-item
      [account]="account"
      [currency]="account.ccy"
      [isSmallVersion]="true"
      (click)="selectFromAccount(account, account.ccy)"
    ></app-account-list-item>
  </div>
</mat-menu>

import { Component, OnInit } from '@angular/core';
import { Program } from 'src/app/login/models/program.enum';
import { UserType } from 'src/app/login/models/user-type.enum';
import { AuthService } from 'src/app/login/services/auth.service';

import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account-information-dialog-new',
  templateUrl: './account-information-dialog.component.html',
  styleUrls: ['./account-information-dialog.component.scss'],
})
export class AccountInformationDialogNewComponent implements OnInit {
  programs = Program;
  userType?: UserType;
  userTypes = UserType;
  environment = environment;

  constructor(
    private dialogRef: MatDialogRef<AccountInformationDialogNewComponent>,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.userType = this.authService.authenticatedUserSubject.value?.type;
  }

  upgradeAccount() {
    //Starts upgrading process
    this.dialogRef.close(true);
  }
}
